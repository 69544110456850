import React from 'react';
// import {
// 	defaultPhoto,
// } from '../../common/v5/helpers';
import {I} from '../../common/v5/config.js';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';

export class GroupBook extends React.Component {
	constructor(props){
		super(props);
		this.handleCloseGroupBook = this.handleCloseGroupBook.bind(this);
		this.handleInsertGroup = this.handleInsertGroup.bind(this);
		this.handleSearchInput = this.handleSearchInput.bind(this);
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {
			searchText: ""
		}
	}
	handleCloseGroupBook(e){
		this.state.searchText = "";
		this.props.onToggle(false);
	}
	handleInsertGroup(){
		this.state.searchText = "";
		this.props.onReplyAdddress();
		this.props.onToggle(false);
	}
	handleShowGroups(id){
		this.props.onShowGroups(id);
	}
	handleSearchInput(e){
		this.setState({searchText: e.target.value})
	}
	handleSearchSubmit(e){
		const p = this.props;
		this.props.onHandleSearch(p.show, this.state.searchText);
		e.preventDefault();
	}
	handleSelect(v, id) {
		this.props.onSelect(id, v);
	}
	render() {
		const p = this.props;
		let groupList = [], groupInfo = "";
		let cbClass = "contact-book-backdrop popup", btnDisable = true;
		if(this.props.show) {
			cbClass = "contact-book-backdrop popup open";
		}
		if(p.groupBook){
			$.each(p.groupBook, (i,v) => {
				groupList.push(
					<li key={i} className={v.id === p.group.id ? "current" : ""}>
						<SquareCheckbox data-qa-id={v.id} onClick={this.handleSelect}
							checked={p.groupList[v.id].selected}
						/>
						<a onClick={(e) => this.handleShowGroups(v.id)}>{v.name}</a>
					</li>
				);
			});
			// $.each(p.groupList, (i,v) => {
			// 	contactList.push(
			// 		<tr key={v.id}>
			// 			<td>{v.name}</td>
			// 		</tr>
			// 	);
			// });
			if(p.group.id != 0 && p.group.name != ""){
				groupInfo = (
					<div className="user-card">
						<div className="user-data">
							<div className="user-name">
								{p.group.name}
							</div>
						</div>
					</div>
				);
			}
			btnDisable = false;
		}

		let GroupButton = "";
		if(p.replyType === "group"){
			GroupButton = <button className="btn-blue" disabled={btnDisable} onClick={this.handleInsertGroup}>{I("Add")}</button>
		}
		return (
			<div className={cbClass}>
				<div className="contact-book popup-inner">
					<div className="contact-book-sidebar">
						<div className="popup-title">
							<h2>{I("Group list")}</h2>
						</div>
						<div className="contact-book-menu">
							<ul>
								<li className="current"><a href="#">{I("Group")}</a></li>
							</ul>
						</div>
					</div>
					<div className="contact-book-contacts">
						<div className="contact-book-search">
							<form onSubmit={this.handleSearchSubmit}>
								<input className="search-field" type="text"
									value={this.state.searchText}
									placeholder={I('Search')}
									onChange={this.handleSearchInput}
								/>
								<button><i className="icon-search"></i></button>
							</form>
						</div>
						<div className="contact-book-list" data-custom-scrollbar>
							<ul>
								{groupList}
							</ul>
						</div>
					</div>
					<div className="contact-book-content">
						<div className="contact-book-content-header">

						</div>
						<div className="contact-book-content-body">
							{groupInfo}
							<div className="add-information">
								<div className="card-list">
									<table>
										<tbody>
											{/* {contactList} */}
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="button-row">
							{GroupButton}
						</div>
					</div>
					<div className="popup-close" onClick={this.handleCloseGroupBook}>
						<i className="icon-times"></i>
					</div>
				</div>
			</div>
			);
	}
}

