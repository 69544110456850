import React, { PureComponent, useState, Fragment, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { WidthProvider, Responsive } from "react-grid-layout";
import {
	STATISTICS_TITLE
	, LIVE_REPORT
	, LIVE_REPORT_OVERVIEW
	, LIVE_REPORT_EMAIL
	, LIVE_REPORT_CHAT
	, LIVE_REPORT_VOICE
	, CENTION_LOGO
	, CENTION_LOGO_MINI
	, MM_LIVE_REPORTS
} from '../../common/v5/constants';
import{
	isValidTelavoxUrl
} from '../../common/v5/helpers';
import SimpleTable from '../../reactcomponents/SimpleTable';
import Reload from '../../reactcomponents/Reload';
import { CancellableSimpleLink } from '../../containers/link';
import MenuCtnr from '../../common/v5/menuCtnr';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import { SidebarSettingsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr, WorkflowBreadcrumbsCtnr } from '../../views/v5/workflowCtnr';
import { centionBlue, centionRed, centionTitleGrey, tintGrey } from '../../styles/_variables';
import Measure from 'react-measure';
import Button from '../../reactcomponents/Button';
import {
	FormInputWithLabelRow,
	TextInputRow,
	SelectInputRow,
} from '../../reactcomponents/Form';
import ToggleSwitch from './ToggleSwitch';
import { SerialMultiSelect } from '../../reactcomponents/Dropdown';

export const ReportMainTitle = styled.div`
	font-size: 28px;
	padding: 10px;
`;
export const ReportSubTitle = styled.div`
	font-size: 18px;
	padding: 10px;
`;

const ResponsiveGridLayout = WidthProvider(Responsive);

export const StyledWidgetTitle = styled.span`
	color: ${centionTitleGrey};
`

export const NumberSpan = styled.span`
	color: #0c87f7;
	margin-right: 5px;
`
class LiveReport extends PureComponent {
	static defaultProps = {
		className: "layout",
		items: 4,
		rowHeight: 40,
		onLayoutChange: function () { },
		isResizable: false,
		autoSize: true,
		breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
		cols: { lg: 3, md: 3, sm: 3, xs: 2, xxs: 1 }
	};
	constructor(props) {
		super(props);
		this.handleReload = this.handleReload.bind(this);
		this.handleSnoopClick = this.handleSnoopClick.bind(this);
		this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
		this.handleSideBarCollapse = this.handleSideBarCollapse.bind(this);
		this.changeView = this.changeView.bind(this);
		this.handleShowLiveReports = this.handleShowLiveReports.bind(this);
		this.handleMessageEvent = this.handleMessageEvent.bind(this);

		this.state = {
			showLiveReportMenu: false
		}
	}
	componentDidMount() {
		this.props.onChangeView(LIVE_REPORT_OVERVIEW);
		window.addEventListener('message', this.handleMessageEvent);
	}
	componentWillUnmount() {
		window.removeEventListener('message', this.handleMessageEvent);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.view !== this.props.view) {
			this.props.onLoadView(this.props.view);
		}
	}
	handleReload() {
		this.props.onLoadView(this.props.view);
	}
	handleSnoopClick(actionType, id) {
		console.info("requested snoop on [" + id + "] mode [" + actionType + "]");
		this.props.onSipSnoop(actionType, id);
	}
	handleShowLiveReports() {
		this.setState({ showLiveReportMenu: !this.state.showLiveReportMenu });
	}
	handleHomePage = (e) => {
		e.preventDefault();
		this.props.onMainView();
	}
	handleSideBarToggle() {
		this.props.onToggleSideBar(!this.props.ui.showSideBar);
	}
	handleSideBarCollapse() {
		this.props.onCollapseSideBar(!this.props.ui.collapseSideBar);
	}
	changeView(page, e) {
		this.props.onChangeView(page);
		if (this.props.ui.showMobileView) {
			this.props.onToggleSideBar(false);
		}
	}
	handleMessageEvent(e) {
		if(typeof e.data === "object"){
			if(typeof e.data.action !== 'undefined' &&
				e.data.action !== null){
				if(e.data.action == "open-errand"){
					let urlValid = isValidTelavoxUrl(e.origin);
					if(typeof e.data.params !== 'undefined' &&
						e.data.params !== null && urlValid == true){
						externalqueue.isPostMessage = true;
						if(typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== ""){
								let encoded = e.data.params.deepLink;
								this.props.simpleLoadAndOpen(encoded, 0);
						}
					}
				} else {
					console.info("unhandled messsage action:", e.data.action);
				}
			}
		}
	}
	render() {
		const {
			activeView,
			activateChat,
			ui,
			view,
			queueData,
			activeData,
			agentsQueueData,
			groupQueueData,
			sipNumberData,
			...p
		} = this.props, st = this.state;

		let layoutClass = "layout slim statistics"
			, appClass = 'app-content statistics-overview'
			;

		if (ui.collapseSideBar) {
			appClass += " collapsed";
		}
		if (!ui.showSideBar) {
			layoutClass += " closed-sidebar"
		}
		if (activateChat) {
			layoutClass += " opened-chat";
		}

		let sidebarClass = "", logoWrapperStyle = {}, flexMenusStyle = {};
		if (ui.collapseSideBar) {
			sidebarClass = "collapsed";
			logoWrapperStyle = { margin: "auto", textAlign: "center" }
			flexMenusStyle = { padding: "0px" };
		}
		const hideLogo = features['hide-cention-logo'];
		const customStyledAppHeader = { borderRadius: "initial" };

		let isShowQueueInfo = true, showSipNumberInUsed = false;
		let title = I("Overview"), titleChannel = I("email"), qTitleChannel = I("queues");
		if(view === LIVE_REPORT_EMAIL) {
			title = I("Email queues");
			titleChannel = I("email");
			qTitleChannel = I("emails");
		} else if (view === LIVE_REPORT_CHAT) {
			title = I("Chat queues");
			titleChannel = I("chat");
			qTitleChannel = I("chats");
		} else if (view === LIVE_REPORT_VOICE) {
			title = I("Voice queues");
			isShowQueueInfo = false;
			showSipNumberInUsed = true;
			titleChannel = I("voice");
			qTitleChannel = I("calls");
		}
		const queueHoverTitle = I(`All waiting ${qTitleChannel} will be shown here.`);
		const inConversationHoverTitle = I(`Shows list of active ${titleChannel} sessions with assigned agents.`);
		const agentQueueHoverTitle = I(`Shows agents' current status. Agents with active ${titleChannel} sessions will be shown first.`);
		const groupQueueHoverTitle = I(`Shows queues and active ${titleChannel} sessions by group.`);
		const sipNumberTitle = I("SIP number in use");

		let loading = queueData.wip || activeData.wip || agentsQueueData.wip || groupQueueData.wip;
		return (
			<div className="statistic-page">
				<div id="page-wrapper">
					<section className={layoutClass} data-app-layout>
						<div className={classNames("app-sidebar", sidebarClass)}>
							<div className="app-header" style={customStyledAppHeader}>
								{(hideLogo) &&
									<MenuCtnr visible={!ui.showErrandMobileView && !ui.collapseSideBar} />
								}
								{(hideLogo) &&
									<ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
										collapsed={ui.collapseSideBar}
										mobileMode={ui.showMobileView}
										onClick={this.handleSideBarToggle}
										onCollapse={this.handleSideBarCollapse} />
								}
								<div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
									<CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
										<img src={ui.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
									</CancellableSimpleLink>
								</div>
							</div>
							<WorkflowInnerContainerCtnr className="app-inner-content" data-custom-scrollbar>
								<div className="flex-menus-wrap" style={flexMenusStyle}>
									<div className="side-bar-main-menu" hidden={hideLogo}>
										{
											!ui.collapseSideBar &&
											<div style={{ marginRight: "24px" }}><MenuCtnr visible={!ui.showErrandMobileView} /></div>
										}
										<ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
											showSideBar={ui.showSideBar}
											collapsed={ui.collapseSideBar}
											mobileMode={ui.showMobileView}
											id={"QA_toggle_sidebar_top"}
											onCollapse={this.handleSideBarCollapse}
											onClick={this.handleSideBarToggle} />
									</div>
									{
										ui.collapseSideBar &&
										<div>
											<IconizeSideBarMenuCtnr currentPage={MM_LIVE_REPORTS} activeView={activeView} showWorkflowMenu={true} />
										</div>
									}
									<Fragment>
										<ul className="sidebar-menu" hidden={ui.collapseSideBar}>
											<ul style={{ padding: '10px 10px 0px 10px' }}>
												<li data-qa-id="live_report_overview_link" className={activeView === LIVE_REPORT_OVERVIEW ? "current" : ""} >
													<a title={I("Overview")} onClick={(e) => this.changeView(LIVE_REPORT_OVERVIEW)}>{I("Overview")}</a>
												</li>
												<li data-qa-id="live_report_email_link" className={activeView === LIVE_REPORT_EMAIL ? "current" : ""} >
													<a title={I("Email")} onClick={(e) => this.changeView(LIVE_REPORT_EMAIL)}>{I("Email")}</a>
												</li>
												{
													features["chat"] &&
													<li data-qa-id="live_report_chat_link" className={activeView === LIVE_REPORT_CHAT ? "current" : ""} >
														<a title={I("Chat")} onClick={(e) => this.changeView(LIVE_REPORT_CHAT)}>{I("Chat")}</a>
													</li>
												}
												{
													features["sip-enabled"] &&
													<li data-qa-id="live_report_voice_link" className={activeView === LIVE_REPORT_VOICE ? "current" : ""} >
														<a title={I("Voice")} onClick={(e) => this.changeView(LIVE_REPORT_VOICE)}>{I("Voice")}</a>
													</li>
												}
											</ul>
										</ul>
									</Fragment>
								</div>
								<SidebarSettingsCtnr />
							</WorkflowInnerContainerCtnr>
						</div>
						<div className={appClass}>
							<div className="app-header statistic" style={customStyledAppHeader}>
								<ul className="conversations-nav">
									<ToggleSideBar
										data-qa-id={"toggle-sidebar-search"}
										visible={!ui.showSideBar || ui.collapseSideBar}
										wrapped={true}
										collapsed={ui.collapseSideBar}
										onCollapse={this.handleSideBarCollapse}
										onClick={this.handleSideBarToggle} />
								</ul>
								{(ui.collapseSideBar || !ui.showSideBar) && <WorkflowBreadcrumbsCtnr />}
							</div>
							<div className='app-body live-report'>
								<ReportMainTitle>{STATISTICS_TITLE[LIVE_REPORT]}</ReportMainTitle>
								<ReportSubTitle>
									{title}
									<div style={{ float: "right" }}>
										<Reload
											data-qa-id="QA_liveReport_list"
											reloadIconClassName="icon-reload"
											loading={loading}
											onClick={this.handleReload}
										/>
									</div>
								</ReportSubTitle>
								<ResponsiveGridLayout
									isDraggable={true}
									{...this.props}
								>
									<div key={"1" + "-" + view} id="1" hidden={!isShowQueueInfo} data-id="live-report-queue-table"
										data-grid={isShowQueueInfo ? { x: 0, y: 0, w: 1, h: 10 } : { x: 0, y: 1, w: 1, h: 10 }}
									>
										<ExpandableBox title={I("in queue")} hoverTitle={queueHoverTitle} data={queueData} withCount={true}/>
									</div>
									<div key={"2" + "-" + view} id="2" data-id="live-report-conversation-table"
										data-grid={isShowQueueInfo ? { x: 1, y: 0, w: 1, h: 10 } : { x: 0, y: 0, w: 1, h: 10 }}
									>
										<ExpandableBox id="chatLive" title={I("in conversation")} hoverTitle={inConversationHoverTitle} data={activeData} withCount={true} handleClick={this.handleSnoopClick} />
									</div>
									<div key={"3" + "-" + view} id="3" data-id="live-report-agent-queue-table"
										data-grid={isShowQueueInfo ? { x: 2, y: 0, w: 1, h: 10 } : { x: 1, y: 0, w: 1, h: 10 }}
									>
										<ExpandableBox title={I("Agent queues")} hoverTitle={agentQueueHoverTitle} data={agentsQueueData} />
									</div>
									<div key={"4" + "-" + view} id="4" data-id="live-report-group-queue-table"
										data-grid={isShowQueueInfo ? { x: 0, y: 1, w: 1, h: 10 } : { x: 2, y: 0, w: 1, h: 10 }}
									>
										<ExpandableBox title={I("Group queues")} hoverTitle={groupQueueHoverTitle} data={groupQueueData} />
									</div>
									<div key={"5" + "-" + view} id="5" hidden={!showSipNumberInUsed} data-id="live-report-sip-number-table"
										data-grid={showSipNumberInUsed ? { x: 0, y: 0, w: 1, h: 10 } : { x: 0, y: 1, w: 1, h: 10 }}
									>
										<ExpandableBox title={sipNumberTitle} hoverTitle={sipNumberTitle} data={sipNumberData} />
									</div>
								</ResponsiveGridLayout>
							</div>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

const StyleWidgetBox = styled.div`
	margin: 5px;
	width: ${props => props.width};
	height: auto;
	background: #FFF;
	//display: grid;
`

const withMeasureDynamicGrid = Component => ({ key, onHeightChange, ...props }) => (
	<Measure
		bounds
		client={true}
		onResize={useCallback(
			({ bounds: { height } }) => {
				if (typeof onHeightChange === 'function') {
					onHeightChange(height)
				}
			},
			[onHeightChange]
		)}
	>
		{({ measureRef }) => <Component key={key} ref={measureRef} {...props} />}
	</Measure>
)

const StyleWidgetBoxWrapper = withMeasureDynamicGrid('div');

export const ExpandableBox = React.forwardRef(({
		id,
		key,
		total,
		title,
		withCount,
		dataType,
		maxResults,
		showCols,
		showTotal,
		refreshInterval,
		position,
		hoverTitle = "",
		width,
		type,
		data,
		isDraggable,
		editMode,
		onActivateEdit,
		widgetTypes,
		autoExpand,
		onHeightChange,
		handleClick,
		onCollapse,
		onRowClick,
		onSave,
		onDelete
	}, ref) => {
	const [expand, setExpand] = useState(true);
	const [edit, setEdit] = useState(false);

	const initWidgetData = {
		id: parseInt(id),
		dataType: dataType,
		title: title,
		showTotal: showTotal,
		showCols: showCols,
		maxResults: maxResults,
		refreshInterval: refreshInterval,
		position: position
	}

	const [widgetData, setWidgetData] = useState(initWidgetData);

	const widgetBoxBaseStyle = {
		margin: '5px'
		, width: width
		//, height: 'auto'
		, background: '#FFF'
		, height: 'fit-content'
	}
	const handleExpand = () => {
		if(edit || editMode) {
			return;
		}
		setExpand((!expand));
		if (typeof onCollapse === 'function') {
			onCollapse(id, expand);
		}
	}
	const handleDeleteWidget = () => {
		if (typeof onDelete === 'function') {
			onDelete(id);
		}
	}
	const handleEditModeWidget = () => {
		setEdit(true);
		if(typeof onActivateEdit === 'function') {
			onActivateEdit(true, id);
		}
	}
	const handleTitleChange = (e) => {
		handleSavePref("title", e.target.value);
	}
	const handleSavePref = (key, val) => {
		const newWidgetData = {...widgetData};
		let value = val;
		if (key === "showCols") {
			if(val && val.length > 0) {
				value = val.join(",");
			}
		}
		newWidgetData[key] = value;
		setWidgetData(newWidgetData);
	}
	const handleSaveWidget = (id) => {
		if(typeof onSave === 'function') {
			onSave(widgetData);
			setEdit(false);
			if(typeof onActivateEdit === 'function') {
				onActivateEdit(false, id);
			}
		}
	}
	const handleCancelEditModeWidget = () => {
		setEdit(false);
		if(typeof onActivateEdit === 'function') {
			onActivateEdit(false, id);
		}
	}

	useEffect(() => {
		if(!autoExpand) {
			setEdit(false);
		}
	}, [autoExpand]);

	const blueBtnStyle = {
		borderColor: centionBlue,
		marginRight: '10px',
		color: '#FFF',
		fontSize: '12px'
	}
	const btnBlueOutlineStyle = {
		background: '#FFF',
		border: '1px solid' + centionBlue,
		marginRight: '10px',
		color: centionBlue,
		fontSize: '12px'
	}
	const btnRedOutlineStyle = {
		background: '#FFF',
		border: '1px solid' + centionRed,
		marginRight: '10px',
		color: centionRed,
		fontSize: '12px'
	}
	const styledButtonWrapper = {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '40px'
	}
	const editableInputStyle = {
		border: '1px solid '+tintGrey,
	}
	let count = 0;
	if (data && data.data) {
		count = data.data.length;
	}
	return(
		<StyleWidgetBoxWrapper key={key} id={id} refs={ref} style={widgetBoxBaseStyle} onHeightChange={onHeightChange}>
			<div className='launchpad-drag-handle' style={{fontSize: "16px", fontWeight: '600', padding: "15px", cursor: "pointer", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"}}
				onClick={handleExpand} title={hoverTitle}>
					{isDraggable &&
						<i className='icon-grip' style={{marginRight: '10px'}}></i>
					}
					{ widgetData.showTotal ? total: "" }
					{ edit ? <input type="text" style={editableInputStyle} defaultValue={widgetData.title} onChange={handleTitleChange} /> :
						withCount ? <StyledWidgetTitle><NumberSpan>{count}</NumberSpan> {widgetData.title}</StyledWidgetTitle> :
							<StyledWidgetTitle>{widgetData.title}</StyledWidgetTitle>}
				<i className='icon-chevron-down' hidden={expand} style={{float: "right"}}></i>
				<i className='icon-chevron-up' hidden={!expand}  style={{float: "right"}}></i>
			</div>
			<div style={{boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"}} hidden={!expand && !autoExpand}>
				{
					edit &&
					<EditWidgetForm
						id={"lp_"+id}
						widgetTypes={widgetTypes}
						data={widgetData}
						onSetPref={handleSavePref}>
					</EditWidgetForm>
				}
				{
					!edit && data &&
					<LiveTable id={id} type={type} cols={data.cols} data={data.data} wip={data.wip} 
						hideHeader={type === "LIST" ? true : false} hideFooter={false} handleClick={handleClick} onRowClick={onRowClick} />
				}
			</div>
			{
				editMode &&
				<div className="widget-edit-bttns" style={styledButtonWrapper}>
					<Button key="btn-del-widget" color="blue" style={btnRedOutlineStyle} text={I("Delete")} className="save-button" onClick={handleDeleteWidget} />
					{ edit &&
						<Fragment>
							<Button key="btn-cancel-widget" color="blue" style={btnBlueOutlineStyle} text={I("Cancel")} className="save-button" onClick={handleCancelEditModeWidget} />
							<Button key="btn-save-widget" color="blue" style={blueBtnStyle} text={I("Save")} className="save-button" onClick={() => handleSaveWidget(id)} />
						</Fragment>
					}
					{ !edit && <Button key="btn-edit-widget" color="blue" style={blueBtnStyle} text={I("Edit")} className="save-button" onClick={handleEditModeWidget} /> }
				</div>
			}
		</StyleWidgetBoxWrapper>
	);
})

const LiveTable = ({ id, type, cols = [], data = [], wip, hideHeader, hideFooter, handleClick, onRowClick }) => {
	let customStyle = {};
	if(type !== "LIST") {
		customStyle = {
			tableLayout: 'fixed'
		}
	}
	if (wip) {
		return (<div style={{ textAlign: 'center', padding: '10px' }}><i className="fa fa-spinner fa-spin" aria-hidden="true" /></div>)
	} else {
		if (data && data.length > 0) {
			return (
				<div>
					<SimpleTable customClass={classNames("table", {"table-list": type === "LIST", "table-basic": type !== "LIST"})}
						customStyle={customStyle} columns={cols} dataSrc={data} rowsPerPage={5} hideHeader={hideHeader} hideFooter={hideFooter} handleClick={handleClick} onRowClick={onRowClick} />
				</div>
			)
		} else {
			return (<div style={{ padding: '10px' }}>{I("No data")}</div>)
		}
	}
}

//a constant array consisting Channel, Errand ID, Contact Name and Subject
const widgetCols = [{
	id: 1,
	name: "Channel"
}, {
	id: 2,
	name: "Errand ID"
}, {
	id: 3,
	name: "Contact Name"
}, {
	id: 4,
	name: "Subject"
}];

//a constant array consisting refresh interval options
const refreshIntervalsOpts = [{
	id: 0,
	name: I("None")
}, {
	id: 5,
	name: I("01 minute")
}];

const EditWidgetForm = ({ id, widgetTypes, data, onSetPref }) => {
	// component that contains fields like
	//1. List type , that showing dropdown with "Collaboration requests" , "Forwarded errands", "Errands due" and "Expiring errands"
	//2. Show total number field, which is a toggle checkbox (default is checked)
	//3, Number of results field which is a simple input that accepts integer (default is 10)

	const [showWidgetCols, setShowWidgetCols] = useState(false);
	const handleTypeChange = (val) => {
		handleSetPref("dataType", val);
	}
	const handleShowTotalChange = (toggle) => {
		handleSetPref("showTotal", toggle);
	}
	const handleShowList = (val) => {
		let showListArr;
		if(val && val.length > 0) {
			showListArr = val.split(",");
		}
		handleSetPref("showCols", showListArr);
	}
	const handleMaxResultsChange = (e) => {
		const value = parseInt(e.target.value);
		handleSetPref("maxResults", value);
	}
	const handleRefreshIntervalChange = (val) => {
		handleSetPref("refreshInterval", val);
	}

	const handleSetPref = (key, val) => {
		if(typeof onSetPref === "function") {
			onSetPref(key, val);
		}
	}
	const { dataType, showTotal, showCols, maxResults, refreshInterval, position } = data;
	return (
		<div className="widget-form admin-one-form">
			<FormInputWithLabelRow
				label={I('List type')}
				warning={""}
				className={"admin-select-popup-type"}
				inlineLabel={false}
			>
				<SelectInputRow
					id={"launchpad-WidgetType"}
					name={"launchpadWidgetType"}
					data-qa-id={"launchpadWidgetType"+id}
					className={"admin-select-cb-event"}
					option={widgetTypes}
					value={dataType}
					textNoItemSelected={I("Select")}
					onSelect={handleTypeChange}
				/>
			</FormInputWithLabelRow>
			<FormInputWithLabelRow
				label={I("Show total number")}
				warning={""}
				className={"admin-select-popup-type"}
				inlineLabel={false}
			>
				<ToggleSwitch
					id={"launchpad-ShowTotal"}
					name={"launchpadShowTotal"}
					data-qa-id={"launchpadShowTotal"+id}
					label={""}
					checked={showTotal}
					handleToggle={handleShowTotalChange}
					listWrap={false}
					className="admin-text-input"
					helperTxt={I("Show total number of results")}
					inlineLabel={false}
				/>
			</FormInputWithLabelRow>
			<FormInputWithLabelRow label={I("Show list")} inlineLabel={false}>
				<SerialMultiSelect
					id={"launchpad-ShowList"}
					name={"launchpadShowList"}
					className={"popup-multi-select"}
					flip={true}
					overrideTitle={true}
					title={I("Select an item")}
					data={widgetCols}
					idFields={{id: "id", name: "name"}}
					selected={showCols}
					onSelect={handleShowList}
					onToggle={() => setShowWidgetCols(!showWidgetCols)}
					show={showWidgetCols}
					selectAll={true}
					selectNone={true}
				/>
			</FormInputWithLabelRow>
			<TextInputRow
				id={"launchpad-MaxResults"}
				name="launchpadMaxResults"
				className="admin-text-input"
				label={I("Number or results")}
				mandatory={false}
				value={maxResults}
				onChange={handleMaxResultsChange}
				overrideClassName="select-row"
				type={"number"}
				min={10}
				step={10}
			/>
			<FormInputWithLabelRow
				label={I("Refresh interval")}
				hide={true} //hide refresh interval for now since we only set to 1 minute as the only option
				inlineLabel={false}>
				<SelectInputRow
					id={"launchpad-refreshInterval"}
					name={"launchpadRefreshInterval"}
					data-qa-id={"launchpadRefreshInterval"+id}
					className={"admin-select-cb-event"}
					option={refreshIntervalsOpts}
					value={refreshInterval}
					textNoItemSelected={I("Select")}
					onSelect={handleRefreshIntervalChange}
				/>
			</FormInputWithLabelRow>
		</div>
	)
}


export default LiveReport;
