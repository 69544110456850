import React from 'react';
import { ALRT_CFRM_OPR_BLOCK_IP } from '../../common/v5/constants';
import { enableConfirm } from '../../redux/actions/async/hmf.js';
import Anchor from '../../reactcomponents/Anchor';

export class IPAddress extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	onClick() {
		let chat = this.props.chat
		, msg = I("Are you sure you want to block the IP address {IPADDRESS}?")
			.replace('{IPADDRESS}', chat.ClientIP)
		;
		// TODO: please do not bring in dispatch into react component level. We
		// want a good code seperation where dispatch only happen at container.
		// This make the code easy to read and more organize.
		this.props.dispatch(enableConfirm(ALRT_CFRM_OPR_BLOCK_IP, msg, {chat}));
	}
	render() {
		let addr = this.props.chat.ClientIP
		, link
		;
		if (addr == '0.0.0.0') {
			addr = I('UNKNOWN');
		} else {
			if (this.props.chat.IPBlocked) {
				link = I("(Blocked)");
			} else {
				link = (
					<Anchor data-qa-id="chat-block-ip" onClick={this.onClick}>
						{I('Block')}
					</Anchor>
				);
			}
		}
		return (
			<div style={{fontWeight: 500}}>
				{I('IP {IPADDRESS}').replace('{IPADDRESS}', addr)} {link}
			</div>
		);
	}
}
