import React, { memo, useCallback, useMemo } from "react";
import styled from 'styled-components';
import each from 'lodash/each';
import classNames from 'classnames';
import Measure from 'react-measure';
import moment from 'moment';
import update from 'immutability-helper';
import { emptyObject } from '../../common/constants';
import {
	IMG_LOADING,
	RPLY_ERRAND,
	RPLY_COMMENT,
	RPLY_MANUAL_COMMENT,
	VIEW_REPLY_PANEL_ID_FORMAT,
	ERRAND_HOTKEYS,
	ATTACH_FILE,
	RPLY_MANUAL,
	RPLY_COLLABORATE,
	ECB_INC_QUESTION,
	ECB_PARTIAL_ANSWER,
	ECB_SUGGEST_TO_LIBRARY,
	ECB_INC_HISTORIES,
	ECB_LOCK2ME,
	ECB_INC_COLLAB_HISTORY,
	ECB_FWD_ALL_HISTORIES,
	COL_SAVE_RECIPIENT,
	RPLY_TAB_NO_SELECT,
	DEL_ANSWER_ATTACHMENT,
	ICCB_NEW,
	RPLY_EXT_FWD,
	RPLY_MANUAL_IM
} from '../../common/v5/constants';
import { I, L } from '../../common/v5/config';
import {
	getChevronIcon,
	getHotkeyChar,
	smsCount,
	removeHTMLTags,
	notChatOrChatInCollborate
} from '../../common/v5/helpers';
import { useCallbackWithValue } from '../../hooks/callback';
import { useIsXL } from '../../hooks/mediaquery';
import {
	composeWithDisplayName,
	withMountWhenShow
} from '../../reactcomponents/hocs';
import { AttachmentBox, SerialTagList } from './Attachments';
import Spellchecker from './Spellchecker';
import { HotkeyLabel } from '../../reactcomponents/Hotkeys';
import {
	ActionDropdown,
	SerialMultiSelect
} from '../../reactcomponents/Dropdown';
import FileUploader from './FileUploader';
import {
	NamedCheckbox as ReplyCheckbox
} from '../../reactcomponents/SquareCheckbox';
import ErrandTranslation from './ErrandTranslation';
import { withErrandInfo } from "../../views/v5/errandCtnrs";
import { compose, renameProps, withHandlers } from "recompose";
import { TableIconicButton as Button } from "../../reactcomponents/Form";
import { replyOptions } from "../../views/v5/errand";

const IconSubNum = ({highlight, icon, num, 'data-qa-id': dataQAId}) => {
	if(!highlight) {
		return <i className={icon} />;
	}
	let nonZero, numDOM;
	if(num) {
		nonZero = ' non-zero';
		numDOM = <div className='number'>{num}</div>;
	} else {
		nonZero = '';
		numDOM = null;
	}
	return <i className={icon+nonZero+' numbered-icon'} data-qa-id={dataQAId}>{numDOM}</i>;
};

class FootnoteActions extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showViewPanelList: false
		}
		this.handleToggleViewPanelList = this.handleToggleViewPanelList.bind(this);
	}
	handleShowUploadAttach = () =>{
		this.props.toggleUploadAttachment(this.props.showUploadAttachmentBox);
	}
	handleToggleViewPanelList(id, toggle) {
		this.setState({showViewPanelList: !toggle});
	}
	render() {
		const p = this.props;
		let errandReplyPanel = "";
		let hideAttachment = false;
		if(typeof p.hideAttachmentLink !== 'undefined' &&
			p.hideAttachmentLink == true){
			hideAttachment = true;
		}
		if(p.showSpellcheck){
			errandReplyPanel = <Spellchecker
					id={"cke_"+p.editorId}
					preferredLang={p.preferredSpellcheckLang}
					langs={p.spellcheckLangs}
					editorId={p.editorId}
				/>
		}
		let noHtml = removeHTMLTags(p.content);
		let isSMS = p.selectedChannel === 'sms';
		return <div className="actions">
				<div className="reply-editor-action errand-file" hidden={hideAttachment}>
					<span className="reply-editor-action-label">
						<HotkeyLabel id={"errandAttachFile"} text={I("ATTACH FILE")} hotkey={getHotkeyChar(ERRAND_HOTKEYS, ATTACH_FILE)} /> :
					</span>
					<a data-qa-id={"QA_attachFile"} onClick={this.handleShowUploadAttach}>
						<IconSubNum icon='icon-attachment'
							data-qa-id='attach-file-icon'
							highlight={!this.props.showUploadAttachmentBox}
							num={this.props.attachmentSize} />
					</a>
				</div>
				{p.chat?null:
				<div className="reply-editor-action errand-translate" hidden={!p.canTranslate}>
					<span className="reply-editor-action-label">
						<i className="icon-translate" />
					</span>
					<ErrandTranslation text={I("Translation options")}
						className="errand-message-translate"
						toLang={p.toLang} disabled={p.disabled}
						onSelectedToLang={p.onSelectedToLang} />
				</div>
				}
				{isSMS &&
				<div>
					<SmsWordCount
					show={p.selectedChannel === 'sms'}
					text={noHtml}
					shortUCS2={p.shortUCS2}
					/>
				</div>
				}
				{errandReplyPanel}
			</div>;
	}
}

class ReplyOptionButtonItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		if(this.props.onClick) {
			this.props.onClick();
		}
	}
	render() {
		const { label, icon, className, active, onClick } = this.props;
		const style = {float: "left"};
		return (
			<li className="grey" data-qa-id={"replyOptBtn_"+label} style={style} onClick={this.handleClick}>
				<div className={classNames("attachFileArchive", className)}>
					<span className={classNames("archivefile replyOptionBtn", {active: active})}>
						<i className={icon}></i>
						<a>{L(label)}</a>
					</span>
				</div>
			</li>
		)
	}
}

class FooterCheckboxesBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleSelectToLang = this.handleSelectToLang.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}
	handleClick(me, state) {
		const currentRoot = this.props.currentRoot;
		const check = this.props.check;
		this.props.onClick(currentRoot, me, state, check);
		if(this.props.onCheckedStateChange) {
			this.props.onCheckedStateChange(update(this.props.checkedStates, {
				[me]: {$set: state}}));
		}
	}
	handleSelectToLang(lang) {
		if (typeof this.props.onSelectedToLang === 'function') {
			this.props.onSelectedToLang(lang, this.props.currentRoot);
		}
	}
	getIcon(name) {
		if(name === ECB_INC_QUESTION) {
			return "icon-include-question";
		} else if (name === ECB_PARTIAL_ANSWER ) {
			return "icon-partial-answer";
		} else if (name === ECB_SUGGEST_TO_LIBRARY) {
			return "icon-suggest-knowledgebase";
		} else if (name === ECB_INC_HISTORIES || name === ECB_FWD_ALL_HISTORIES) {
			return "icon-include-fullhistory";
		} else if (name === ECB_LOCK2ME) {
			return "icon-lock-to-me";
		} else if (name === ECB_INC_COLLAB_HISTORY) {
			return "icon-collab-history";
		}else if (name === COL_SAVE_RECIPIENT) {
			return "icon-save-recipient";
		}else if (name === ICCB_NEW) {
			if(this.props.currentRoot === RPLY_COMMENT) {
				return "icon-internal-comment";
			}else if (this.props.currentRoot === RPLY_COLLABORATE) {
				return "icon-new-thread";
			}
		}else {
			return "";
		}
	}
	handleResize(height) {
		if(this.props.onResize) {
			this.props.onResize(height);
		}
	}
	render() {
		const { currentRoot, hideCheckboxes, ...p } = this.props;
		const notChatOrChatCollaborating = notChatOrChatInCollborate(
			p.chat,
			currentRoot
		);
		let currentCheckboxes;
		if (p.checkboxes) {
			currentCheckboxes = p.checkboxes.checkboxes[currentRoot];
		}
		let sq;
		// TODO: comment has checkbox too!
		if (notChatOrChatCollaborating /* || currentRoot === RPLY_COMMENT */
			&& currentCheckboxes
			&& !hideCheckboxes) {
			sq = [];
			each(p.checkboxes.order[currentRoot], v => {
				const cb = currentCheckboxes[v];
				if (!cb || cb.hidden) {
					return;
				}
				sq.push(
					<ReplyOptionButtonItem
						key={"replyOptBtn_"+cb.label}
						label={cb.label}
						icon={this.getIcon(v)}
						className="archivefile"
						onClick={() => this.handleClick(v, !p.checkedStates[v])}
						active={p.checkedStates[v]}
					/>
				);
			});
		} else {
			sq = null;
		}
		let customStyle;
		if (!p.chat && this.props.verticalView) {
			customStyle = {
				position: "absolute",
				bottom: this.props.offsetBottom+"px"
			}
		} else {
			customStyle = emptyObject
		}
		return (
			<Measure
			bounds
			client={true}
			onResize={contentRect => {
				this.handleResize(contentRect.bounds.height)
			}}
			>
			{({ measureRef }) => (
				<div className="block-checkboxes" style={customStyle} ref={measureRef}>
					{notChatOrChatCollaborating &&
						<div className="left-sidebar">{sq}</div>
					}
					{notChatOrChatCollaborating && !p.showAttachment ? null :
					<div className={classNames({"right-sidebar": !p.chat})}>
						<FootnoteActions
							chat={p.chat}
							disabled={p.disabled}
							toggleUploadAttachment={p.toggleUploadAttachment}
							showUploadAttachmentBox={p.showUploadAttachmentBox}
							hideAttachmentLink={p.hideAttachmentLink}
							attachmentSize={p.attachmentSize}
							toLang={p.toLang}
							onSelectedToLang={this.handleSelectToLang}
							canTranslate={p.canTranslate}
							availableReplyPanel={p.availableReplyPanel}
							selectedReplyPanel={p.selectedReplyPanel}
							onSelectedViewPanel={p.onSelectedViewPanel}
							showSpellcheck={p.showSpellcheck}
							editorId={p.editorId}
							preferredSpellcheckLang={p.preferredSpellcheckLang}
							spellcheckLangs={p.spellcheckLangs}
							selectedChannel={p.selectedChannel}
							content={p.content}
							shortUCS2={p.shortUCS2}
						/>
					</div>
					}
				</div>
			)}
			</Measure>
		)
	}
}

export const FooterCheckboxes = ({ chat, onClick, ...props }) => (
	<FooterCheckboxesBase
		chat={chat}
		onClick={useCallbackWithValue(!!chat, onClick)}
		{...props}
	/>
)

//todo: can refactor this better
const AttachmentBar = ({
	hide
	, canInsertIncomingAttachments
	, currentReply
	, chat
	, onHandleAttachCurrentFiles
	, selectAllQuestionFiles
	, isUploading
	, attachFile
	, onHandleAttachments
	, archive
	, attachments
	, onAttachmentDelete
	, onToggleAttachmentPanel
	, showAttachmentPanel
}) => {
	return (
		<div className="inner-body-attachment" hidden={hide}>
			<ul className="menu-list">
				<li className="attachment-panel-toggler">
					<Button
						className={`btn-toggle ${showAttachmentPanel ? "active" : ""}`}
						title={I("Toggle attachment panel")}
						iconClass={"icon-image"}
						onClick={onToggleAttachmentPanel}
					/>
				</li>
				{
					(canInsertIncomingAttachments && (currentReply !== RPLY_MANUAL && !chat && (currentReply === RPLY_ERRAND || currentReply === RPLY_COLLABORATE))) &&
					<ReplyOptionButtonItem
						label={I("Include original message attachment")}
						icon={"icon-include-attachment"}
						className="archivefile"
						onClick={onHandleAttachCurrentFiles}
						active={selectAllQuestionFiles}
					/>
				}
				<li className="grey" data-tooltip data-dropdown-menu>
					{!isUploading && <FileUploader
						cssClass="btn btn-default btn-file btn-fileupload"
						title={I("Browse")}
						uploadTo="errand/uploadAnswerAttachment"
						attachSingle={attachFile.single}
						multipleFile={attachFile.multiple}
						uploadAttachment={onHandleAttachments} />}
					<div hidden={!isUploading}>
						<img src={IMG_LOADING} />
					</div>
				</li>
				{/* {archive} */}
				<li className="attachment-items-wrapper">
					<SerialTagList value={attachments}
						showDelete={true}
						onDelete={onAttachmentDelete} />
				</li>
			</ul>
		</div>
	)
}

export class EditorFootnotes extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onSelectArchives = this.onSelectArchives.bind(this);
		this.handleAttachments = this.handleAttachments.bind(this);
		this.handleAttachCurrentFiles = this.handleAttachCurrentFiles.bind(this);
	}
	handleActionToggling = () => {
		this.props.onShowArchiveAttach(this.props.showArchiveDD);
	}
	onSelectArchives(selectedId) {
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
		let pc = this.props.chat;
		// Some Existing Bug ?:
		// Selecting archives attchment on chat "RPLY_COLLABORATE" will read files on 'RPLY_ERRAND' instead
		// This will prevent "REPLY_COLLABORATE" to add any files if already exist in "REPLY".
		// This feels like a bug. Will fix on the new UI but keep untouched on old UI
		if(cflagAttachment) {
			// here we check if 'reply ext collab panel' already have
			// the existing files on its OWN panel' NOT "REPLY's panel"

			let p = this.props;
			let chatCollabAttachments = p.collabArchiveAttachment;

			const alertMessage = I("This archive file has already been selected");

			if(p.currentReply === RPLY_COLLABORATE && chatCollabAttachments) {
				if(chatCollabAttachments.some(item => item.id === selectedId)){
					alert(alertMessage);
					return;
				}
			}
			else if(pc && pc.files && pc.files.length > 0){
				if(pc.files.some(item => item.id === selectedId)){
					alert(alertMessage);
					return;
				}
			}
		} else if(pc && pc.files && pc.files.length > 0){
			for(let i=0; i < pc.files.length; i++){
				if(pc.files[i].id == selectedId){
					alert(I("This archive file has already been selected"));
					return;
				}
			};
		}
		this.props.onUploadArchive(selectedId, this.props.archiveImgs,
			this.props.showAttachment, this.props.currentReply, this.props.chat);
	}
	handleAttachments(dataFiles, uploadTo) {
		this.props.onFileupload(dataFiles, uploadTo, this.props.showAttachment,
			this.props.currentReply, this.props.chat);
	}
	handleAttachCurrentFiles() {
		if(this.props.onSelectAllCurrentAttachment) {
			this.props.onSelectAllCurrentAttachment(!this.props.selectAllQuestionFiles, this.props.currentReply);
		}
	}
	render() {
		const {
			attachments,
			currentReply,
			onAttachmentDelete,
			collaborationAttachments,
			onInsertCollabAnswerAttachment,
			showUploadAttachmentBox,
			canInsertIncomingAttachments,
			selectAllQuestionFiles,
			isUploading,
			attachFile,
			archiveImgs,
			showArchiveDD,
			disabled,
			onSelectTab,
			toggleUploadAttachment,
			onToggleAttachmentPanel,
			showAttachmentPanel,
			hideAttachmentBar
		} = this.props;
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
		let archive, hide;
		if(currentReply !== RPLY_COMMENT && currentReply !== RPLY_MANUAL_COMMENT) {
			archive = <li className="grey" data-tooltip data-dropdown-menu>
					<div className="attachFileArchive">
						<ActionDropdown id="archiveFiles"
							title={<span className="archivefile">
									<a>{I("Choose file")}
										<i className={getChevronIcon(!showArchiveDD)} />
									</a>
								</span>}
							show={showArchiveDD}
							isHeader={true}
							headerText={I("Image archive")}
							direction={"up"}
							data={archiveImgs}
							onToggle={this.handleActionToggling}
							onSelectItem={this.onSelectArchives} />
					</div>
				</li>;
		} else {
			archive = null;
		}
		if(!showUploadAttachmentBox || disabled == true || hideAttachmentBar) {
			hide = true;
		}

		const manualReplies = currentReply === RPLY_MANUAL || currentReply === RPLY_MANUAL_COMMENT || currentReply === RPLY_MANUAL_IM;
		let attachmentPanelHeading = replyOptions[currentReply] ? replyOptions[currentReply].name : I('ATTACHMENTS');

		switch (attachmentPanelHeading) {
			case 'REPLY':
			  attachmentPanelHeading = I('REPLY');
			  break;
			case 'INTERNAL COMMENT':
			  attachmentPanelHeading = I('INTERNAL COMMENT');
			  break;
			case 'COLLABORATE':
			  attachmentPanelHeading = I('COLLABORATE');
			  break;
			case 'FORWARD TO EXTERNAL':
			  attachmentPanelHeading = I('FORWARD TO EXTERNAL');
			  break;
			case 'QUESTION':
			  attachmentPanelHeading = I('QUESTION');
			  break;
			case 'CLOSE WITH SUMMARY':
			  attachmentPanelHeading = I('CLOSE WITH SUMMARY');
			  break;
			default:
			  break;
		  }
		  

		if(cflagAttachment && !manualReplies) {
			return (
				<div className="message-attachment-bar" hidden={hide}>
					<AttachmentBar
						hide={hide}
						canInsertIncomingAttachments={canInsertIncomingAttachments}
						currentReply={currentReply}
						chat={this.props.chat}
						onHandleAttachCurrentFiles={this.handleAttachCurrentFiles}
						selectAllQuestionFiles={selectAllQuestionFiles}
						isUploading={isUploading}
						attachFile={attachFile}
						onHandleAttachments={this.handleAttachments}
						archive={archive}
						attachments={attachments}
						onAttachmentDelete={onAttachmentDelete}
						showAttachmentPanel={showAttachmentPanel}
						onToggleAttachmentPanel={()=>onToggleAttachmentPanel(showAttachmentPanel)}
					/>
					<div className="attachment-panel" hidden={!showAttachmentPanel} >
						<div className="attachment-panel__header">
							<div className="headings">{attachmentPanelHeading}</div>
							<Button
								className={"close-panel btn-transparent m-0 p-1"}
								iconClass={"icon-times"}
								onClick={() => onToggleAttachmentPanel(showAttachmentPanel)} //true to always close
							/>
						</div>
						<div className="attachment-panel__body">
							<ReplyAttachmentBox
								eid={this.props.errandId}
								show={true}
								archiveImgs={archiveImgs}
								onSelectArchives={this.onSelectArchives}
								collaborationAttachments={collaborationAttachments}
								onInsertCollabAnswerAttachment={onInsertCollabAnswerAttachment}
								dispatch={this.props.dispatch}
								isUploading={isUploading}
								attachFile={attachFile}
								onHandleAttachments={this.handleAttachments}
							/>
						</div>
					</div>
				</div>
			)
		} else {
		return <div className="message-attachment-bar" hidden={hide}>
				<div className="inner-body-attachment">
					<ul>
						{
							(canInsertIncomingAttachments && (currentReply !== RPLY_MANUAL && !this.props.chat && (currentReply === RPLY_ERRAND || currentReply === RPLY_COLLABORATE))) &&
							<ReplyOptionButtonItem
								label={I("Include original message attachment")}
								icon={"icon-include-attachment"}
								className="archivefile"
								onClick={this.handleAttachCurrentFiles}
								active={selectAllQuestionFiles}
							/>
						}
						<li className="grey" data-tooltip data-dropdown-menu>
							{!isUploading && <FileUploader
								cssClass="btn btn-default btn-file btn-fileupload"
								title={I("Browse")}
								uploadTo="errand/uploadAnswerAttachment"
								attachSingle={attachFile.single}
								multipleFile={attachFile.multiple}
								uploadAttachment={this.handleAttachments} />}
							<div hidden={!isUploading}>
								<img src={IMG_LOADING} />
							</div>
						</li>
						{archive}
						<li>
							<SerialTagList value={attachments}
								showDelete={true}
								onDelete={onAttachmentDelete} />
						</li>
					</ul>
				</div>
			</div>;
		}
	}
}

const ReplyAttachmentBox = compose(
	withErrandInfo,
	withHandlers({
		onSelect: props => (id, value, history) => {
			props.onSelectAttachment(
				id
				, value
				, props.currentReply
				, history
			);
		},
		onDeleteAttch: props => (aId, type, currentReply, fileName) => {
			props.onDeleteAnswerAttachment(
				DEL_ANSWER_ATTACHMENT
				, aId
				, type
				, currentReply
				, fileName
			);
		}
	 }),
	renameProps({
		attachmentSize: "size",
		attachmentList: "list",
		archiveAttachments: "archiveFiles",
		libraryAttachments: "libraryFiles",
	})
)(AttachmentBox)

ReplyAttachmentBox.displayName = 'ReplyAttachmentBox'; //inspect/debug name

class ReplyOneButton extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleButtonClick = this.handleButtonClick.bind(this);
	}
	handleButtonClick(e) {
		const p = this.props;
		if(!p.disabled) {
			if(p.tag === 'a') {
				e.preventDefault(); // NOTE: avoid trigger link to #
			}
			p.onButtonClick(p.type, e);
		}
	}
	render() {
		const p = this.props, {busy, disabled, reason, tag: Tag, text, id, hotkey} = p;
		let busyDOM = null, href, type, title, label;
		if(busy) {
			busyDOM = <span>
					<i className="fa fa-spinner fa-spin" aria-hidden="true" />
					&nbsp;
				</span>;
		}
		if(Tag === 'a') {
			href = '#';
		} else if(Tag === 'button') {
			type = 'button';
		}
		if(reason) {
			title = reason;
		} else {
			title = text;
		}

		label =  <HotkeyLabel key={id} id={id} text={text} hotkey={hotkey} />;

		return <Tag data-qa-id={"QA_btn_"+id} href={href} type={type} onClick={this.handleButtonClick}
				disabled={disabled} className={p.className} title={title}>
				{busyDOM}{label}
			 </Tag>;
	}
}

export const Buttons = ({className, children, condition, currentReply, onButtonClick, tag, agentWorking}) => {
	const {buttons, order} = condition;
	let bttns = [];
	each(order, v => {
		const bttn = buttons[v];
		if(!bttn || bttn.hidden) {
			return;
		}
		let disableBtn = agentWorking;
		if(currentReply === RPLY_COLLABORATE) {
			disableBtn = false;
		}
		bttns.push(<ReplyOneButton key={v} id={v} type={v} disabled={disableBtn}
			text={L(bttn.text)} hotkey={bttn.hotkey} className={bttn.class} busy={bttn.busy} tag={tag}
			reason={bttn.reason} onButtonClick={onButtonClick} />);
	});
	return <div className={className}>{bttns}{children}</div>;
};

let smsText = I('SMS count: {SMS_COUNT}');

const SmsWordCount = ({show, text, shortUCS2}) => {
	if(show == true && typeof text!== "undefined") {
		let smsNumber =  smsCount(text, shortUCS2);
		let theLine = smsText.replace('{SMS_COUNT}', smsNumber);
		return <div className="reply-editor-after">
				<div className="auto-save-text">{theLine}</div>
			</div>;
	}
	return null;

}

let saveText = I('This message was auto-saved at {DATE_TIME}');

const SavedNote = ({show, timestamp, timezone}) => {
	if(!show || typeof timestamp === "undefined") {
		return null;
	}
	saveText = L(saveText);
	const text = saveText.replace('{DATE_TIME}', moment(timestamp)
		.utcOffset(timezone).format('YYYY-MM-DD, HH:mm:ss'));
	return <div className="reply-editor-after">
			<div className="auto-save-text">{text}</div>
		</div>;
};

const RefDiv = ({ children, className, measureRef, style }) => (
	<div className={className} ref={measureRef} style={style}>{children}</div>
)

const StyledDiv = styled(RefDiv)`
	${({ fixedWidth }) => fixedWidth ? `width: ${fixedWidth};` : ''}
	&.reply.buttons {
		.button-row {
			a {
				${({ isBig }) => isBig ? 'font-size: 12px;' : ''}
			}
		}
	}
`
const Div = props => <StyledDiv isBig={useIsXL()} {...props} />

const warningText = I('Tweets longer than 280 characters will be divided into {NUMBER_OF_TWEET} tweets.');

const TweetWarningMessage = ({ numberOfTweet }) => (
	<div
		className="tweet-warning-msg"
		dangerouslySetInnerHTML={useMemo(
			() => ({ __html: warningText.replace('{NUMBER_OF_TWEET}', `<b>${numberOfTweet}</b>`) }),
			[numberOfTweet]
		)}
	/>
)

export const TweetWarningMsg = withMountWhenShow(TweetWarningMessage)

const FooterButtonsBase = ({
	agentWorking,
	className,
	condition,
	currentReply,
	currentChannel,
	answer,
	dynamicWidth,
	fixedWidth,
	navTabsHeight,
	numberOfTweet,
	onButtonClick,
	onResize,
	savedTimestamp,
	showTweetWarn,
	tag,
	timezone,
	shortUCS2,
	verticalView
}) => (
	<Measure
		bounds
		client={true}
		onResize={useCallback(({ bounds: { height, width } }) => {
			if (typeof onResize === 'function') {
				onResize(height)
			}
		}, [onResize])}
	>
	{({ measureRef }) => (
		<Div
			className={classNames("reply buttons", className)}
			fixedWidth={fixedWidth}
			measureRef={measureRef}
			style={useMemo(() => {
				if (!verticalView) {
					return emptyObject
				}
				return {
					position: "absolute",
					bottom: navTabsHeight + "px",
					width: (dynamicWidth-10) + "px"
				}
			}, [dynamicWidth, navTabsHeight, verticalView])}
		>
			<Buttons
				agentWorking={agentWorking}
				className="button-row"
				condition={condition}
				currentReply={currentReply}
				onButtonClick={onButtonClick}
				tag={tag}
			/>
			{ currentChannel === 'sms' &&
			<SmsWordCount
				show={true}
				text={answer}
				shortUCS2={shortUCS2}
			/>
			}
			<SavedNote
				show={currentReply === RPLY_ERRAND || (currentReply === RPLY_COLLABORATE)}
				timestamp={savedTimestamp}
				timezone={timezone}
			/>
			<TweetWarningMsg show={showTweetWarn} numberOfTweet={numberOfTweet} />
		</Div>
	)}
	</Measure>
)

export const FooterButtons = composeWithDisplayName(
	'FooterButtons',
	memo
)(FooterButtonsBase)

export const ReplyButtons = props => (
	<FooterButtons className='reply-footer' tag='a' {...props} />
)
