import React from 'react';
import { Link } from 'react-router-dom';
import { PF_TIMESTAMP, ANM_TAB_NOTIFICATION, ANM_TAB_ANNOUNCEMENT } from '../../common/v5/constants';
import HeaderFirstLink from './HeaderFirstLink';
import { getNotificationIcon, getNotificationMessage } from '../../common/v5/helpers';
import { readNotification, unreadNotification, dismissNotification, dismissMultiNotification, unreadMultiNotification, readMultiNotification } from '../../common/v5/socket';
import Moment from '../../reactcomponents/Moment';
import { withCancellableOpenErrand } from '../../containers/hocs';
import { NotifCheckbox } from '../../reactcomponents/Dropdown';
import update from 'immutability-helper'
import classNames from 'classnames';
import {
	ACCOUNTS_PATH_SLICE_V5,
	ACCOUNTS_PATH_SLICE
} from '../../common/v5/constants';
import { Nav } from 'reactstrap';
import { OneNavItem } from '../../reactcomponents/NavTabs';
import CFlag from '../../reactcomponents/CFlag';
import TruncateText from '../../components/v5/TruncateText';

class HeaderNotificationDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleSelectItem = this.handleSelectItem.bind(this);
		this.handleRead = this.handleRead.bind(this);
		this.handleUnread = this.handleUnread.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.state = {
			allSelected: false
			, selected: []
			, activeTab : ANM_TAB_NOTIFICATION
		}
	}
	componentDidMount() {
		this.props.onSetNotificationTab(ANM_TAB_NOTIFICATION);
	}
	handleToggleClick(key) {
		if (this.props.uiData.openHeaderArrow == key) {
			this.props.onToggleArrow(null);
		} else {
			this.props.onToggleArrow(key);
		}
	}
	handleDismissNotification(e, id) {
		e.stopPropagation();
		dismissNotification(id);
	}
	handleReadNotification(id, msg, linkPage) {
		const { data, openMessage, openStatistics, openLastErrandInThread, openCollaReplyPanel, openAccounts, openErrand, openAnnouncement } = this.props;
		const items = data;
	
		if (items[id] && !items[id].read) {
			readNotification(id);
		}
	
		switch (linkPage) {
			case "/messages":
				openMessage(msg.linkTo);
				break;
			case "/statistics":
				openStatistics(msg.linkTo);
				break;
			default:
				this.handleDefaultNavigation(msg, linkPage);
		}
	}
	
	handleDefaultNavigation(msg, linkPage) {
		const { openLastErrandInThread, openCollaReplyPanel, openAccounts, openErrand, openAnnouncement } = this.props;
		const featureOpenLastErrandInThread = features["external-experts.notification-link-to-last-errand"];
		const isCENAnnouncementActive = cflag.IsActive("2023-09-20.CEN-109.announcement");
	
		if (featureOpenLastErrandInThread && msg.text === 'MsgCollaborationReply') {
			openLastErrandInThread(msg.linkTo);
			openCollaReplyPanel(msg.linkTo, msg.eeThread, msg.eeQuery, true);
		} else {
			switch (msg.text) {
				case 'MsgCollaborationRequest':
					openCollaReplyPanel(msg.linkTo, msg.eeThread, msg.eeQuery);
					break;
				case 'MsgAccountsErr':
					openAccounts(msg.page || "", linkPage);
					break;
				case 'MsgCollaborationReply':
					openCollaReplyPanel(msg.linkTo, msg.eeThread, msg.eeQuery, true);
					break;
				case 'MsgAnnouncement':
					if (isCENAnnouncementActive) {
						openAnnouncement(msg.id);
					}
					break;
				default:
					openErrand(msg.linkTo);
			}
		}
	}
	
	handleUnreadNotification(event, id) {
		event.stopPropagation();
		unreadNotification(id);
	}
	
	handleReadAll(event) {
		event.stopPropagation();
		const isAnnouncementActive = cflag.IsActive("2023-09-20.CEN-109.announcement");
		const isAnnouncementTab = this.state.activeTab === ANM_TAB_ANNOUNCEMENT;
	
		readNotification(-1, isAnnouncementActive && isAnnouncementTab);
	}
	
	handleRead(event) {
		event.stopPropagation();
		const { allSelected, selected, activeTab } = this.state;
		const isAnnouncementActive = cflag.IsActive("2023-09-20.CEN-109.announcement");
		const isAnnouncementTab = activeTab === ANM_TAB_ANNOUNCEMENT;
	
		if (allSelected) {
			readNotification(-1, isAnnouncementActive && isAnnouncementTab);
		} else {
			const ids = selected.join(",");
			readMultiNotification(ids);
		}
	
		this.resetSelection();
	}
	
	handleUnread(event) {
		event.stopPropagation();
		const { allSelected, selected, activeTab } = this.state;
		const isAnnouncementActive = cflag.IsActive("2023-09-20.CEN-109.announcement");
		const isAnnouncementTab = activeTab === ANM_TAB_ANNOUNCEMENT;
	
		if (allSelected) {
			unreadNotification(-1, isAnnouncementActive && isAnnouncementTab);
		} else {
			const ids = selected.join(",");
			unreadMultiNotification(ids);
		}
	
		this.resetSelection();
	}
	
	resetSelection() {
		this.setState({ selected: [], allSelected: false });
	}
	
	handleClear(e) {
		e.stopPropagation();
		if (this.state.allSelected) {
			if (cflag.IsActive ("2023-09-20.CEN-109.announcement") ) {
				let announcement = (this.state.activeTab === ANM_TAB_ANNOUNCEMENT)
				dismissNotification(-1, announcement);
			} else {
				dismissNotification(-1);
			}
		} else {
			let ids = this.state.selected.join(",");
			dismissMultiNotification(ids);
		}
		this.setState({ selected: [], allSelected: false });
	}
	handleClearAll(e) {
		if (cflag.IsActive ("2023-09-20.CEN-109.announcement") ) {
			let announcement = (this.state.activeTab === ANM_TAB_ANNOUNCEMENT)
			dismissNotification(-1, announcement);
		} else {
			dismissNotification(-1);
		}
	}
	handleSelectAll() {
		let data = this.props.data;
		let selected = [];
		for (let key in data) {
			if (this.state.allSelected) {
				selected = [];
			} else {
				selected.push(data[key].id);
			}
		}
		this.setState({
			allSelected: !this.state.allSelected
			, selected: selected
		});
	}
	handleSelectItem(id, toggle) {
		let selected = [];
		if (toggle) {
			selected = update(this.state.selected, { $push: [id] });
		} else {
			var idx = $.inArray(id, this.state.selected);
			if (idx >= 0) {
				selected = update(this.state.selected, { $splice: [[idx, 1]] });
			}
		}
		this.setState({ selected: selected });
	}
	renderUnreadOption(id) {
		return (
			<div className="notices-list-item-actions">
				<button className="remove" onClick={(e) => this.handleUnreadNotification(e, id)}>
					<i className="far fa-bell"></i>
				</button>
			</div>
		);
	}
	handleChangeTab(tab) {
		this.setState({
			activeTab : tab
		});
		this.props.onSetNotificationTab(tab);
	}
	renderHeaderDropdown() {
		let items = this.props.data
			, reversedData = []
			, notificationMessages = []
			, maxItems = 4
			, showAll = false
			, announcementMessages = []
			, announcementShowAll = false
			;
		let notification = 'notification'
		let announcement = 'announcement'
		for (let key in items) {
			reversedData.push(items[key]);
		}
		let notificationCnt = 0,
			announcementCnt = 0 ;
		for (let i = reversedData.length - 1, n = 1; i >= 0; n++, i--) {
			let item = reversedData[i]
			let msg = item.msg;
			let messageClass = (item.read) ? "" : "unread";
			let linkPage = msg.page ? msg.page : "";
			let pathName = process.env.PATH_PREFIX + "v5" + linkPage;
			if (msg.text == "MsgAccountsErr") {
				pathName = process.env.PATH_PREFIX + ACCOUNTS_PATH_SLICE_V5[linkPage] + "/" + msg.linkTo;
				linkPage = pathName
			}
			let pushTo = notification;
			if (cflag.IsActive ("2023-09-20.CEN-109.announcement") && msg.text === "MsgAnnouncement") {
				pushTo = announcement;
				announcementCnt++;
				if (announcementCnt > maxItems) {
					announcementShowAll = true;
					continue;
				}
			} else if (msg.text !== "MsgAnnouncement") {
				notificationCnt++;
				if (notificationCnt > maxItems) {
					showAll = true;
					continue;
				}
			} else {
				continue;
			}
			if (this.props.forSidebar) {
				eval(pushTo + "Messages").push((
					<li key={n} className={classNames("notices-list-item notif", {"read": item.read})}>
						<NotifCheckbox
							id={item.id}
							key={"notifcb-" + item.id}
							data-qa-id={"notifcb-" + item.id}
							selected={this.state.selected.includes(item.id)}
							onClick={this.handleSelectItem}
						/>
						<Link key={"notices-" + n} to={{ pathname: pathName }}
							data-qa-id={"notification_" + n}
							onClick={() => this.handleReadNotification(item.id, msg, linkPage)}>
							<div className="notices-list-item-icon">
								{getNotificationIcon(msg.text)}
							</div>
							<div className="notices-list-item-text">
								<div className={messageClass}>
									{	getNotificationMessage(msg) &&
										<TruncateText truncateText={true} noFormatting={true}
											text={getNotificationMessage(msg)} limit={50} showMore={false}/>
									}
								</div>
								<span className={classNames("datetime", {"read": item.read})}>
									<Moment
										date={item.sent}
										parseFormat={PF_TIMESTAMP}
										timezoneOffset={this.props.agentTimezoneOffset}
									/>
								</span>
							</div>
						</Link>
						<div className="notices-list-item-actions">
							<button className="remove" onClick={(e) => this.handleDismissNotification(e, item.id)}>
								<i className="icon-times"></i>
							</button>
						</div>
					</li>
				));
			} else {
				eval(pushTo + "Messages").push((
					<li key={n} className="notices-list-item">
						<Link key={"notices-" + n} to={{ pathname: pathName }}
							data-qa-id={"notification_" + n}
							onClick={() => this.handleReadNotification(item.id, msg, linkPage)}>
							<div className="notices-list-item-icon">
								{getNotificationIcon(msg.text)}
							</div>
							<div className="notices-list-item-text">
								<div className={messageClass}>
									{getNotificationMessage(msg)} 
									<span className="datetime">
										<Moment
											date={item.sent}
											parseFormat={PF_TIMESTAMP}
											timezoneOffset={this.props.agentTimezoneOffset}
										/>
									</span>
								</div>
							</div>
						</Link>
						{item.read && this.renderUnreadOption(item.id)}
						<div className="notices-list-item-actions">
							<button className="remove" onClick={(e) => this.handleDismissNotification(e, item.id)}>
								<i className="icon-times"></i>
							</button>
						</div>
					</li>
				));
			}
		}
		let notificationAction = null;
		let showBulkAct = false;
		if ((this.props.forSidebar && this.state.selected.length > 0)
			|| (this.props.forSidebar && this.state.allSelected)) {
			showBulkAct = true;
		}
		let noticeListStyle = {};
		if( cflag.IsActive ("2023-09-20.CEN-109.announcement") ) {
			noticeListStyle = {
				display: 'block'
			}
		}
		if (this.props.noticeCount > 0 || notificationCnt > 0 || announcementCnt > 0) {
			notificationAction = <div className="notices-list-action" style={noticeListStyle}>
				{ !cflag.IsActive ("2023-09-20.CEN-109.announcement") &&
					<div className="notices-list-action-title" hidden={!this.props.forSidebar}>
						<NotifCheckbox
							data-qa-id={"notifcb-all"}
							selected={this.state.allSelected}
							onClick={this.handleSelectAll}
						/>
						{I("Notifications")}
					</div>
				}
				{ cflag.IsActive ("2023-09-20.CEN-109.announcement") &&
					<div className="notices-list-action-title" hidden={!this.props.forSidebar} style={{width: '100%'}}>
						<Nav style={{width: '100%', overflow: 'hidden'}} tabs>
							<OneNavItem
								active={this.state.activeTab === ANM_TAB_NOTIFICATION}
								onClick={(v) => { this.handleChangeTab(v) }}
								tabId={ANM_TAB_NOTIFICATION}
								text={I("Notifications")}
								title={"Notifications"}
							/>
							<OneNavItem
								active={this.state.activeTab === ANM_TAB_ANNOUNCEMENT}
								onClick={(v) => { this.handleChangeTab(v) }}
								tabId={ANM_TAB_ANNOUNCEMENT}
								text={I("Announcements")}
								title={"Announcements"}
							/>
						</Nav>
					</div>
				}
				<div className={classNames("notices-list-action-sub",
						{ "with-announcement": cflag.IsActive ("2023-09-20.CEN-109.announcement") }
					)}>
					{ cflag.IsActive ("2023-09-20.CEN-109.announcement") &&
						<div style={{
							display: eval( (this.state.activeTab === ANM_TAB_NOTIFICATION ? notification : announcement)+"Cnt===0 ?'none':'flex'"), 
							justifyContent: 'flex-start', width: '45%', marginLeft: '10px'}}>
							<NotifCheckbox
								data-qa-id={"notifcb-all"}
								selected={this.state.allSelected}
								onClick={this.handleSelectAll}
							/>
							<div className="select-all" onClick={this.handleSelectAll}>
								{I("Select All")}
							</div>
						</div>
					}
					<div className="notices-list-actions-wrapper">
						<div className="notices-list-action-link has-separator" onClick={this.handleClear} hidden={!showBulkAct}>
							{I('Clear')}
						</div>
						<div className="notices-list-action-link has-separator" onClick={this.handleRead} hidden={!showBulkAct}>
							{I('Mark as read')}
						</div>
						<div className="notices-list-action-link" onClick={this.handleUnread} hidden={!showBulkAct}>
							{I('Mark as unread')}
						</div>
						<div className="notices-list-action-link" onClick={this.handleReadAll} hidden={this.props.forSidebar}>
							{I('Read all')}
						</div>
					</div>
				</div>
			</div>;
		} else if ( cflag.IsActive ("2023-09-20.CEN-109.announcement") ?
			( (eval("announcementMessages").length + eval("notificationMessages").length) > 0)
			: (notificationMessages.length > 0)) {
			notificationAction = <div className="notices-list-action">
				<div className="notices-list-action-title" hidden={!this.props.forSidebar}>
					{I("Notifications")}
				</div>
				<div className="notices-list-action-sub">
					<div className="notices-list-action-link" onClick={this.handleClearAll}>
						{I('Clear all')}
					</div>
				</div>
			</div>;
		}
		let showAllText = I("Show all");
		if (this.props.forSidebar) {
			showAllText = I("View more notifications");
		}
		return (
			<div className='header-dropdown'>
				{notificationAction}
				<ul>
				<div className='notif-list'>
					{!cflag.IsActive ("2023-09-20.CEN-109.announcement") && notificationMessages}
				</div>
					<CFlag flag="2023-09-20.CEN-109.announcement">
						{eval( (this.state.activeTab === ANM_TAB_NOTIFICATION ? notification : announcement)+"Messages")}
					</CFlag>
					{
						(!cflag.IsActive ("2023-09-20.CEN-109.announcement") && showAll) ?
							<li className="notices-list-item" onClick={this.props.onShowAll}>
								<div className="notices-list-item-icon">
								</div>
								<div className="notices-list-item-text view-more">
									{showAllText}
								</div>
								<div className="notices-list-item-actions">
								</div>
							</li> : ''
					}
					<CFlag flag="2023-09-20.CEN-109.announcement">
						{
						( ( this.state.activeTab === ANM_TAB_NOTIFICATION && showAll ) ||
						  ( this.state.activeTab === ANM_TAB_ANNOUNCEMENT && announcementShowAll ) ) ?
							<li className="notices-list-item" onClick={this.props.onShowAll}>
								<div className="notices-list-item-icon">
								</div>
								<div className="notices-list-item-text view-more">
									{showAllText}
								</div>
								<div className="notices-list-item-actions">
								</div>
							</li> : ''
						}
					</CFlag>
				</ul>
			</div>
		)
	}
	render() {
		let className;
		const key = this.props.id;
		if (this.props.uiData.openHeaderArrow == key || this.props.forSidebar) className += ' dropped';
		return (
			<div key={key} className={className} onClick={() => this.handleToggleClick(key)}>
				<HeaderFirstLink
					id={key}
					title={this.props.title}
					forSidebar={this.props.forSidebar}
					noticeCount={this.props.noticeCount} />
				{this.renderHeaderDropdown()}
			</div>
		)
	}
}

export default withCancellableOpenErrand(HeaderNotificationDropdown);
