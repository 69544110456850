import { createGlobalStyle } from "styled-components"
import { LAYOUT_STYLES } from "../../styles/darkTheme/dt_layouts"
import { UI_STYLES } from "../../styles/darkTheme/dt_UI-components"
import { HEADER_ACTIONS } from "../../styles/darkTheme/dt_headerActions";
import { SIDEBAR_STYLES } from "../../styles/darkTheme/dt_sidebars";
import { CKE_STYLES } from "../../styles/darkTheme/dt_cke";
import { REPLY_BOX_STYLES } from "../../styles/darkTheme/dt_replyBox";
import { ERRAND_STYLES } from "../../styles/darkTheme/dt_errand";
import { ADMIN_STYLES } from "../../styles/darkTheme/dt_admin";
import { TAG_STYLES } from "../../styles/darkTheme/dt_tags";
import { TABLE_STYLES } from "../../styles/darkTheme/dt_table";
import { FORM_STYLES } from "../../styles/darkTheme/dt_forms";
import { EXPORT_STYLES } from "../../styles/darkTheme/dt_export";
import { KNOWLEDGEBASE_STYLES } from "../../styles/darkTheme/dt_knowledgebase";
import { SEARCH_STYLES } from "../../styles/darkTheme/dt_search";
import { SEARCH_ANSWER_STYLES } from "../../styles/darkTheme/dt_searchAnswers";
import { STATISTIC_STYLES } from "../../styles/darkTheme/dt_statistic";
import { TYPOGRAPHY_STYLES } from "../../styles/darkTheme/dt_typography";
import { WORKFLOWTABLE_STYLES } from "../../styles/darkTheme/dt_workflowTable";

const appendDarkStyles = () => {
  return (`
    ${LAYOUT_STYLES}
    ${HEADER_ACTIONS}
    ${SIDEBAR_STYLES}
    ${REPLY_BOX_STYLES}
    ${CKE_STYLES}
    ${FORM_STYLES}
    ${TAG_STYLES}
    ${TABLE_STYLES}
    ${ERRAND_STYLES}
    ${EXPORT_STYLES}
    ${KNOWLEDGEBASE_STYLES}
    ${SEARCH_STYLES}
    ${SEARCH_ANSWER_STYLES}
    ${STATISTIC_STYLES}
    ${ADMIN_STYLES}
    ${TYPOGRAPHY_STYLES}
    ${WORKFLOWTABLE_STYLES}
    ${UI_STYLES}
  `
  )
}

export const GlobalStyles = createGlobalStyle`
  html * {
    /* Track */
    /* Handle */
    
    > main:first-child {
      background-color: ${({ theme }) => theme.midGrey};
    }
    // .layout {
    //   .app-header {
    //     i {
    //       color: ${({ theme }) => theme.lightGrey};
    //     }
    //   }
    // }
    ${props => (props.isDarkMode && appendDarkStyles())};
  }
  `
