import React, { Fragment, useEffect, useState, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { MM_EXPORT_CALL, INPUT_EMPTY_WARNING, BTN_TXT_SAVING, BTN_TXT_SAVE } from '../../common/v5/constants';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	TableHeader,
	EditorFooter,
	EditorHeaderWDownload,
	SaveButton,
	CancelButton,
	AdminListAndEditLayout,
	withEditWrap,
	CustomButton
} from '../../reactcomponents/Admin';
import { ButtonIcon } from '../../reactcomponents/HeaderActions';
import { TABLE_STANDARD_OPTIONS } from '../../reactcomponents/Table';
import { renderFileActionBtns as renderActionButtons } from '../../views/v5/admin';
import { useCallbackMultiValues } from '../../hooks/callback';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';
import {
	TabContent
	, TabPane
	, Nav
} from 'reactstrap';
import { OneNavItem } from '../../reactcomponents/NavTabs';
import { TextInputRow, SelectInputRow, FormInputWithLabelRow, TextField } from '../../reactcomponents/Form';
import { LabelToggleSwitch as ToggleSwitch } from './ToggleSwitch';
import { formatValueToNameObj } from '../../common/v5/helpers';
import { AddTextItemInputContainer } from '../../reactcomponents/AddTextItemInput';
import { NamedCheckbox } from '../../reactcomponents/SquareCheckbox';
import { SerialMultiSelect } from '../../reactcomponents/Dropdown';
import { SingleCalenderDD  } from '../../reactcomponents/DatePicker';
import EllipsisText from '../../reactcomponents/EllipsisText';
import { ModalBox } from '../../reactcomponents/Modal';
import { ExportPreviewTableCtnr } from '../../views/v5/exportV5';
import { GeneratedCdrExportCtnr } from '../../views/exports/exportCtnr';

const TimeFrameType = [
	{ 'Id': 0, 'Name': I('Relative') },
	{ 'Id': 1, 'Name': I('Absolute') }
];

const TAB_GENERAL = 1
	, TAB_ADVANCE = 2

export const SelectedReadOnly = ({title, selected = [], className, data = [], ...props}) => {
	let selectedList = [], matchSelected;
	$.each(selected , (i,v) => {
		if(data && data.length > 0){
			matchSelected = data.find(obj => obj.Id == v);
			if(matchSelected) {
				selectedList.push(<li key={i+matchSelected.Name}>{matchSelected.Name}</li>);
			}
		}
	});
	return (
		<div className={className} hidden={selected && selected.length > 0 ? false : true}>
			<label>{title} : </label>
			<ul className='export-selected-data'>
				{selectedList}
			</ul>
		</div>
	);
};

const ExportCallEditForm = ({
	hidden,
	input,
	baseButtons,
	timezone,
	fileType,
	runInterval,
	allAreas,
	areas,
	searchInterval,
	columnsToDisplay,
	advanceCallFilters,
	onHandleTextInputBlur,
	onChangeInput,
	onSetValue
}) => {
	const [activeTab, setActiveTab] = useState(TAB_GENERAL);
	const [showExportCols, setShowExportCols] = useState(false);
	const [showArea, setShowArea] = useState(false);

	const addText = (id, text) => {
		onSetValue(id, text);
	}

	const removeText = (id, index) => {
		onSetValue(id, index, true);
	}

	function getHours() {
		const hours = [];
		for (let i = 0; i < 24; i++) {
			let hoursObj = {};
			if (i < 10) {
				hoursObj = { 'Id': i, 'Name': ('0' + i).toString() };
			} else {
				hoursObj = { 'Id': i, 'Name': i.toString() };
			}
			hours.push(hoursObj);
		}
		return hours;
	}
	function getMinutes() {
		const minutes = [];
		for (let i = 0; i < 60; i++) {
			let minutesObj = {};
			if (i < 10) {
				minutesObj = { 'Id': i, 'Name': ('0' + i).toString() };
			} else {
				minutesObj = { 'Id': i, 'Name': i.toString() };
			}
			minutes.push(minutesObj);
		}
		return minutes;
	}

	function checkToggleNot(field, value) {
		onSetInput(value, field)
	}

	function onSetInput(value, field) {
		onSetValue(field, value);
	}

	function handleInputText(e) {
		const value = e.target.value;
		const field = e.target.name;
		onSetValue(field, value);
	}

	function onToggleExportCols() {
		setShowExportCols(!showExportCols);
	}
	function onToggleArea() {
		setShowArea(!showArea);
	}
	function onSelectInput(field, val) {
		let newVals = [], newValsArr = [];
		if(val) {
			newVals = val.split(',');
		}
		newValsArr = newVals.map(col => parseInt(col));
		onSetValue(field, newValsArr);
	}
	function onSelectDate(field, date) {
		onSetValue(field, date);
	}
	function onPrivacyCheck(toggle) {
		onSetValue('privacyBool', toggle );
		onSetValue('privacy', toggle ? 'Yes' : 'No' );
	}
	return (
		<div>
			<form id="callExportEdit" className="admin-one-form edit-admin-form export-call-form" hidden={hidden}>
				<div className='edit-admin-form'>
					<section className={"export-general-tabs"}>
						<Nav tabs>
							<OneNavItem
								active={activeTab === TAB_GENERAL}
								onClick={(v) => { setActiveTab(v) }}
								tabId={TAB_GENERAL}
								text={I("General")}
							/>
							<OneNavItem
								active={activeTab === TAB_ADVANCE}
								onClick={(v) => { setActiveTab(v) }}
								tabId={TAB_ADVANCE}
								text={I("Advance")}
							/>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId={TAB_GENERAL}>
								<TextInputRow
									name="name"
									className="admin-text-input with-helper"
									label={I(`Export name`)}
									value={input.name ? input.name : ""}
									onChange={onChangeInput}
									onBlur={onHandleTextInputBlur}
									warning={input.name ? "" : INPUT_EMPTY_WARNING}
									inlineLabel={false}
									mandatory={true}
								/>
								<TextInputRow
									id={"admin-ivrDescription"}
									name="desc"
									className="admin-text-input with-helper"
									label={I(`Export description`)}
									value={input.desc ? input.desc : ""}
									onChange={onChangeInput}
									onBlur={onHandleTextInputBlur}
									inlineLabel={false}
								/>
								<div className='admin-input-wrapper-half'>
									<ToggleSwitch
										id={"privacyBool"}
										name={"privacyBool"}
										data-qa-id="privacy"
										label={I("Export only vieawable for this user")}
										checked={input.privacyBool}
										onClick={onPrivacyCheck}
										listWrap={false}
										className="admin-text-input"
									/>
									<ToggleSwitch
										id={"emailExport"}
										name={"emailExport"}
										data-qa-id="exportEmailWhenComplete"
										label={I("Email export when complete")}
										checked={input.emailExport}
										onClick={onSetInput}
										listWrap={false}
										className="admin-text-input"
										helperTxt={I("This option will result in an email being sent out to the E-mail address configured. The generated file will be included in the E-mail for downloading..")}
									/>
								</div>
								{
									input.emailExport &&
									<Fragment>
										<FormInputWithLabelRow
											label={I("Email address")}
											warning={input.emailAddress ? "" : INPUT_EMPTY_WARNING}
											className="admin-text-input with-helper"
											inlineLabel={false}
											mandatory={true}
										>
											<TextInputRow
												id={"emailAddress"}
												name="emailAddress"
												value={input.emailAddress}
												onChange={onChangeInput}
												onBlur={onHandleTextInputBlur}
											></TextInputRow>
										</FormInputWithLabelRow>
										<FormInputWithLabelRow
											label={I("Email header")}
											warning={""}
											className="admin-text-input with-helper"
											inlineLabel={false}
										>
											<TextInputRow
												id={"emailHeader"}
												name="emailHeader"
												value={input.emailHeader}
												onChange={onChangeInput}
												onBlur={onHandleTextInputBlur}
											></TextInputRow>
										</FormInputWithLabelRow>
										<FormInputWithLabelRow
											label={I("Email body")}
											warning={""}
											className="admin-text-input with-helper"
											inlineLabel={false}
										>
											<TextInputRow
												id={"emailBody"}
												name="emailBody"
												value={input.emailBody}
												onChange={onChangeInput}
												onBlur={onHandleTextInputBlur}
											></TextInputRow>
										</FormInputWithLabelRow>
									</Fragment>
								}
								<FormInputWithLabelRow
									label={I('Time zone')}
									warning={""}
									className={"export-gen-full-select"}
									inlineLabel={false}
								>
									<SelectInputRow
										id="timeZone"
										name="timeZone"
										className=""
										label={""}
										option={formatValueToNameObj(timezone, "Id", "Name")}
										value={input.timeZone}
										onSelect={onSetInput}
									/>
								</FormInputWithLabelRow>
								<div className=''>
									<FormInputWithLabelRow
										label={I("Schedule time to run export")}
										warning={""}
										className={""}
										inlineLabel={false}
									>
										<div className="admin-input-wrapper-one-two">
											<SelectInputRow
												id="time"
												name="time"
												className=""
												option={formatValueToNameObj(runInterval, "Id", "Name")}
												value={input.time}
												onSelect={onSetInput}
											/>
											<SelectInputRow
												id="hour"
												name="hour"
												className=""
												label={""}
												option={getHours()}
												value={input.hour}
												onSelect={onSetInput}
											/>
											<SelectInputRow
												id="minute"
												name="minute"
												className=""
												label={""}
												option={getMinutes()}
												value={input.minute}
												onSelect={onSetInput}
											/>
										</div>
									</FormInputWithLabelRow>
								</div>
								<div className='admin-input-wrapper-half'>
									{
										input.time === 10 &&
										<FormInputWithLabelRow
											label={I("Time frame type")}
											mandatory={true}
											warning={""}
											className={""}
											inlineLabel={false}
										>
											<SelectInputRow
												id="timeFrameType"
												name="timeFrameType"
												className=""
												option={TimeFrameType}
												value={input.timeFrameType}
												onSelect={onSetInput}
											/>
										</FormInputWithLabelRow>
									}
									<FormInputWithLabelRow
										label={I("Call interval")}
										mandatory={true}
										warning={""}
										className={""}
										inlineLabel={false}
									>
										<SelectInputRow
											id="searchInterval"
											name="searchInterval"
											className=""
											option={formatValueToNameObj(searchInterval, "Id", "Name")}
											value={input.searchInterval}
											onSelect={onSetInput}
											hide={input.timeFrameType != 0 ? true : false}
										/>
										{
											(input.timeFrameType == 1 && input.time == 10) &&
											<div className="export-date-selector">
												<SingleCalenderDD
													id="absoluteTimeFrom"
													nam e="absoluteTimeFrom"
													title={I("Start")}
													selected={input.absoluteTimeFrom === "None" ? null : input.absoluteTimeFrom}
													style={{ display: "block" }}
													onSelect={(d) => onSelectDate("absoluteTimeFrom", d)}
												/>
												<SingleCalenderDD
													id="absoluteTimeTo"
													name="absoluteTimeTo"
													title={I("end")}
													selected={input.absoluteTimeTo === "None" ? null : input.absoluteTimeTo}
													style={{ display: "block" }}
													onSelect={(d) => onSelectDate("absoluteTimeTo", d)}
												/>
											</div>
										}
									</FormInputWithLabelRow>
								</div>
								<div className='admin-input-wrapper-half export-gen-multi-select'>
									<FormInputWithLabelRow
										label={I('Areas')}
										mandatory={true}
										inlineLabel={false}
									>
										<SerialMultiSelect
											id={"area"}
											name="area"
											title={I("Select Area")}
											className="popup-multi-select admin-text-input-qrm"
											overrideTitle={true}
											groupSelect={true}
											nested={{key: 'Areas'}}
											data={areas}
											idFields={{id: "Id", name: "Name"}}
											selected={input.area}
											onSelect={(v) => onSelectInput('area', v)}
											onToggle={onToggleArea}
											show={showArea}
											selectAll={true}
											selectNone={true}
										/>
										{
											input.area && input.area.length > 0 &&
											<div style={{width: "100%", display: "inline-block", margin: "10px"}}>
												<SelectedReadOnly title={I("Selected Areas")} selected={input.area} data={allAreas} className="export-selected-options" />
											</div>
										}
									</FormInputWithLabelRow>
								</div>
								<div className='admin-input-wrapper-half export-gen-multi-select'>
									<FormInputWithLabelRow label={I("Columns to display")} inlineLabel={false}>
										<SerialMultiSelect
											id={"displayKey"}
											name={"displayKey"}
											className={"popup-multi-select"}
											flip={true}
											overrideTitle={true}
											title={I("Select an item")}
											data={columnsToDisplay}
											idFields={{id: "Id", name: "Name"}}
											selected={input.displayKey}
											onSelect={(v) => onSelectInput('displayKey', v)}
											onToggle={onToggleExportCols}
											show={showExportCols}
											selectAll={true}
											selectNone={true}
										/>
										{
											input.displayKey && input.displayKey.length > 0 &&
											<div style={{width: "100%", display: "inline-block", margin: "10px"}}>
												<SelectedReadOnly title={I("Selected Display Keys")} selected={input.displayKey} data={columnsToDisplay} className="export-selected-options" />
											</div>
										}
									</FormInputWithLabelRow>
									<FormInputWithLabelRow
										label={I("Export file type")}
										mandatory={true}
										warning={""}
										className={""}
										inlineLabel={false}
									>
										<SelectInputRow
											id="fileType"
											name="fileType"
											className=""
											option={formatValueToNameObj(fileType, "Id", "Name")}
											value={input.fileType}
											onSelect={onSetInput}
										/>
									</FormInputWithLabelRow>
								</div>
							</TabPane>
							<TabPane tabId={TAB_ADVANCE}>
								<div className={"export-advance-tabs"}>
									{
										/* Notes:
										appended the "name" on each filter to with below text to make it unique and easy to be identified
										
										Filter <- text input
										NotToggle <- the NOT toggle checkbox
										MatchingCategory <- the matching category dropdown
										
										For example. for "callerId" will become "callerId_Filter", "callerId_NotToggle", "callerId_MatchingCategory"

										Adjust "advanceCallFilters" in backend for more filters
										*/
										advanceCallFilters.map((filter, index) => {
											const filterTxtId = filter.name+"_Filter";
											let filterTxts = [];
											filterTxts = input[filterTxtId] ? input[filterTxtId].split(",") : [];
	
											return (
												<div className="export-filter-wrapper" key={filter.name+"-"+index} id={filter.name+"-"+index}>
													<FormInputWithLabelRow
														label={filter.label}
														warning={""}
														className={"export-filter-row"}
														inlineLabel={false}
													>
														<NamedCheckbox
															label={I("Not")}
															id={filter.name+"-"+index+"_cb"}
															me={filter.name+"_NotToggle"}
															checked={input[filter.name+"_NotToggle"]}
															show={true}
															onClick={checkToggleNot}
														/>
														<SelectInputRow
															id={filter.name+"-"+index+"_select"}
															name={filter.name+"_MatchingCategory"}
															className=""
															option={formatValueToNameObj(filter.options, "Id", "Name")}
															value={input[filter.name+"_MatchingCategory"]}
															onSelect={onSetInput}
														/>
														{ 
															filter.haveFilter && (
																<AddTextItemInputContainer id={filterTxtId} name={filterTxtId} onAdd={addText} onRemove={removeText} list={filterTxts} />
															)
														}
														{ filter.inputType === "number" &&
															<TextField
																id={filter.name+"-"+index+"_input"}
																name={filter.name+"_Input"}
																type="number"
																value={input[filter.name+"_Filter"] ? input[filter.name+"_Filter"] : ""}
																onChange={handleInputText}
																placeholder={filter.placeholder ? filter.placeholder : ""}
															/>
														}
													</FormInputWithLabelRow>
												</div>
											)
										})
									}
								</div>
							</TabPane>
						</TabContent>
					</section>
					<div style={{ marginTop: '50px' }}>
						{baseButtons}
					</div>
				</div>
			</form>
		</div>
	);
}

const EditForm = withEditWrap(ExportCallEditForm);

const EditFormButtons = ({
	onCancel,
	onDelete,
	onPreview,
	onRun,
	saveTxt = I('Save'),
	disableSave,
	onSave
}) => {
	return (
		<EditorFooter
			left={
				<div className="export-footer-btn">
					<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={onDelete} text={I("Remove")} />
				</div>
			}
		>
			<CancelButton onClick={onCancel} />
			<CustomButton className={"btn-blue"} disabled={disableSave} onClick={onPreview} text={I("Preview")} />
			<SaveButton className={"btn-blue"}  disabled={disableSave} onClick={onSave} text={saveTxt} />
			<CustomButton className={"btn-blue"} disabled={disableSave} onClick={onRun} text={I("Run now")} />
		</EditorFooter>
	)
}

const ExportSearchBar = (props) => {
	const ref = useRef();
	const [txt, setTxt] = useState("");

	const handleChange = () => {
		setTxt(ref.current.value);
		props.onSearch(ref.current.value);
	};
	return (
		<div className='adminFilter' style={{margin: '20px 0px'}}>
			<TextInputRow
				id={"tag-search"}
				name={"tag-search-name"}
				className={"admin-filter-select search-field"}
				icon="icon-search"
				placeholder={I("Search by export name")}
				value={txt}
				onChange={handleChange}
				ref={ref}
			/>
		</div>
	);
};

const ExportCallList = ({
	view,
	input,
	list,
	areas,
	allAreas,
	timezone,
	fileType,
	runInterval,
	searchInterval,
	columnsToDisplay,
	advanceCallFilters,
	activeId,
	mobileMode,
	onLoad,
	onLoadToEdit,
	onDeleteFromList,
	onCancel,
	onRunCallExport,
	onSave,
	onCloseForm,
	onClickToggleEnable,
	onSetValue,
	onPreviewCallExport,
	onCdrExportDownload,
	showExportPreview,
	showGeneratedCdrExport,
	onCloseExportPreview,
	onClosePreviewCallExport,
	saveStatus
}) => {

	let disableSave = true;
	let buttonSaveTxt = BTN_TXT_SAVE;
	if(saveStatus && saveStatus.status === 1) {
		buttonSaveTxt = BTN_TXT_SAVING;
		disableSave = true;
	} else {
		if (input && input.name && input.name.length !== 0) {
			disableSave = false;
		}
	}

	useEffect(() => {
		if (onLoad) {
			onLoad(MM_EXPORT_CALL, false);
		}
	}, []);

	const handleEdit = (id) => {
		onLoadToEdit(id)
		setShowForm(true);
	}

	const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) {
			onDeleteFromList(id);
		}
	}

	const handleToggleEnable = (id, toggle) => {
		onClickToggleEnable(id, !toggle);
	}

	const actionDatas = {
		onEdit: handleEdit
		, onDelete: handleDelete
	}

	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'name',
			text: I('Name'),
			sort: true
		},
		{
			dataField: 'createdBy',
			text: I('Created By'),
			sort: true,
		},
		{
			dataField: 'privacyBool',
			text: I('Personal'),
			sort: true,
			formatter: (cell, row) => {
				return <span>{cell ? 'Yes' : 'No'}</span>
			}
		},
		{
			dataField: 'action',
			text: I('Run'),
			sort: true,
			formatter: (cell, row) => {
				return <EllipsisText text={cell} />
			}
		},
		{
			dataField: 'activate',
			text: I('Enabled'),
			sort: true,
			formatter: (cell, row) => {
				return <SwitchCheckbox
					active={cell}
					id={row.id}
					key={"switch-" + row.id}
					inputId={row.id}
					name={row.name}
					disabled={false}
					onClick={(t) => handleToggleEnable(row.id, t)}
				/>
			}
		},
		{
			isDummyField: true,
			dataField: 'tbl-action',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderActionButtons(cell, row, actionDatas);
			},
		}
	];

	const hiddenRows = [0];

	const defaultSorted = [{
		dataField: 'name',
		order: 'asc'
	}];

	const isNew = activeId <= 0;

	const rowEvents = {
		onClick: (e, row) => {
			e.stopPropagation();
			setShowForm(true);
			return onLoadToEdit(row.id, MM_EXPORT_CALL, false, list);
		}
	};

	const rowClasses = (row) => {
		let classes = null;
		if (row.id === activeId) {
			classes = 'active-row';
		}
		return classes;
	};

	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		onCancel={useCallbackMultiValues(onCancel, activeId)}
		onDelete={useCallbackMultiValues(handleDelete, activeId)}
		disableSave={disableSave}
		saveTxt={buttonSaveTxt}
		onSave={onSave}
		onRun={useCallbackMultiValues(onRunCallExport, activeId)}
		onPreview={useCallbackMultiValues(onPreviewCallExport, activeId)}
	/>

	const handleTextInputChange = (e) => {
		let field = e.target.name, value = e.target.value;
		onSetValue(field, value);
	}

	const handleCdrDownload = () => {
		onCdrExportDownload(activeId);
	}

	const [showForm, setShowForm] = useState(false);

	const handleShowForm = () => {
		onLoadToEdit(0);
		setShowForm(true);
	}

	let listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={I('Call detail record')}
				type='button'
				iconClass='icon-add'
				onClickAdd={(e) => handleShowForm()}
				hide={false}
				hideAddIcon={false}
				showInline={true}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
		<ToolkitProvider
			keyField="id"
			data={ list }
			columns={ columns }
			search
			>
			{ props => (
				<Fragment>
					<ExportSearchBar { ...props.searchProps } />
					<BootstrapTable
						keyField="id"
						data={list}
						hiddenRows={ hiddenRows }
						noDataIndication={I("No data to display")}
						columns={columns}
						bordered={false}
						condensed={false}
						defaultSorted={defaultSorted}
						pagination={paginationFactory(TABLE_STANDARD_OPTIONS)}
						rowEvents={rowEvents}
						rowClasses={rowClasses}
						hover
						{ ...props.baseProps }
					/>
				</Fragment>
			)}
			</ToolkitProvider>
		</ListContentWrapper>
	</Fragment>;

	let downloadLink = (
		<span className="page-header-small-title" style={{float:"right"}}>
			<a href="#" onClick={handleCdrDownload}>
				<i className="fas fa-download" style={{paddingLeft:"5px"}}></i>
			</a>
		</span>
	)
	let downloadButton = (
		<ButtonIcon
			className={"btn export-download float-right"}
			icon={"fas fa-download"}
			onClick={handleCdrDownload}
			title={I("Download export")}
			data-qa-id={"mobile-btn-download-export"}
			noTooltip={true}/>
	)
	const renderDownload = !mobileMode ? downloadLink : downloadButton;

	let editSection = "";

	if(input) {
		editSection = <Fragment>
			{/* <AlertWrapper  id={"ExportCallAlert"} hidden={adminAlert.show ? false : true} onDismiss={onDismissAlert}>
				<DismissableAlert show={adminAlert.show ? true : false}
					role={adminAlert.type === "error" ? "danger" : "success"}
					text={adminAlert.msg}
					icon={adminAlert.type === "error" ? "icon-caution" : "icon-new-tick-single"}
					onDismiss={onDismissAlert}
				/>
			</AlertWrapper> */}
			<EditorHeaderWDownload
				hidden={!showForm}
				isNew={isNew}
				onClickClose={onCloseForm}
				title={I("Edit call detail record")}
				download={renderDownload}
			/>
			<EditForm
				activeId={activeId}
				input={input}
				timezone={timezone}
				allAreas={allAreas}
				areas={areas}
				fileType={fileType}
				runInterval={runInterval}
				searchInterval={searchInterval}
				columnsToDisplay={columnsToDisplay}
				advanceCallFilters={advanceCallFilters}
				view={view}
				onTextInputChange={handleTextInputChange}
				onChangeInput={handleTextInputChange}
				onSave={onSave}
				onCancel={onCloseForm}
				onDelete={handleDelete}
				isNew={isNew}
				hidden={!showForm}
				baseButtons={baseButtons}
				onSetValue={onSetValue}
			/>
			{showExportPreview &&
				<ModalBox
					show={true}
					onClose={onClosePreviewCallExport}
					className={"generated-cdr-export"}
				>
					<Fragment>
						<ExportPreviewTableCtnr />
					</Fragment>
				</ModalBox>
			}
			{showGeneratedCdrExport &&
				<ModalBox
					show={true}
					onClose={onCloseExportPreview}
					className={"generated-cdr-export"}
					headerContent={""}
				>
					<Fragment>
						<GeneratedCdrExportCtnr />
					</Fragment>
				</ModalBox>
			}
		</Fragment>;
	}

	return <AdminListAndEditLayout
		className={"call-export-page"}
		listSection={listSection}
		editSection={editSection}
	/>
}

export default ExportCallList;
