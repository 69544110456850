import React from 'react';
import { PF_TIMESTAMP } from '../../common/v5/constants';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import { FullTimeMoment } from './BriefErrand';
import {I} from '../../common/v5/config.js';
import {
	defaultPhoto
} from '../../common/v5/helpers';

const BriefErrandNotices = ({errand}) => {
	if(!errand || !errand.style ||
		!errand.style.themeWarning) {
		return null;
	}
	const theme = errand.style.themeWarning;
	let warnIcon, alertIcon, hideAlert = true;
	if(theme == "System Warning Alert Theme") {
		warnIcon = 'icon-caution';
	} else if(theme == "System Warning Theme") {
		warnIcon = 'icon-clock';
	} else {
		warnIcon = 'icon-clock';
	}

	if(errand.data && errand.data.highpriority) {
		alertIcon = 'icon-caution';
		hideAlert = false;
	}
	return <ul className="conversation-notices">
			<li className="warning"><i className={warnIcon}></i></li>
			<li className="high-priority" hidden={hideAlert}><i className={alertIcon}></i></li>
		</ul>;
}

class MessageBrief extends React.Component {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
	}
	handleSelect(v) {
		this.props.onSelect(this.props.me, v);
	}
	serviceClass(name) {
		// TODO: should base on service constant integer which require service
		// to pass render the constant service ID.
		name = name.toLowerCase();
		if(name === 'email' || name === 'manual') {
			return 'icon-mail';
		} else if(name === 'facebook' || name === 'facebook (manual)') {
			return 'icon-facebook';
		} else if(name === 'chat') {
			return 'icon-mail';
		} else if(name === 'twitter') {
			return 'icon-twitter';
		} else if(name === 'linkedin') {
			return 'icon-linkedin';
		} else if(name === 'vkontakte' || name === 'vkontakte (manual)') {
			return 'icon-mail';
		} else if(name === 'line' || name === 'line (manual)') {
			return 'icon-mail';
		} else {
			return 'icon-mail';
		}
	}
	readOrAnswered(e, d) {
		let res = '';
		// if(d.hasAnswer || d.answered) {
		// 	res = ' with-answer';
		// }
		if(e.status) {
			res += ' read'; //this means all errand in My Errands have this ui flag
		}
		return res;
	}
	handleClick() {
		const p = this.props;
		p.onClick(p.me);
	}
	handleDoubleClick() {
		const p = this.props;
		p.onDoubleClick(p.me);
	}
	render() {
		const p = this.props, erd = p.errand, e = erd.data,
			convClass = 'conversation' + this.readOrAnswered(erd, e);
		let frm = erd.origin, msg, agentPhoto; //, agentImgSrc;
		agentPhoto = p.avatar;
		// if(agentPhoto){
		// 	agentImgSrc = <img src={agentPhoto} alt={I('Agent photo')} />;
		// }else{
		// 	agentImgSrc = defaultPhoto(true);
		// }
		msg = erd.subject;
		return <div data-qa-id={"msgBrief-"+p.me} className={convClass} onClick={this.handleClick} onDoubleClick={this.handleDoubleClick}>
				<div className="conversation-left-side">
					<div className="conversation-checkbox">
						<SquareCheckbox data-qa-id={"msgBrief-cb-"+p.me} onClick={this.handleSelect}
							checked={p.select} />
					</div>
					<ProfilePhoto width={"70"} isAgent={true} //errand={e} 
						photo={agentPhoto}
						className={"conversation-photo"} />
				</div>
				<div className="conversation-center">
					<div className="conversation-name">{frm}</div>
					<div className="conversation-message">{msg}</div>
				</div>
				<div className="conversation-right-side">
					<FullTimeMoment
						date={erd.createdAt}
						parseFormat={PF_TIMESTAMP}
						timezoneOffset={p.tzOffset}
					/>
				</div>
				<BriefErrandNotices errand={erd} />
			</div>;
	}
}

export default MessageBrief;
