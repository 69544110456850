import React, { Component, PureComponent } from 'react';
import classNames from 'classnames';
import { ClientMessageSelection } from '../../reactcomponents/Chatbot';
import { SelectList2 } from '../../reactcomponents/SelectBox';
import PopupPage from "../../reactcomponents/PopupPage";
import { Body } from '../../reactcomponents/AlertConfirm';
import { I, L } from '../../common/v5/config.js';
import {
	AT_LIBRARY
	, UNSELECT
	, USER_VOTE_DOWN
	, USER_VOTE_UP
	, START_TEMPLATE_DATA
	, END_TEMPLATE_DATA
} from '../../common/v5/constants';
import { withClientMessageTrainingPhrase } from '../../containers/chatbot';
import { SerialTagList } from './Attachments';
import { KBErrandsModal } from '../../views/v5/knowledgebase/knowledgeBase';

class KnowledgeBaseDropdown extends React.PureComponent {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
	}
	toggle = evt => {
		this.props.onToggleShow(!this.props.show);
	}
	render() {
		const p = this.props, opts = p.options;
		let style = {
				width: p.width,
				height: 'auto',
				bottom: 'auto',
				top: p.top
			}, myClass = 'jq-selectbox jqselect',
			o = [], list = [], selectedTitle = "";
		if(p.className) {
			myClass += ' ' + p.className;
		}
		if(!p.show) {
			style.display = 'none';
		} else {
			myClass += ' dropdown opened';
		}
		$.each(opts, (i,v) => {
			o.push(<option key={'o'+i} value={v.value}>{v.name}</option>);
			list.push(
				<SelectList2
					key={'l'+i}
					className={classNames(
						{'sel selected': p.selected === v.id}
						, 'folder-list'
					)}
					icon={v.icon}
					id={v.id}
					name={v.name}
					onClick={p.onSelect}
				/>
			);
		});
		let item = p.textNoItemSelected, myStyle = {};
		if(p.selected === 0 || p.selected === UNSELECT) {
			if(p.textNoItemSelected) {
				item = p.textNoItemSelected;
			} else {
				item = I('Select an item');
			}
		}
		if(p.selected > 0) {
			for(let i = 0; i < opts.length; i++){
				if(opts[i].id === p.selected){
					item = opts[i].name;
					const selectIcon = opts[i].icon;
					if(selectIcon) {
						item += ' ';
						item += <SortIcon icon={selectIcon} />;
					}
				}
			}
		}
		if(p.maxHeight) {
			myStyle.maxHeight = p.maxHeight;
			myStyle.display = 'grid';
		}
		if(p.preSelectedTitle){
			selectedTitle = p.preSelectedTitle;
		}else{
			selectedTitle = item;
		}
		return <div className={myClass} hidden={p.visible ? false : true}>
				<div className="jq-selectbox__select" onClick={this.toggle} data-qa-id={p.id ? p.id : ""}>
					<div className="jq-selectbox__select-text toggle-btn" data-qa-id="kb-select-folder">
						{selectedTitle}
					</div>
					<div className="jq-selectbox__trigger">
						<div className="jq-selectbox__trigger-arrow" data-qa-id={p.id ? p.id+"_arrow" : ""}></div>
					</div>
				</div>
				<div className="jq-selectbox__dropdown" style={style}>
					<ul style={myStyle}>{list}</ul>
				</div>
			</div>;
	}
}

class KbQuestionItem extends Component {
	answerClick() {
		const p = this.props.item;
		let shortcuts = "";
		if(p.keyboardShortcut){
			let keys = {
				keyboardShortcut: p.keyboardShortcut,
				keyboardShortcutUseAlt: p.keyboardShortcutUseAlt,
				keyboardShortcutUseCtrl: p.keyboardShortcutUseCtrl,
				keyboardShortcutUseMeta: p.keyboardShortcutUseMeta,
				keyboardShortcutUseShift: p.keyboardShortcutUseShift
			}
			shortcuts = this.formKeyBoardShortcut(keys);
		}
		this.props.showAnswer(
			p.question,
			p.answer,
			p.attachments,
			p.id,
			shortcuts,
			p
		);
	}
	formKeyBoardShortcut(keys) {
		let mod = [];
		if( keys.keyboardShortcutUseAlt ) {
			mod.push("Alt");
		}
		if( keys.keyboardShortcutUseCtrl ) {
			mod.push("Ctrl");
		}
		if( keys.keyboardShortcutUseMeta ) {
			mod.push("Meta");
		}
		if( keys.keyboardShortcutUseShift ) {
			mod.push("Shift");
		}
		let mods = mod.join(" + ");
		return "("+mods+" + "+keys.keyboardShortcut+")";
	}
	render() {
		const p = this.props;
		let item = p.item;
		return(
			<ul style={p.display}>
				<li className={p.selected ? "current" : ""}>
					<a href="#" style={p.display} data-qa-id={"kb-question-list-item-"+item.name}
						onClick={this.answerClick.bind(this)} className="rootNode">
						{item.name}
					</a>
				</li>
			</ul>
		);
	}
}

class KbDropDownItem extends Component {
	constructor(props){
		super(props);
		this.kbDropdown = this.kbDropdown.bind(this);
		this.state = {
				showItem: false
		};
	}
	componentDidMount() {
		const p = this.props;
		if(p.defaultKb > 0){
			if(p.item.level){
				if(p.item.level === 1){
					this.setState({showItem: true});
				}
			}else{
				this.setState({showItem: false});
			}
		}
	}
	kbDropdown() {
		this.setState({
			showItem: !this.state.showItem
		});
	}
	render() {
		const p = this.props, s = this.state;
		let item = p.item, displayText = item.name;
		let toggleButton ={}, toggleItem ={};
		let showItem = s.showItem;

		if(p.doSearch == true){
			showItem = true
		}
		let showToggleItem={display: showItem?"inline-block":"none"};
		if(item.type == "question"){
			displayText = item.question.replace(/<\/?[^>]+(>|$)/g, "");
		}
		if(item.type !="question"){
			toggleButton=(<div className="toggle-btn"
							data-qa-id={"kb-select-sub-folder"}
							onClick={this.kbDropdown}
							style={{display:"block"}}>
							<i className="icon-folder"></i>
							{displayText}
						</div>);
						// (<i className="toggle-btn icon-folder"
						// data-qa-id={"kb-select-sub-folder"}
						// onClick={this.kbDropdown}
						// style={{display:"block"}}>
						// {displayText}
						// </i>);
			toggleItem=p.subelements;
		}
		return(
			<div className="toggle-item" style={p.display}>
				{toggleButton}
				<div style={showToggleItem}>
				{toggleItem}
				</div>
			</div>
		);
	}
}

class KBTreeItems extends Component {
	recurseSubTree = (item, display) => {
		const p = this.props;
		let subelements = [],
			thisStyle = {display: display};
		if(item){
			if(item.list){
				item.list.forEach(function(subItem){
					let newElement = this.recurseSubTree(subItem, display);
					subelements.push(newElement);
				}.bind(this));
			}
			if(item.type == "question"){
				return (<KbQuestionItem key={item.nodeId} item={item}
					selected={p.selectedAnswer === item.id ? true : false}
					display={thisStyle} showAnswer={p.showAnswer}/>)
			}
			return (<KbDropDownItem key={item.nodeId} defaultKb={p.defaultKb}
				doSearch={p.doSearch}
				id={item.id} item={item}
				display={thisStyle}
				subelements={subelements} />
			);
		}
	}
	render() {
		const p = this.props;
		if(p.dataSrc){
			let parentItem;
			$.each(p.dataSrc, (i,v) => {
				parentItem = v;
				parentItem.level = 1;
			});
			return this.recurseSubTree(parentItem, p.display);
		}
	}
}

class KBModalTree extends Component {
	constructor(props){
		super(props);
		this.handleSelectLibrary = this.handleSelectLibrary.bind(this);
		this.onToggleFolder = this.onToggleFolder.bind(this);
	}
	handleSelectLibrary(id){
		this.props.onSelectLibrary(id);
		this.props.onShowLibraryList(!this.props.knowledgeBase.show)
	}
	onToggleFolder(newState){
		this.props.onShowLibraryList(newState);
	}
	render() {
		const p = this.props;
		let defKbName = I('Choose Knowledge base'),
			element;
		if(p.dataSrc){
			element = <KBTreeItems key={p.dataSrc.id}
				dataSrc={p.dataSrc}
				defaultKb={p.defaultKb}
				doSearch={p.doSearch}
				selectedAnswer={p.selectedAnswer}
				display={p.showList ? "none" : "block"}
				showAnswer={p.showAnswer}/>
		}
		if(p.defaultKb > 0) {
			for(var i=0; i < p.kbList.length; i++){
				if (p.kbList[i].id == p.defaultKb){
					defKbName = p.kbList[i].value;
				}
			}
		}
		return(
			<div className="toggle-tabs">
				<div className="toggle-item" style={{display:"block"}}>
					<div>
						<div className="label-row">
							<KnowledgeBaseDropdown className=''
								top='26px' maxHeight='972px'
								preSelectedTitle={I("Folder")}
								textNoItemSelected={defKbName}
								visible={true}
								onToggleShow={this.onToggleFolder}
								selected={p.selectedLibrary}
								options={p.kbList ? p.kbList : []}
								show={p.showList}
								onSelect={this.handleSelectLibrary}
							/>
						</div>
					</div>
					<div style={{display: "block"}}>
					{element}
					</div>
				</div>
			</div>
		);
	}
}

const SelectTrainPhrase = withClientMessageTrainingPhrase(ClientMessageSelection)

export const ChatbotsTrainPhraseSelection = () => (
	<Body>
		<SelectTrainPhrase />
	</Body>
)

class KBPopup extends Component {
	constructor(props){
		super(props);
		this.closePopup = this.closePopup.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearchOnKeyDown = this.handleSearchOnKeyDown.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.addAnswer = this.addAnswer.bind(this);
		this.showAnswer = this.showAnswer.bind(this);
		this.searchTimeoutHandler = this.searchTimeoutHandler.bind(this);
	}
	closePopup() {
		this.props.onSearchLibrary(false);
		this.props.onResetLibrarySearch(false);
		this.props.handleToggle(false);
	}
	addAnswerBase() {
		const p = this.props;
		if (p.chat) {
			// TODO: it seem that chat errand interface just become cancer to React.
			// Forcing any code related to answer to use anti-pattern wrong React
			// coding.
			const editor = CKEDITOR.instances["chatEditor"];
			if (editor) {
				const currentAns = editor.getData();
				const appended = currentAns + p.ui.shownAnswer;
				editor.setData(appended);
			}
		}
		let answer = p.ui.shownAnswer;
		// for non chat, strip off the template data from the message (if any)
		if (!p.chat) {
			let sidx = answer.indexOf(START_TEMPLATE_DATA)
			let eidx = answer.indexOf(END_TEMPLATE_DATA)
			if (sidx != -1 && eidx != -1 && eidx > sidx) {
				answer = answer.substring(0, sidx) + answer.substring(eidx+END_TEMPLATE_DATA.length, answer.length);
			}
		}
		p.handleAppendKnowledgeBase(
			answer,
			"",
			p.ui.shownAttachments,
			p.currentReply,
			p.chat,
			p.ui.shownId
		);
		p.onSearchLibrary(false);
		p.onResetLibrarySearch(true);
	}
	addAnswer() {
		new Promise(resolve => {
			const {
				chat,
				noForBotQuestion,
				ui: { questionNode: { chatbots, forBot }, shownId }
			} = this.props;
			const hasBot = noForBotQuestion ? chatbots && chatbots.length : forBot;
			if (!chat || !hasBot || !shownId) {
				resolve();
				return;
			}
			this.props.onRequestTrainChatbots(shownId, chatbots).catch(() => {
				if (process.env.NODE_ENV !== 'production') {
					console.log('dbg: no for training chatbot')
				}
			}).then(() => resolve());
		}).then(() => {
			this.addAnswerBase();
		});
	}
	showAnswer(questionText, answerText, attachments, id, shortcuts, item) {
		let p = {
			questionText,
			answerText,
			attachments,
			id,
			shortcuts
		}
		this.props.onShowAnswer(p, item);
	}
	searchTimeoutHandler(){
		const p = this.props;
		var params = {};

		if(this.props.ui.doSearch == true){
			params.search = p.ui.searchText;
			params.source = p.searchSource;
			let searchLibrary = p.defaultLibrary
			if(p.ui.selectedLibrary > 0) {
				searchLibrary = p.ui.selectedLibrary;
			} else if(p.kbList.length > 0 && searchLibrary == ""){
				searchLibrary = p.kbList[0].id;
				// When no library selected at first search, it searches on the first library
				// We update first library id into selected library, so that it won't refer to first loaded KB list
				p.onUpdateSelectedLibrary(searchLibrary);
			}
			p.getKnowledgeBase(searchLibrary, params);
			if(p.ui.searchText==""){
				p.onSearchLibrary(false);
			}
		}
	}
	handleSearchChange(event){
		const p = this.props;

		this.resetTimeout();

		if (event.target.value.length >= 1) {
			if(p.ui.searchText != event.target.value ){
				p.onSearchLibrary(true);
				p.onSetSearchText(event.target.value);
			}
		} else if (event.target.value.length == 0) {
			if(p.ui.searchText != ""){
				p.onSearchLibrary(true);
				p.onSetSearchText("")
			}
			this.showAnswer("", "", null, 0, "")
		}
		// TODO: KB-MESS: this is wrong code. NEVER mutate props.
		p.ui.searchTimeoutId = window.setTimeout(this.searchTimeoutHandler, p.ui.searchTimeoutDelay);
	}
	handleSearchOnKeyDown(event){
		// keyCode 13 = 'enter', 27 = 'Escape' key, 38 = 'Up' key, 40 = 'down' key
		switch (event.keyCode) {
			case 13:
				this.showAnswer("", "", null, 0, "")
				this.handleSearchChange(event);
				if (event.shiftKey)
					this.addAnswer();
				break;
			case 27:
				event.target.value = '';
				this.handleSearchChange(event);
				// TODO: KB-MESS: do not use jQuery like this
				$(".infoBaseHighlightNode").removeClass("infoBaseHighlightNode");
				break;
			case 38:
				this.highlightPrevNode("up");
				break;
			case 40:
				this.highlightPrevNode("down");
				break;
		}
	}
	handleClick(event){
		const p = this.props;

		this.resetTimeout();
		// TODO: KB-MESS: do not use jQuery like this
		$(".search-field").val('');

		if(p.ui.searchText != ""){
			p.onSearchLibrary(true);
			p.onSetSearchText("")
		}

		this.showAnswer("", "", null, 0, "")
		// TODO: this is wrong code. NEVER mutate props.
		p.ui.searchTimeoutId = window.setTimeout(this.searchTimeoutHandler, p.ui.searchTimeoutDelay);
		// TODO: KB-MESS: do not use jQuery like this
		$(".infoBaseHighlightNode").removeClass("infoBaseHighlightNode");
		$(".search-field").focus();
	}
	resetTimeout(){
		const p = this.props;

		if (p.ui.searchTimeoutId) {
			window.clearTimeout(p.ui.searchTimeoutId);
			p.onClearSearchTimeout();
		}
	}
	highlightPrevNode(direction) {
		let strHighlightNodeClass = "infoBaseHighlightNode";
		// TODO: KB-MESS: do not use jQuery like this
		let rootNodes = $(".rootNode");

		if (rootNodes.length > 0) {
			let intRootNodeFoundIndex = -1;
			let intSelectedIndex = -1;

			function highlightAction(node, index) {
				if (node.closest("div").length > 0 && node.closest("div")[0].style.display == "inline-block") {
					// TODO: KB-MESS: do not use jQuery like this
					node.addClass(strHighlightNodeClass);
					intSelectedIndex = index;
					return true;
				}
			}

			function searchTree(element){
				if(element.list == null){
					intRootNodeFoundIndex += 1;
					if (intRootNodeFoundIndex == intSelectedIndex){
						return element;
					}

				}else if (element.list != null){
					let intCount;
					let result = null;
					for(intCount=0; result == null && intCount< element.list.length; intCount++){
						 result = searchTree(element.list[intCount], intSelectedIndex);
					}
					return result;
				}
			}
			// TODO: KB-MESS: do not use jQuery like this
			if ($("." + strHighlightNodeClass).length > 0) {
				//Check if there is another link under, if no, go to top
				rootNodes.each(function (index, node) {
					// TODO: KB-MESS: do not use jQuery like this
					if ($(node).hasClass(strHighlightNodeClass)) {
						// TODO: KB-MESS: do not use jQuery like this
						$(node).removeClass(strHighlightNodeClass);

						let intNextIndex = 0;
						let intNodeMaxIndex = rootNodes.length - 1;
						if (direction == "up") {
							if (index == 0) {
								intNextIndex = intNodeMaxIndex;
							} else {
								intNextIndex = index - 1;
							}

						} else {
							if (index == intNodeMaxIndex) {
								intNextIndex = 0;
							} else {
								intNextIndex = index + 1;
							}
						}

						if (highlightAction($(rootNodes[intNextIndex]), intNextIndex))
							return false;
					}
				});
			}
			// TODO: KB-MESS: do not use jQuery like this
			if ($("." + strHighlightNodeClass).length == 0) {
				rootNodes.each(function (index, node) {
					if (highlightAction($(node), index))
						return false;
				});
			}
			// TODO: KB-MESS: do not use jQuery like this
			// show answer if there is a highlighted node
			if ($("." + strHighlightNodeClass).length > 0) {
				const p = this.props;
				let node = null;
				if (p.dataSrc) {
					for (let intCount=0; node == null && intCount< p.dataSrc.length; intCount++) {
						node = searchTree(p.dataSrc[intCount], intSelectedIndex);
					}
				}
				if (node != null) {
					this.showAnswer(
						node.question,
						node.answer,
						node.attachments,
						node.id,
						"",
						node
					);
				}
			}
		}
	}
	voteForFeaturedQuestion = () => {
		this.props.onUpdateFeaturedQuestion({id: this.props.ui.shownId});
	}
	handleChangeComment = (e) => {
		this.props.onChangeComment(e.target.value);
	}
	handleChangeVote = (vote) => {
		const { userVote, onChangeVote } = this.props;
		if (userVote.vote === USER_VOTE_UP && vote === USER_VOTE_UP) {
			return onChangeVote(0);
		} else if (userVote.vote === USER_VOTE_DOWN && vote === USER_VOTE_DOWN) {
			return onChangeVote(0);
		} else {
			return onChangeVote(vote);
		}
	}
	handleUserVoteActions = (actions) => {
		const {
			onEditUserVote
			, onUpdateUserVote
			, onUpdateUserVoteWarning
			, ui
			, userVote
		} = this.props;
		if(userVote.vote === 0 || userVote.vote === null) {
			onUpdateUserVoteWarning(!userVote.warning)
		} else if (userVote.vote != 0 && userVote.warning) {
			onUpdateUserVoteWarning(!userVote.warning)
		}
		let params = {
			comment: null
			, id: ui.shownId
			, vote: 0
		}
		if (actions === 'remove') {
			onUpdateUserVote(params)
		} else if (actions === 'save') {
			if (userVote.vote === null ||
				userVote.vote === undefined ||
				userVote.vote === 0) {
				return;
			}
			params = {
				comment: userVote.comment
				, id: ui.shownId
				, vote: userVote.vote
			}
			onUpdateUserVote(params)
		} else {
			onEditUserVote(!userVote.editable)
		}
	}
	render() {
		const p = this.props, attachments = p.ui.shownAttachments;
		let hasAttachemnt = "", disableAdd = true;
		let kbModalTree = <KBModalTree dataSrc={p.dataSrc}
			showList={p.ui.show}
			doSearch={p.ui.doSearch}
			onSetSearch={p.onSearchLibrary}
			kbList={p.kbList}
			knowledgeBase = {p.ui}
			onShowLibraryList = {p.onShowLibraryList}
			selectedLibrary = {p.ui.selectedLibrary}
			onSelectLibrary = {p.onSelectLibrary}
			defaultKb={p.defaultLibrary}
			selectedAnswer={p.ui.shownId}
			showAnswer={this.showAnswer}/>
		let topSuggestions = (
			<div style={{display:"none"}}>
				<div className="menu-title">{I('Top suggestions')}</div>
				<div className="popup-menu">
					<ul>
						<li>
							<a href="#">Saw opening hours</a>
						</li>
						<li>
							<a href="#">Saw opening hours</a>
						</li>
						<li>
							<a href="#">Saw opening hours</a>
						</li>
					</ul>
				</div>
			</div>
		);
		if(p.ui.shownQuestion || p.ui.shownAnswer){
			disableAdd = false;
		}
		if(attachments && attachments.length > 0
			&& p.ui.shownId > 0){
				let lists = {
					[AT_LIBRARY]: {
						list: attachments
					}
				}
				hasAttachemnt = (
					<div className="knowledge-base-attachments">
						<div className="attachment-header">
							<SerialTagList value={lists}
								showDelete={false} />
						</div>
					</div>
				);
		}
		let showQuestionContent = true;
		if (p.ui.shownQuestion.length > 0 || p.ui.shownShortcut.length > 0 || p.ui.shownAnswer) {
			showQuestionContent = false;
		}
		let featuredQuestionClass = "fa fa-thumbs-o-up clickable";
		if (p.ui.featuredQuestion) {
			featuredQuestionClass = "fa fa-thumbs-up clickable featured";
		}
		let rateUpIcon = "far fa-thumbs-up clickable "
			, rateDownIcon = "far fa-thumbs-down clickable"
			, inputClassName = "input-comment";
		if (p.userVote.vote === USER_VOTE_UP ) {
			rateUpIcon += " featured"
		} else if (p.userVote.vote === USER_VOTE_DOWN) {
			rateDownIcon += " featured"
		}
		if (!p.userVote.editable) {
			inputClassName += " disabled";
			rateDownIcon += " disabled";
			rateUpIcon += " disabled";
		}
		let style = {
			cursor: 'pointer',
			fontWeight: 'bold',
			position: 'absolute',
			top: '9px',
			right: '-15px',
			fontSize: '9px'
		}
		return (
			<PopupPage
				data-qa-id="knowledge-base-backdrop-popup"
				extraClass="knowledge-base-backdrop"
				innerClass="knowledge-base"
				show={p.ui.showPopup}
				onClose={this.closePopup}
			>
					<div className="knowledge-base-sidebar">
						<div className="popup-title">
							<h2>{I("Knowledge base")}</h2>
						</div>
						{topSuggestions}
						{kbModalTree}
					</div>
					<div className="knowledge-base-content">
						<div className="knowledge-base-search">
							<button type="submit">
								<i className="icon-search">
								</i>
							</button>
							<input className="search-field" type="text" data-qa-id="kb-search-library"
								onKeyDown={this.handleSearchOnKeyDown}
								placeholder={I("Search knowledge base")}/>
								<i className="icon-times" style={style} onClick={this.handleClick}></i>
						</div>
						<div className="knowledge-base-content-body" hidden={showQuestionContent}>
							<h3 dangerouslySetInnerHTML={{__html:p.ui.shownQuestion+p.ui.shownShortcut}}/>
							<p dangerouslySetInnerHTML={{__html:p.ui.shownAnswer}}/>
						</div>
						{hasAttachemnt}
							<div className={"knowledge-base-featured-question"} hidden={showQuestionContent}>
							{I('Is it helpful?')}
							<i
								className={rateUpIcon}
								onClick={() => p.userVote.editable && this.handleChangeVote(USER_VOTE_UP)}
								style={{marginLeft: '2px', marginRight: '2px'}}
							/>
							<i
								className={rateDownIcon}
								onClick={() => p.userVote.editable && this.handleChangeVote(USER_VOTE_DOWN)}
								style={{marginLeft: '2px', marginRight: '2px'}}
							/>
							<input
								className={inputClassName}
								disabled={!p.userVote.editable}
								onChange={this.handleChangeComment}
								placeholder={I("You can leave a comment here...")}
								style={{marginLeft: '2px', marginRight: '2px'}}
								type="text"
								value={this.props.userVote.comment}
							/>
						</div>
						<i
							className={"far fa-save user-vote-actions"}
							hidden={showQuestionContent}
							onClick={() => this.handleUserVoteActions("save")}
						/>
						<i
							className={"fas fa-edit user-vote-actions"}
							hidden={showQuestionContent}
							onClick={() => this.handleUserVoteActions("edit")}
						/>
						<i
							className={"far fa-trash-alt user-vote-actions"}
							hidden={showQuestionContent}
							onClick={() => this.handleUserVoteActions("remove")}
						/>
						<span className={"text-danger"} hidden={!p.userVote.warning}>{I('Please grade the answer')}</span>
						<div className="button-row">
							<button type="button" disabled={disableAdd} data-qa-id="kb-add-answer" onClick={this.addAnswer} className="btn-blue">{I("Add")}</button>
						</div>
					</div>
					<ChatbotsTrainPhraseSelection />
			</PopupPage>
		);
	}
}

export const KBPopupBase = ({...props}) => {
	if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui") && cflag.IsActive("2024-05-23.CEN-2090.new-errand-knowledgebase-gui")) {
		return <KBErrandsModal
			innerClass="kb-manual-errands-modal"
			show={props.expandKnowledgeBasePanel}
			onMinimize={props.onMinimizeKnowledgePopup}
			onClose={props.onCloseKnowledgeBasePopup}
			{...props}
		/>
	} else {
		return <KBPopup {...props} />;
	}
}

export class CtrlKnowledgeBasePopup extends PureComponent {
	constructor(props){
		super(props);
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		if (this.props.onToggle) {
			this.props.onToggle(this.props.open);
		}
	}
	render() {
		let p=this.props;
		return(
				<div className="knowledge-base-link">
					<a className="blue" onClick={this.toggle} data-qa-id={"QA_knowledgeBasePopup"}>{L(p.title)}</a>
				</div>
		);
	}
}