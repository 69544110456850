import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { saveTemplateAsFile } from '../../common/v5/helpers';
import Drawflow  from 'drawflow';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CustomButton } from '../../reactcomponents/Admin';

//TODO: Enhancements
//Import external json file (Same JSON format as exported)
//Export to pdf (Diagram)

const NodeBox = () => {
	return (
		<div>
      		<input type="text" df-name="" readOnly/>
    	</div>
	)
}

let editor;

//Still has bugs for this which SVG (lines) not visible after
//exported. Hence, hide this.
const ExportPDF = (ivrName) => {
	const input = document.getElementsByClassName('drawflow')[0];
	input.style.height = input.scrollHeight + 'px';
	html2canvas(input)
		.then((canvas) => {
			var imgData = canvas.toDataURL('image/png');
			var imgWidth = 210;
			var pageHeight = 295;
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;

			var doc = new jsPDF('p', 'mm');
			var position = 0;

			doc.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
			}
			doc.save(`${ivrName}.pdf`);
		})
}

export function FlowDiagram({name, data, onDelete, onClose}) {
	let els = [];
	const ref = useRef();

	function exportData() {
		//export to JSON, which can be imported
		const exportdata = editor.export();
		const fileName = `${name}-ivr-export.json`;
		saveTemplateAsFile(fileName, exportdata);
	}

	function exportToPDF() {
		ExportPDF(name);
	}

	useEffect(() => {
		//initiate drawflow
		const drawflowWrapper = ref.current;

		if(drawflowWrapper) {
			editor = new Drawflow(drawflowWrapper);
			//editor.editor_mode = 'edit'; // Default

			editor.contextmenu = function(e) { e.preventDefault() }
			editor.start();

			//editor.addNode(name, inputs, outputs, posx, posy, class, data, html);
			//editor.addNode('NodeBox', 1, 1, 100, 150, "NodeBox", { name: '' }, 'NodeBox');
			//editor.addNode('NodeBox', 1, 1, 300, 200, "NodeBox", { name: '' }, 'NodeBox');

			editor.import(data);

			/* const nodes = data.drawflow.Home.data;
			setTimeout(() => {
				for (let key in nodes) {
					const node = nodes[key];
					//editor.updateConnectionNodes(`node-${node.id}`)
					//console.log(`update node connection => node-${node.id}`);
					console.log("Node -> ", node);

					let inputLen = Object.keys(node.inputs).length;
					let outputLen = Object.keys(node.outputs).length;

					editor.addNode(node.name, inputLen, outputLen, node.pos_x, node.pos_y, `NodeBox-${key}`, { name: node.html }, node.html);

					if(node.outputs) {
						//output_1 -> connections
						//output_2 -> connections
						for (let outputKey in node.outputs) {
							let output = node.outputs[outputKey];
							console.log("output key ", outputKey);

							if(output.connections && output.connections.length > 0) {
								output.connections.map((info, i) => {
									console.log("output info -> ", info);
									//console.log("editor.addConnection", editor.addConnection)
									editor.addConnection(node.id, info.node, outputKey, info.output);
								})
							}
						}
					}
				}
			}, 500) */
			
			const domContainer = document.querySelectorAll('.NodeBox .drawflow_content_node');
			domContainer.forEach((element, i) => {
				els.push(ReactDOM.render(
					<NodeBox></NodeBox>,
					element
				));
			});
		}
	}, [data]);

	return (
		<React.Fragment>
			<div ref={ref} className='drawflow-wrapper'>
				{els}
			</div>
			<div className="button-row" style={{marginTop: '10px'}}>
				<div style={{float: 'left'}}>
					<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={onDelete} text={I("Remove")} />
				</div>
				<div style={{float: 'right', display: 'flex'}}>
					<button hidden type="button" onClick={exportToPDF} data-qa-id="ivr-preview-btn-pdf" className="btn-blue">{I("Export to PDF")}</button>
					<button type="button" onClick={exportData} data-qa-id="ivr-preview-btn-pdf" className="btn-blue">{I("Export")}</button>
					<button type="button" onClick={onClose} data-qa-id="ivr-preview--btn-cancel" className="btn-grey">{I("Cancel")}</button>
				</div>
			</div>
		</React.Fragment>
	)
}
