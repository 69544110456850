import React from 'react';
import WorkflowTable from './WorkflowTable';
import WorkflowActions from './WorkflowActions';
import {sanitizeHtml,truncateHtml} from '../../common/v5/helpers.js';
import {I} from '../../common/v5/config.js';
import {
	WorkflowInnerContainerCtnr
} from '../../views/v5/workflowCtnr';

class ErrandListView extends React.Component {
	constructor(props) {
		super(props);
		this.onActiveSubject = this.onActiveSubject.bind(this);
		this.state = {
			activePreview : ""
			, activeSubject : {}
		}
		this.setErrandPreview = this.setErrandPreview.bind(this);
	}
	getErrandPreview(id){
		this.setState({activePreview:""});
	}
	setErrandPreview(){
		//let prevBody = sanitizeHtml(truncateHtml(this.props.previewData,1000));
		//showing full body content and see if it's affect the load performance and ui
		let prevBody = sanitizeHtml(this.props.previewData);
		return prevBody;
	};
	onActiveSubject(toggle, id) {
		if (toggle) {
			this.setState({
				activeSubject: { show: toggle, id: id }
			});
		} else {
			this.setState({
				activeSubject: { show: toggle, id: id }
			});
		};
	}
	render() {
		const {
				allowAgentForwardToAgent
				, allowForwardErrandsToAgent
				, forwardErrandsAgents
				, hasPendingReviewErrand
				, isLoadingFwdErrandsAgents
				, onOpenForwardToAgent
				, onSelectForwardToAgent
				, ...p
			} = this.props
			;
		let hideActions = !p.showMultipleActions;
		let listViewClass = "errand-list-view full-width"
			, errandText = "errand"
			;
		if(p.totalSelected > 0){
			if(p.totalSelected > 1) {
				errandText = "errands";
			}
		} else {
			hideActions = true;
		}
		if(p.showMultipleActions){
			listViewClass = "errand-list-view";
		}
		let selectedString =  I("{TOTAL_SELECTED} {ERRAND_TEXT} selected.")
		.replace('{TOTAL_SELECTED}',p.totalSelected)
		.replace('{ERRAND_TEXT}', errandText);

		return (
			<WorkflowInnerContainerCtnr className="app-inner-content" hidden={p.hidden}>
				<div className="errand-box mb-3">
					<div className={listViewClass}>
						<WorkflowTable
							list={p.list}
							listReady={p.listReady}
							pageSize={p.pageSize}
							currentOffset={p.currentOffset}
							totalList={p.totalList}
							onClickRow={p.onClickErrand}
							onSelectRow={p.onSelectErrand}
							onSelectAll={p.onSelectAllErrand}
							selectAll={p.selectAll}
							onPageChange={p.onPageChange}
							onSortList={p.onSortList}
							previewActivated={p.previewActivated}
							onLoadPreview={p.onLoadPreview}
							previewData={this.setErrandPreview()}
							showOrganizationColumnFeature={p.showOrganizationColumnFeature}
							dueDateFeature={p.dueDateFeature}
							postponeDateFeature={p.postponeDateFeature}
							mobile={p.mobile}
							onGetCollabInfo={p.onGetCollaborationInfo}
							collaborationInfo={p.collaborationInfo}
							canOffCollaborationLight={p.canOffCollaborationLight}
							onToggleLight={p.onToggleLight}
							onActiveSubject={this.onActiveSubject}
							activeSubject={this.state.activeSubject}
							onGetSlaTime={p.onGetSlaTime}
							slaTime={p.slaTime}
							showSlaTimeFeature={p.showSlaTimeFeature}
							activeCreatedErrand={p.activeCreatedErrand}
							sipGetCurrentEid={p.sipGetCurrentEid}
							sipMakeCallCurrentErrand={p.sipMakeCallCurrentErrand}
							canShowPreview={!this.props.hidden}
						/>
					</div>
					{!this.props.hidden && <WorkflowActions
						allowAgentForwardToAgent={allowAgentForwardToAgent}
						allowForwardErrandsToAgent={allowForwardErrandsToAgent}
						forwardErrandsAgents={forwardErrandsAgents}
						hidden={hideActions}
						isLoadingFwdErrandsAgents={isLoadingFwdErrandsAgents}
						currentContext={p.currentContext}
						currentOpened={p.currentOpened}
						onOpenForwardToAgent={onOpenForwardToAgent}
						onSelectForwardToAgent={onSelectForwardToAgent}
						totalSelected={p.totalSelected}
						selectedErrands={p.selectedErrands}
						onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
						onHandleSetPriority={p.onHandleSetPriority}
						onHandlePrintErrands={p.onHandlePrintErrands}
						shouldPopPrint={p.shouldPopPrint}
						printContent={p.printContent}
						onHandleClose={p.onHandleClose}
						onHandleDelete={p.onHandleDelete}
						userFolders={p.userFolders}
						showMoveToFolder={p.showMoveToFolder}
						onHandleMoveToFolder={p.onHandleMoveToFolder}
						showForwardAreaOptions={p.showForwardAreaOptions}
						forwardToAreas={p.forwardToAreas}
						onHandleForwardToArea={p.onHandleForwardToArea}
						onHandleSetDueDate={p.onHandleSetDueDate}
						onHandleReturnToInbox={p.onHandleReturnToInbox}
						onHandleSendingBulkErrand={p.onHandleSendingBulkErrand}
						onHandleLinkErrand={p.onHandleLinkErrand}
						agentCanPutBackErrands={p.agentCanPutBackErrands}
						agentCanMoveFromInboxFeature={p.agentCanMoveFromInboxFeature}
						agentCanForwardToAreaFeature={p.agentCanForwardToAreaFeature}
						agentCanForwardToAreaInOrganizationFeature={p.agentCanForwardToAreaInOrganizationFeature}
						agentCanDeleteErrandsFeature={p.agentCanDeleteErrandsFeature}
						showHighPriorityFeature={p.showHighPriorityFeature}
						hasChatErrand={p.chatErrandSelected}
						allowDueDate={this.props.allowDueDate}
						dueDateFeature={p.dueDateFeature}
						hasClosedErrand={false}
						allowCloseErrand={p.allowCloseErrand}
						hasPendingReviewErrand={hasPendingReviewErrand}
						defaultFolderId={p.defaultFolderId}
						selectedAgent={p.selectedAgent}
						areas={p.areas}
						handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
						agentWorking={p.agentWorking}
					/>}
				</div>
			</WorkflowInnerContainerCtnr>
		);
	}
}

export default ErrandListView;
