import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { positiveNumber
	, isTelavoxEnabled
} from '../../common/v5/helpers.js';
import Reload from '../../reactcomponents/Reload';
import { GroupableBriefErrand, BriefSearchErrand } from './BriefErrand';
import ToggleSideBar from './ToggleSideBar';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import Scrollable from './Scrollable';
import {
	TopWorkflowFilterCtnr,
	WorkflowBreadcrumbsCtnr,
	WorkflowInnerContainerCtnr
} from '../../views/v5/workflowCtnr';
import ListSortByFilters from '../../reactcomponents/ListSortByFilters';
import ErrandListView from './ErrandListView';
import {I} from '../../common/v5/config.js';
import { ChatErrandListCtnr } from '../../containers/chat';
import {
	PREF_LIST_VIEW,
	PREF_CONVERSATION_VIEW,
	ICON_LIST_VIEW,
	ICON_CONVERSATION_VIEW,
	CTX_MY,
	CTX_NEW,
	CTX_POSTPONED,
	CTX_VIP,
	CTX_REVIEW
} from '../../common/v5/constants';
import { log } from 'util';
import { IsContextSearch } from '../../common/v5/utils';
import classNames from 'classnames';
import {postOneBasicErrand} from '../../redux/actions/async/ajax.js';
import BriefErrand from '../../components/v5/BriefErrand.js';
import AgentSocket from '../../common/v5/agentsocket.js';


class ErrandListHeader extends React.Component {
	constructor(props) {
		super(props);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleShowSelectSort = this.handleShowSelectSort.bind(this);
		this.handleToggleSideBar = this.handleToggleSideBar.bind(this);
		this.handleSwitchToListView = this.handleSwitchToListView.bind(this);
		this.handleRefreshList = this.handleRefreshList.bind(this);
	}
	handleSelectAll(v) {
		this.props.onSelect(v);
	}
	handleShowSelectSort(e) {
		if(typeof e === 'boolean') {
			this.props.onToggleSort(e);
		} else {
			this.props.onToggleSort(!this.props.showSortSelection);
		}
	}
	handleToggleSideBar(v) {
		const p = this.props;
		p.onToggleSideBar(v);
	}
	handleSwitchToListView() {
		let listView = PREF_CONVERSATION_VIEW;
		if(this.props.errandListType === PREF_LIST_VIEW){
			listView = PREF_CONVERSATION_VIEW;
		}else{
			listView = PREF_LIST_VIEW;
		}
		this.props.onSetErrandView(listView);
	}
	handleRefreshList() {
		this.props.onRefreshList();
	}
	render() {
		const { loading, mobileMode, onSelectSort, ...p } = this.props;
		let iconListClass = ICON_LIST_VIEW, listViewTitle = I("Switch to list view");
		if(p.showListView == PREF_LIST_VIEW) {
			iconListClass = ICON_CONVERSATION_VIEW;
			listViewTitle = I("Switch to conversation view");
		}
		let customStyledAppHeader = { borderRadius: "initial"}
		let isVisible = (!p.showSideBar || p.collapseSideBar) ||
				!(p.isExternal && isTelavoxEnabled());
		return <div className={classNames("app-header", {"mobile": mobileMode})} style={customStyledAppHeader}>
				<ul className="conversations-nav">
					<ToggleSideBar visible={isVisible} showSideBar={p.showSideBar} collapsed={p.collapseSideBar} id={"QA_sidebar_toggle_top"}
						onClick={this.handleToggleSideBar} wrapped={true} />
					<li>
						<Reload
							data-qa-id="QA_reload_list"
							loading={loading}
							onClick={this.handleRefreshList}
						/>
					</li>
					{/* {this.props.errandListType === PREF_LIST_VIEW && (
						<li>
							<SquareCheckbox
								className="big"
								data-qa-id="select-all-errand-list"
								label={I('Select all')}
								checked={p.selectAll}
								onClick={this.handleSelectAll}
							/>
						</li>
					)} */}
					{!IsContextSearch(this.props.currentContext) && <li>
						<a className="c3-switch-view" title={listViewTitle} data-qa-id={"QA_change_view"} onClick={this.handleSwitchToListView}>
						<i className={iconListClass}></i></a>
					</li>}
					{
						(!IsContextSearch(this.props.currentContext) && p.collapseSideBar) &&
							<TopWorkflowFilterCtnr />
					}
				</ul>
					{
						(this.props.errandListType === PREF_LIST_VIEW && !IsContextSearch(this.props.currentContext) && p.collapseSideBar) &&
							<WorkflowBreadcrumbsCtnr />
					}
					<div className="conversation-sort-wrapper">
						<ListSortByFilters
							data-qa-id="QA_list_sort"
							listCurrentSorting={p.listCurrentSorting}
							listCurrentSortName={p.listCurrentSortName}
							listSortDirection={p.listSortDirection}
							selectedSort={p.selectedSort}
							showSortSelection= {p.showSortSelection}
							onSelectSort={onSelectSort}
							onToggleSort={this.handleShowSelectSort}
						/>
					</div>
			</div>;
	}
}

const OneNonChatErrand = ({ onClick, ...props }) => {
	const handleClick = useCallback(
		(id, cipherKey) => onClick(id, undefined, cipherKey)
		, [onClick]
	);
	return <GroupableBriefErrand {...props} onClick={handleClick} />;
}

class ErrandList extends React.Component {
	constructor(props) {
		super(props);
		this.handleLoadMore = this.handleLoadMore.bind(this);
		this.onHandlePaginate = this.onHandlePaginate.bind(this);
	}
	componentDidMount(){
		AgentSocket.SetEventListener('queue-errand-event', (message) => {
			if(this.props.onFetchQueueErrands) {
				this.props.onFetchQueueErrands(message);
			}
		});}
	componentDidUpdate(){
		if(this.props.type == PREF_CONVERSATION_VIEW && this.props.listReady) {
			let hasScroll = this.hasScroll(), hasMore = this.hasMore();
			if(!errandViewOnly && !hasScroll && hasMore){
				this.handleLoadMore();
			}
		}
	}
	hasScroll(){
		var div = this.scrollableList;
		if(div) {
			var hasScroll = div.scrollHeight > div.clientHeight;
			return hasScroll;
		}
	}
	handleScroll = (atBottom) => {
		if(this.props.type !== PREF_LIST_VIEW){
			if(atBottom) {
				this.handleLoadMore();
			}
		}
	}
	hasMore() {
		if(this.props.currentSize > this.props.listTotalCount){
			document.removeEventListener('scroll', this.handleScroll);
			return false;
		}else{
			if(this.props.listTotalCount != 0) {
				return true;
			}else{
				return false;
			}
		}
	}
	handleLoadMore(){
		let hasMore = this.hasMore();
		if(hasMore) {
			this.props.onLoadMore();
		}
	}
	onChatGetCollaborationInfo() {
		console.log("TODO onChatGetCollaborationInfo");
	}
	onHandlePaginate(data){
		let currentSize = this.props.searchOffset;
		let selected = data.selected;
		let offset = Math.ceil(selected * currentSize);
		this.props.onSearchListPaginate(offset);
	}
	render() {
		const {
				allowAgentForwardToAgent
				, allowForwardErrandsToAgent
				, forwardErrandsAgents
				, hasPendingReviewErrand
				, isLoadingFwdErrandsAgents
				, onOpenForwardToAgent
				, allowDueDate
				, onSelectForwardToAgent
				, errandCount
				, vipCount
				, ...p
			} = this.props
			, d = p.data.data
			;
		let list = [], listClassName="app-conversations", hideList = true, hideConversation = false;
		let errandsLeft = 0, errandsLoaded = 0, chatsLoaded = 0, hasChat = false, showConvPreview = false;
		let chatErrandList;
		let sumCurrentLoaded = 0; //for sum of loaded chats & errand
		let listViewData = [];
		if (p.type === PREF_CONVERSATION_VIEW) {
			showConvPreview = true;
		}
		if (p.errandListChat && p.errandListChat.length > 0) {
			hasChat = true;
			$.each(p.errandListChat, (i,chat) => {
				listViewData.push({
					data: chat.errand,
					chat: chat,
					selected: chat.ui.selected,
					setMessagesSeen: () => {
						p.setMessagesSeen(chat); // Only handles setting messages as seen
					},
					onClick: p.onClick
				});
			});
			chatErrandList = <ChatErrandListCtnr
				onSelectOne={p.onSelectOne}
				data={p.data.data}
				currentOpened={p.currentOpened}
				collaborationInfo={p.collaborationInfo}
				errandListChat={p.errandListChat}
				onClick={p.onClick}
				clientAvatar={p.clientAvatar}
				mobile={p.mobileMode}
				canShowPreview={showConvPreview}
				/>;
			chatsLoaded = p.errandListChat.length;
		}
		if(!IsContextSearch(p.currentContext)){
			if(d && d.order && d.order.length && (!p.isExternal ||
				isTelavoxEnabled())) {
				$.each(d.order, (i, v) => {
					const data = d.norm[v]
						, selected = d.opr[v] ? d.opr[v].selected : false
						;
					if (!p.listReady && p.contextChanged) {
						listViewData = [];
					} else {
						if (p.listReady || !p.contextChanged) {
							listViewData.push({
								data
								, selected
							});
						}
					}
					list.push(<OneNonChatErrand
						errand={data}
						key={v}
						index={i}
						canShowPreview={showConvPreview}
						me={v}
						handleId={positiveNumber(data.groupWith)}
						onClick={p.onClick}
						tzOffset={p.tzOffset}
						onSelect={p.onSelectOne}
						select={selected}
						onGetCollaborationInfo={p.onGetCollaborationInfo}
						collaborationInfo={p.collaborationInfo}
						canOffCollaborationLight={p.canOffCollaborationLight}
						onToggleLight={p.onToggleLight}
						clientAvatar={p.clientAvatar}
						currentOpened={p.currentOpened}
						dueDateFeature={p.dueDateFeature}
						postponeDateFeature={p.postponeDateFeature}
						showExactDayAndTime={p.showExactDayAndTime}
						onLoadPreview={p.onLoadPreview}
						previewData={p.previewData}
						previewActivated={p.previewActivated}
						mobile={p.mobileMode}
						onGetSlaTime={this.props.onGetSlaTime}
						slaTime={this.props.slaTime}
						showSlaTimeFeature={this.props.showSlaTimeFeature}
						activeCreatedErrand={p.activeCreatedErrand}
						sipGetCurrentEid={p.sipGetCurrentEid}
						sipMakeCallCurrentErrand={p.sipMakeCallCurrentErrand}
					/>);
				});
				errandsLoaded = list.length;
				sumCurrentLoaded = errandsLoaded + chatsLoaded;
				errandsLeft = p.listTotalCount-sumCurrentLoaded;
				if(p.currentContext == CTX_POSTPONED){
					if(errandCount && errandCount.postpone) {
						errandsLeft = errandCount.postpone.total - sumCurrentLoaded;
					}
				} else if(p.currentContext == CTX_VIP) {
					if(vipCount) {
						errandsLeft = vipCount - sumCurrentLoaded;
					}
				} else if(p.currentContext == CTX_REVIEW) {
					if(errandCount && errandCount.review) {
						errandsLeft = errandCount.review.total - sumCurrentLoaded;
					}
				}
				if(errandsLeft < 0) {
					errandsLeft = 0;
				}
			}
		}else{
			let sd = p.searchData;
			if(sd && sd.length) {
				$.each(sd, (i,v) => {
					let lst = v;
					lst.collaboration = {status: false};
					lst.fromName = v.from;
					lst.serviceName = v.channel;
					lst.areaName = v.area;
					lst.date = v.createdTime;
					listViewData.push({
						data: {data: lst},
						selected: false
					});

					let noSelect = false
					, selected = p.searchResults.opr[v.id] ? p.searchResults.opr[v.id].selected : false;

					list.push(<BriefSearchErrand errand={v} key={i} index={i} me={v.id}
							context={p.currentContext}
							onClick={p.onClick} tzOffset={p.tzOffset}
							onSelect={p.onSelectOne} noSelect={noSelect} select={selected}
							onGetCollaborationInfo={p.onGetCollaborationInfo}
							collaborationInfo={p.collaborationInfo}
							canOffCollaborationLight={p.canOffCollaborationLight}
							onToggleLight={p.onToggleLight} clientAvatar={p.clientAvatar}
							currentOpened={p.currentOpened}
							onLoadPreview={p.onLoadPreview}
							previewActivated={p.previewActivated}
							previewData={p.previewData}
							showExactDayAndTime={p.showExactDayAndTime} />
						);
				});
			}
		}
		let errandsLeftText = <div style={{textAlign: 'center'}}><i className="fa fa-spinner fa-spin"></i></div>;
		if(p.listReady) {
			errandsLeftText = I("{ERRAND_LEFT} more errands.").replace("{ERRAND_LEFT}",errandsLeft);
		}
		if((list.length == 0 && !hasChat)) {
			list = <h3>{I("Loading")}</h3>;
			if(p.listReady){
				list = <h3>{I("No errands")}</h3>;
			}
		}
		if(!p.listReady && p.contextChanged){
			list = <h3>{I("Loading")}</h3>;
		}
		if(p.mobileMode) {
			if(p.currentOpened !=0) {
				hideConversation = true;
			}
		}
		if(p.type == PREF_LIST_VIEW) {
			if(!p.mobileMode){
				listClassName = "app-content width-100";
				if(p.collapseSideBar){
					listClassName += " collapsed";
				}
				hideList = false;
				hideConversation = false;
			}
		}else{
				hideList = true;
				listClassName = "app-conversations";
		}
		if(p.showErrandFullView){
			hideList = true;
			hideConversation = true;
		}
		if(p.viewSingleErrandOnly && !isTelavoxEnabled()){
			hideConversation = true;
		}
		const moreLoading = this.hasMore() ?
			<div className="scroll-spinner">
				<i className="fa fa-spinner fa-spin"></i>
			</div>
		: null;

		return (
			<div className={listClassName} hidden={hideConversation}>
					<ErrandListHeader
						errandListType={p.type}
						listCurrentSorting={p.listCurrentSorting}
						listCurrentSortName={p.listCurrentSortName}
						listSortDirection={p.listSortDirection}
						loading={p.loading}
						onSelect={p.onSelectAll}
						onSelectSort={p.onSelectSort}
						onToggleSort={p.onToggleShowSort}
						onToggleSideBar={p.onToggleSideBar}
						onRefreshList={p.onSetLoadList}
						showSortSelection={p.showSort}
						showSideBar={p.showSideBar}
						collapseSideBar={p.collapseSideBar}
						showListView={p.showListView}
						selectedSort={p.selectedSort}
						selectAll={p.errandAllSelected}
						onSetErrandView= {p.onSetErrandView}
						currentContext={p.currentContext}
						mobileMode={p.mobileMode}
						isExternal={p.isExternal}
					/>
					{!IsContextSearch(p.currentContext) ?
						<Fragment>
						{hideList && p.folderUI}
						<Scrollable onScroll={this.handleScroll} disable={hideList ? false : true}>
							<WorkflowInnerContainerCtnr
								id="ErrandList" forwardRef={(el)=>{this.scrollableList=el}} hidden={hideList ? false : true} className="app-inner-content" data-conversations data-custom-scrollbar
							>
								{chatErrandList}
								{/* <NewComponent
								 listos = {list}
								 dataList = {p.listViewData}
								 context={p.currentContext}
								 {...this.props}
								/> */}
								{list}
								{moreLoading}
								<div className="info-thickbox" hidden={errandsLeft == 0 ? true : false}>{errandsLeftText}</div>
							</WorkflowInnerContainerCtnr>
						</Scrollable>
						</Fragment>
						:
						<WorkflowInnerContainerCtnr id="ErrandList" hidden={hideList ? false : true} className="app-inner-content" data-conversations>
							{chatErrandList}
							{list}
							<div className="info-search-paginate vertical" hidden={p.totalSearchResult <= p.searchOffset ? true : false}>
								<ReactPaginate
									previousLabel={<i className="icon-chevron-left"></i>}
									nextLabel={<i className="icon-chevron-right"></i>}
									pageCount={Math.ceil(p.totalSearchResult / p.searchOffset)}
									onPageChange={this.onHandlePaginate}
									containerClassName={"pagination justify-content-end"}
									activeClassName={"page-item active"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									pageLinkClassName={"page-link"}
									marginPagesDisplayed={1}
									pageRangeDisplayed={1}
								/>
							</div>
						</WorkflowInnerContainerCtnr>
					}
					<ErrandListView
						allowAgentForwardToAgent={allowAgentForwardToAgent}
						allowForwardErrandsToAgent={allowForwardErrandsToAgent}
						forwardErrandsAgents={forwardErrandsAgents}
						hidden={hideList ? true : false}
						hasPendingReviewErrand={hasPendingReviewErrand}
						isLoadingFwdErrandsAgents={isLoadingFwdErrandsAgents}
						listReady={p.listReady}
						list={listViewData}
						chatErrandSelected={p.chatErrandSelected}
						allowDueDate={this.props.allowDueDate}
						allowCloseErrand={this.props.allowCloseErrand}
						onRefreshList={p.onSetLoadList}
						totalSelected={p.totalSelected}
						selectedErrands={p.selectedErrands}
						currentOffset={p.currentOffset}
						currentOpened={p.currentOpened}
						pageSize={p.currentSize}
						onPageChange={p.onNextPage}
						onOpenForwardToAgent={onOpenForwardToAgent}
						onSelectForwardToAgent={onSelectForwardToAgent}
						onSortList={p.onSortList}
						totalList={p.listTotalCountForPagination}
						onSelectErrand={p.onSelectOne}
						onSelectAllErrand={p.onSelectAll}
						selectAll={p.errandAllSelected}
						onClickErrand={(errandId) => {
							const clickedErrand = listViewData.find(errand => errand.data.id === errandId);
							if (clickedErrand && clickedErrand.chat) {
								clickedErrand.setMessagesSeen();
								clickedErrand.onClick(errandId, clickedErrand.chat, false); 
							}
							else {
								p.onClick(errandId);
							}
						}
						}
						previewActivated={p.previewActivated}
						onLoadPreview={p.onLoadPreview}
						previewData={p.previewData}
						userFolders={p.userFolders}
						showMoveToFolder={p.showMoveToFolder}
						onHandleMoveToFolder={p.onHandleMoveToFolder}
						agentCanPutBackErrands={p.agentCanPutBackErrands}
						agentCanMoveFromInboxFeature={p.agentCanMoveFromInboxFeature}
						agentCanForwardToAreaFeature={p.agentCanForwardToAreaFeature}
						agentCanForwardToAreaInOrganizationFeature={p.agentCanForwardToAreaInOrganizationFeature}
						forwardToAreas={p.forwardToAreas}
						showForwardAreaOptions={p.showForwardAreaOptions}
						onHandleForwardToArea={p.onHandleForwardToArea}
						onHandlePrintErrands={p.onHandlePrintErrands}
						shouldPopPrint={p.shouldPopPrint}
						printContent={p.printContent}
						currentContext={p.currentContext}
						agentCanDeleteErrandsFeature={p.agentCanDeleteErrandsFeature}
						onHandleDelete={p.onHandleDelete}
						onHandleClose={p.onHandleClose}
						onHandleSetPriority={p.onHandleSetPriority}
						onHandleSetDueDate={p.onHandleSetDueDate}
						onHandleReturnToInbox={p.onHandleReturnToInbox}
						onHandleSendingBulkErrand={p.onHandleSendingBulkErrand}
						onHandleLinkErrand={p.onHandleLinkErrand}
						onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
						showOrganizationColumnFeature={p.showOrganizationColumnFeature}
						dueDateFeature={p.dueDateFeature}
						postponeDateFeature={p.postponeDateFeature}
						showHighPriorityFeature={p.showHighPriorityFeature}
						showMultipleActions={p.showMultipleActions}
						mobile={p.mobileMode}
						onGetCollaborationInfo={p.onGetCollaborationInfo}
						collaborationInfo={p.collaborationInfo}
						canOffCollaborationLight={p.canOffCollaborationLight}
						onToggleLight={p.onToggleLight}
						defaultFolderId={p.defaultFolderId}
						onGetSlaTime={p.onGetSlaTime}
						slaTime={p.slaTime}
						showSlaTimeFeature={p.showSlaTimeFeature}
						selectedAgent={p.selectedAgent}
						areas={p.areas}
						handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
						agentWorking={p.agentWorking}
						activeCreatedErrand={p.activeCreatedErrand}
						sipGetCurrentEid={p.sipGetCurrentEid}
						sipMakeCallCurrentErrand={p.sipMakeCallCurrentErrand}
					/>
				</div>
			);
	}
}

export default ErrandList;
