import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { I } from '../../common/v5/config';
import { getChevronIcon } from '../../common/v5/helpers';
import { withCancellableOpenErrand } from '../../containers/hocs';
import Link from '../../containers/link';
import { SideBarDropdownWrapperWithTooltip } from './SidebarSettings';

var ERRANDTYPE = 0, AREATYPE = 1, TAGTYPE = 2, KBTYPE = 3;

class HeaderSearch extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			value: '',
			showCollapsedSearchMenu: false
		}
		this.renderCatItem = this.renderCatItem.bind(this);
	}
	wrap(match){
		return '<span class="search-line">' + match + '</span>';
	}
	highlightSearchTerm(term, searchResult){
		let escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		let regex = new RegExp(escapedTerm, 'gi');
		return searchResult.replace(regex, match => this.wrap(match));
	}
	renderItem(items, searchTerm, types){
		let showTotal = 5, count = 0;
		let rsl = [];
		let textLimit = 25;
		if(this.props.resultTextLimit) {
			textLimit = this.props.resultTextLimit;
		}
		items.forEach((rs, i) => {
			count++;
			if(count <= showTotal){
				let rsBody = rs.body;
				if(rs.body.length > textLimit){
					rsBody = rsBody.substr(0, textLimit) + '...';
				}
				let body = this.highlightSearchTerm(searchTerm, rsBody);
				rsl.push(<li key={i}>
					<Link to={""+this.urlOpenAction(types, rs.id)}
						 dangerouslySetInnerHTML={{__html: body}}
					 />
				</li>);
			}
		});
		return rsl;
	}
	urlOpenAction(type, itemId) {
		const { protocol, hostname } = window.location;
		let handlerPath = "";
		switch(type){
			case AREATYPE:
				handlerPath = "admin#areas/"+ itemId;
				break;
			case TAGTYPE:
				handlerPath = "admin#tag/"+ itemId;
				break;
			case KBTYPE:
				handlerPath = "admin#library/suggestion/"+ itemId;
				break;
			default:
				handlerPath = "v5/search";
		}
		return process.env.PATH_PREFIX + handlerPath;
	}
	handleLinkErrand = (id) =>{
		this.props.openErrand(id);
	}
	renderErrand(items, searchTerm){
		let showTotal = 5, count = 0;
		let rsl = [];
		let textLimit = 25;
		if(this.props.resultTextLimit) {
			textLimit = this.props.resultTextLimit;
		}
		items.forEach((rs, i) => {
			count++;
			if(count <= showTotal){
				let rsBody = rs.body;
				if(rs.body.length > textLimit){
					//strip out <br> and <br/> and <br /> and <p> and <div>
					//or any other html tags that may introduce a new line
					rsBody = rsBody.replace(/<br\s*\/?>/gi, ' ');
					rsBody = rsBody.replace(/<p\s*\/?>/gi, ' ');
					rsBody = rsBody.replace(/<div\s*\/?>/gi, ' ');
					rsBody = rsBody.replace(/<[^>]*>/gi, ' ');
					rsBody = rsBody.substr(0, textLimit) + '...';
				}
				let body = this.highlightSearchTerm(searchTerm, rsBody);
				rsl.push(<LinkErrand key={i} eid={rs.id} body={body} handleClick={this.handleLinkErrand} />);
			}
		});
		return rsl;
	}
	renderCatItem(items, term,catName, types){
		if(items.length === 0){
			return <li><a>{I("No result found")}</a></li>;
		}
		if( types === ERRANDTYPE){
			return items && items.length > 0 && <li>
					<a href="#">{catName}</a>
					<ul className="search-sublist">{this.renderErrand(items, term)}</ul>
			</li>;
		} else {
			return items && items.length > 0 && <li><a href="#">{catName}</a><ul className="search-sublist">{this.renderItem(items, term, types)}</ul></li>;
		}
	}
	handleChevronUpDown = () =>{
		let p = this.props;
		p.handleHeaderDD(!p.showResultBox);
	}
	render(){
		let p = this.props;
		let searchDD = "";
		let chevron;
		if (p.showResultBox) {
			searchDD = "dropped";
		}
		if (p.isSearching) {
			chevron = "fa fa-spinner fa-spin";
		} else {
			chevron = getChevronIcon(p.showResultBox);
		}
		return(
			<div className="header-search-box">
				<div className="header-search-actions">
					<button type="submit" onClick={p.onSearchButton}>
						<i hidden={this.props.withHoverForInput} className="icon-search"></i>
					</button>
					<input
						value={p.searchText}
						className="search-field"
						type="text"
						name="searchText"
						data-qa-id="global-search-input"
						placeholder={I('Search')}
						onKeyDown={p.onKeyDown}
						onKeyUp={p.onKeyUp}
						onChange={p.onChange}
						autoComplete="off"
					/>
					<a href="#" data-dropdown-target="search-dropdown" hidden={this.props.withHoverForInput && !p.isSearching} className={searchDD} onClick={this.handleChevronUpDown}>
						<i className={chevron} />
					</a>
				</div>
				<HeaderSearchDropdown
					show={p.showResultBox}
					searchDD={searchDD}
					searchResults={p.searchResults}
					searchText={p.searchText}
					onHide={this.handleChevronUpDown}
					isResultEmpty={this.isResultEmpty}
					onRenderCatItem={this.renderCatItem}
					withHoverForInput={p.withHoverForInput}
				/>
			</div>
		)
	}
	isResultEmpty = () =>{
		let p = this.props;
		let rs = false;
		if(p.searchResults.errands.length > 0)
			return true;
		else if(p.searchResults.areas.length > 0)
			return true;
		else if(p.searchResults.tags.length > 0)
			return true;
		else if(p.searchResults.knowledgeBases.length > 0)
			return true;
		else
			return false
	}
}

class LinkErrand extends React.Component {
	handleClick = () =>{
		let {eid} = this.props;
		this.props.handleClick(eid);
	}
	render = ()=>{
		let p = this.props;
		return(<li><a onClick={this.handleClick} dangerouslySetInnerHTML={{__html: p.body }}></a></li>)
	}
}

class StaticHeaderSearchDropdown extends React.Component {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	handleClickOutside() {
		if(!this.props.withHoverForInput) {
			this.props.onHide();
		}
	}
	render() {
		const {show, searchDD, searchResults, searchText, isResultEmpty, onRenderCatItem} = this.props;
		if(show){
			return(
				<div className={"header-dropdown " + searchDD} data-dropdown="search-dropdown">
					<ul className="search-list">
						{onRenderCatItem(searchResults.errands, searchText, I("ERRANDS"), ERRANDTYPE)}
						{/*@Mujibur: Temporarily disabled*/}
						{/*{onRenderCatItem(searchResults.areas, searchText, I("AREAS"), AREATYPE)}*/}
						{/*{onRenderCatItem(searchResults.tags, searchText, I("TAGS"),TAGTYPE)}*/}
						{/*{onRenderCatItem(searchResults.knowledgeBases, searchText, I("KNOWLEDGE BASE"), KBTYPE)}*/}
						{isResultEmpty() && <li className="more">
							<Link to={{pathname: `${process.env.PATH_PREFIX}v5/search`,state: { searchTerm: searchText }}}>{I('More results and options')}</Link>
						</li>}
					</ul>
				</div>
			)
		}else{
			return null;
		}
	}
}

const HeaderSearchDropdown = onClickOutside(StaticHeaderSearchDropdown);

export default withCancellableOpenErrand(HeaderSearch);
