import React from 'react';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import onClickOutside from 'react-onclickoutside';
import Measure from 'react-measure';
import { GenerateDropdown2 as D4 } from '../DrilldownDropdown';
import { I } from '../../common/v5/config.js';
import {
	CHAT_crOwner,
} from '../../common/v5/constants.js';
import { doNothing } from '../../reactcomponents/common';
import AddMore from '../../reactcomponents/AddMore';
import styled from 'styled-components';
import { DEFAULT_TAG_COLOR } from '../../common/v5/constants';

class UnhideD4 extends React.Component {
	handleClickOutside = evt => {
		this.props.onClickOutside();
	}
	render() {
		const p = this.props;
		if(!p.show) {
			return null;
		}
		return <D4 id={p.me} customCssClass={p.cssClass} tags={p.tags}
			addSelectedItem={p.onAddSelectedItem} height={p.height} marginTop={p.marginTop}
			clearCurrentItem={p.clearCurrentItem}
			selectedTags={p.selectedTags}
			onDeleteTag={p.onDeleteTag}
			/>;
	}
}

const HideableD4 = onClickOutside(UnhideD4);

export const ColorizeTag = styled.div`
	border-radius: 10px;
	background-color: ${props => props.tagColor}
`;

class OneTag extends React.Component {
	constructor(props) {
		super(props);
		this.handleDelete = this.handleDelete.bind(this);
	}
	handleDelete(e) {
		this.props.onDeleteTag(this.props.myIndex, this.props.me);
	}
	render() {
		const p = this.props;
		let wLimitStyle = {};
		if(Number.isNaN(p.widthLimit) == false) {
			wLimitStyle = {
				maxWidth: p.widthLimit
			}
		}
		let tagId = p.me.join('-');
		return(
			<li ref={p.innerRef} id={"errand-tag-"+tagId} data-tag-id={tagId}>
				<a className="tag-label" href="#" style={wLimitStyle} title={p.name}>{p.name}</a>
				{p.readOnly
				? null
				: <a href="#" className="remove">
					<i className="icon-times" data-qa-id={"delete-tag-"+p["data-qa-id"]}
						onClick={this.handleDelete}></i>
				</a>
				}
			</li>

		)
	}
}

class ErrandTagList extends React.Component {
	constructor(props) {
		super(props);
		this.genName = this.genName.bind(this);
		this.findTag = this.findTag.bind(this);
		this.getIndexFromSelected = this.getIndexFromSelected.bind(this);
		this.handleDeleteHiddenTag = this.handleDeleteHiddenTag.bind(this);
		this.calcWidth = this.calcWidth.bind(this);
		this.state = {
			dimensions: {
				width: -1,
				height: -1,
			},
		}
	}
	findTag(src, id) {
		let found, index;
		$.each(src, (i,v) => {
			if(v.id === id) {
				found = true;
				index = i;
				return false;
			}
		});
		if(found) {
			return src[index];
		}
		return null;
	}
	genName(tags, ids) {
		let names = [];
		$.each(ids, function(i,v) {
			let tag = this.findTag(tags, v);
			if(tag) {
				names.push(tag.value);
				if(!tag.children) {
					return false;
				} else {
					tags = tag.children;
				}
			}
		}.bind(this));
		return names.join(' / ');
	}
	isVisibleTag(parent, child, widthLimit) {
		return !(
			(child.offsetLeft - parent.offsetLeft > (widthLimit-100))
		)
	}
	calcWidth(widthLimit, elem) {
		if(elem){
			let pa = elem.parentElement.parentElement;
			if(pa) {
				let paWidth = pa.getBoundingClientRect().width;
				let visible = this.isVisibleTag(pa, elem, widthLimit);
				if(widthLimit > 0 && (paWidth > widthLimit)){
					if(!visible){
						elem.classList.add("invisible-tag");
					}else{
						elem.classList.remove("invisible-tag");
					}
				}else{
					elem.classList.remove("invisible-tag");
				}
			}
		}
	}
	getIndexFromSelected(selectedTagId) {
		for(let i=0;i<this.props.selectedTags.length;i++){
			let tags = this.props.selectedTags[i];
			if (tags.join('-') == selectedTagId) {
				return i;
			}
		}
	}
	handleDeleteHiddenTag(index, arr) {
		if(this.props.onDeleteTag){
			let selectedTagId = arr.join('-');
			let ind = this.getIndexFromSelected(selectedTagId);
			let elem = document.querySelector('[data-tag-id="'+selectedTagId+'"]');
			if(elem){
				elem.classList.remove("invisible-tag");
			}
			this.props.onDeleteTag(ind, arr);
		}
	}
	render() {
		const p = this.props, data = p.selectedTags;
		const selectedTagsObj = p.selectedTagsObj;
		const { width, height } = this.state.dimensions;
		let list = [], listTail = [], hiddenTags = [];

		let widthLimit = p.outerWidth;
		let tagColor = "unset";
		if(outerWidth > 0) {
			widthLimit = p.outerWidth-200;
		}

		for (let i = 0; i < data.length; i++) {
			let v = data[i];
			let parentId = v[0];
			//set tags color only if parent tag and is VIP
			if(parentId == selectedTagsObj[i].id) {
				if(selectedTagsObj[i].parent && selectedTagsObj[i].isVIP){
					tagColor = selectedTagsObj[i].tagColor
				} else {
					tagColor = DEFAULT_TAG_COLOR;
				}
			}

			list.push(<ColorizeTag key={'' + p.eid + i} tagColor={tagColor} className="colored-tag">
				<OneTag innerRef={(e) => this.calcWidth((p.outerWidth-200), e)} key={'' + p.eid + i} me={v} myIndex={i} showMore={p.showMore} data-qa-id={p["data-qa-id"]+"-"+v}
					readOnly={p.readOnly}
					tagColor={tagColor}
					// widthLimit={100}
					name={this.genName(p.tags, v)} onDeleteTag={p.onDeleteTag} />
				</ColorizeTag>
			);
		}

		let hideLink = (typeof p.hideAddErrandLink !== 'undefined' &&
		p.hideAddErrandLink == true) ? true : false;

		let tagListWidthLimit = 0;

		if(p.outerWidth){
			tagListWidthLimit = p.outerWidth-180; //reserve space for 'show more' and 'add tag'
		}

		// Need improvement on decide the limit maybe
		if(p.showMore && (this.state.dimensions.width > tagListWidthLimit)){
			let unseenTags = document.getElementsByClassName("invisible-tag");
			if(unseenTags){
				if(unseenTags.length > 0){
					for (let i = 0; i < unseenTags.length; i++) {
						let ids = unseenTags[i].getAttribute('data-tag-id');
						hiddenTags.push(ids.split("-").map(x=>+x));
					}
				}
			}

			if(hiddenTags.length > 0) {
				listTail.push(<ShowAllTags allTags={p.tags} tags={hiddenTags} onGetName={this.genName} key={'showmore' + p.eid} onDeleteTag={this.handleDeleteHiddenTag} className="link" />);
			}
		}

		let tagListStyle = {}, addTagStyle = {marginLeft: "5px"}, ulStyle = {};

		if(p.showMore){
			ulStyle = {
				display: "-webkit-flex",
				display: "inline-flex"
			}
			tagListStyle = {
				float: "left",
				maxWidth: tagListWidthLimit,
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
				// marginRight: "5px",
				display: "flex",
			}
			addTagStyle = {
				float: "right"
			}
		}

		let addMoreTags = <AddMore
							$addButtonOnRight={true}
							className='link add-tag'
							data-qa-id={p["data-qa-id"]}
							key={'addtag' + p.eid}
							innerClassName='ignore-react-onclickoutside'
							onClick={p.onToggleTagsSelection}
							style={addTagStyle}
							tag='li'
						>
							{I('add tag')}
						</AddMore>;

		if(p.readOnly || hideLink) {
			addMoreTags = null;
		}

		return (
			<Measure
				bounds
				client={true}
				onResize={contentRect => {
					this.setState({
						dimensions: contentRect.bounds,
					})
				}}
				>
				{({ measureRef }) => (
					<ul className="errand-tags-list" style={ulStyle} ref={measureRef}>
						{p.addPosition === "left" && addMoreTags}
						{
							p.showMore ?
							<div className="tags-container" style={tagListStyle}>{list}</div>
							: list
						}
						{listTail}
						{p.addPosition !== "left" && addMoreTags}
					</ul>
				)}
			</Measure>
		)
	}
}

class ErrandTags extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleAddSelectedItem = this.handleAddSelectedItem.bind(this);
		this.handleDeleteTag = this.handleDeleteTag.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleToggleD4 = this.handleToggleD4.bind(this);
	}
	handleAddSelectedItem(...tags) {
		const v = []
		each(tags, ({ id }) => { v.push(id) })
		if (v.length > 0) {
			this.props.onAddTag(v, this.props.chat)
		}
	}
	handleDeleteTag(index, array) {
		this.props.onDeleteTag(index, this.props.chat);
	}
	handleClickOutside(evt) {
		this.props.onToggleTagsSelection(false);
	}
	handleToggleD4(evt) {
		this.props.onToggleTagsSelection(!this.props.showTagsSelection);
	}
	render() {
		const {
				chat
				, hideAddErrandLink
				, readOnly
				, ...p
			} = this.props
			;
		let allTags
			;
		if (p.allTags) {
			allTags = p.allTags;
		} else {
			allTags = p.tags;
		}

		let errandTagsStyle = {};
		if(p.showMore){
			errandTagsStyle={
				display: "flex"
			}
		}

		let selTagsObj = [];
		//filter selectedTags with more data
		$.each(p.selectedTags, function(i, v) {
			selTagsObj.push({
				id: p.selectedTags[i][0],
				subId: p.selectedTags[i][1],
				parent: false
			})
			if(!p.selectedTags[i][1]){
				selTagsObj[i].parent = true;
			}
			$.each(p.tags, function(j, u) {
				if (p.tags[j].id == selTagsObj[i].id){
					selTagsObj[i].isVIP = p.tags[j].isVIP;
					selTagsObj[i].tagColor = p.tags[j].tagColor;
				}
			})
		})

		if(p.me === 'classifytags' && !p.tagsReady){
			return <i className="fa fa-spinner fa-spin" aria-hidden="true" />;
		}else{
			return <div className="field errand-tags" style={errandTagsStyle}>
				<span className="label">{p.title}</span>
				<div className="panel-wrapper">
					<ErrandTagList selectedTags={p.selectedTags} eid={p.eid} outerWidth={p.outerWidth} showMore={p.showMore}
						data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id}
						readOnly={(chat && chat.Role != CHAT_crOwner) || readOnly}
						hideAddErrandLink={hideAddErrandLink}
						tags={allTags} onDeleteTag={this.handleDeleteTag}
						selectedTagsObj={selTagsObj}
						onToggleTagsSelection={this.handleToggleD4}
						addPosition={p.addPosition} />
					{!readOnly &&
						<HideableD4
							show={p.showTagsSelection || hideAddErrandLink}
							me={p.me+'d4'}
							data-qa-id={p["data-qa-id"] ? p["data-qa-id"] : p.id}
							tags={p.tags}
							height={p.height}
							marginTop={p.marginTop}
							onAddSelectedItem={this.handleAddSelectedItem}
							onClickOutside={this.handleClickOutside}
							clearCurrentItem={p.clearCurrentItem}
							selectedTags={p.selectedTags}
							onDeleteTag={this.handleDeleteTag}
						/>
					}
				</div>
			</div>;
		}
	}
}

class ShowAllTags extends React.PureComponent {
	constructor(props){
		super(props);
		this.displayName = "ShowAllTags";
		this.state = {
			showTags: false
		}
	}
	render(){
		const p = this.props;
		let tagCount = 0, tagsInfo = [];
		if(typeof p.tags !== "undefined"){
			tagCount = p.tags.length;
			$.each(p.tags, function(i,v) {
				tagsInfo.push(<OneTag key={"allTagItem"+i} me={v} myIndex={i} data-qa-id={p["data-qa-id"]+"-"+v}
					name={p.onGetName(p.allTags, v)} onDeleteTag={p.onDeleteTag} />);
			});
		}
		return (
				<div className="errand-message-tags" data-tooltip>
					<li className="link">
						<a href="#" className='ignore-react-onclickoutside' data-qa-id={p["data-qa-id"]}>
							{I('show more')}
						</a>
					</li>
					<div className="errand-message-tags-info tooltip-content text-center">
						<ul>
							{tagsInfo}
						</ul>
					</div>
				</div>
			)
	}
}

// DisplayOnlyTags understand the errand tags component (ETC) and stimulate the
// require allTags / tags props for ETC and hide the implementation of ETC and
// simplify the component prop for only doing the display job as it requires
// only selectedTags prop.
export const DisplayOnlyTags = ({
	allTags
	, hideAddErrandLink
	, onAddTag
	, onDeleteTag
	, onToggleTagsSelection
	, selectedTags
	, showTagsSelection
	, tags
	, ...props
}) => {
	let t = []
		, selected = []
		;
	$.each(selectedTags, (i, v) => {
		let value;
		if (typeof v === "string") {
			value = v;
		} else {
			value = v.desc;
		}
		const id = i+1;
		t.push({id, value});
		selected.push([id]);
	});
	return (
		<ErrandTags {...props}
			allTags={t}
			onAddTag={doNothing}
			onDeleteTag={doNothing}
			onToggleTagsSelection={doNothing}
			readOnly={true}
			showTagsSelection={false}
			selectedTags={selected}
			tags={t}
		/>
	);
};

DisplayOnlyTags.propTypes = {
	selectedTags: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.shape({
			id: PropTypes.number
			, desc: PropTypes.string
		})
	])).isRequired
};

export default ErrandTags;
