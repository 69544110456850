import React, { memo } from 'react'
import { composeWithDisplayName } from '../../reactcomponents/hocs'
import Moment, {
  withFormatChange,
  withReadOnlyFullTime
} from '../../reactcomponents/Moment'
import { withAgentTimezonOffset } from '../../containers/hocs'

const EnchancedMoment = composeWithDisplayName(
  'EnchancedMoment',
  memo,
  withAgentTimezonOffset,
  withFormatChange,
  withReadOnlyFullTime
)(Moment)

export default EnchancedMoment
