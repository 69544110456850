//TODO(Mujib): Plan to make it as sharable component as possible to errands/messages/... list etc
import React from 'react';
import classNames from 'classnames';
import Reload from '../../reactcomponents/Reload';
import MessageBrief from './MessageBrief';
import ToggleSideBar from './ToggleSideBar';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import Scrollable from './Scrollable';
import ListSortByDate from '../../reactcomponents/ListSortByDate';
import {I} from '../../common/v5/config.js';
import { ButtonIcon } from '../../reactcomponents/HeaderActions';
import { WorkflowInnerContainerCtnr } from '../../views/v5/workflowCtnr';

const sortSelectionOptions = [
	{name: I('ASC'), id: 'dateAsc', attr: 'date', dir: 'asc', icon: 'icon-arrow-up'},
	{name: I('DESC'), id: 'dateDesc', attr: 'date', dir: 'desc', icon: 'icon-arrow-down'}
];

const maxWidth100Percent = {maxWidth: '100%'}
	, paddingBottom10px = {paddingBottom: '10px'}
	;
class BoxListHeader extends React.Component {
	constructor(props) {
		super(props);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleSelectSort = this.handleSelectSort.bind(this);
		this.handleShowSelectSort = this.handleShowSelectSort.bind(this);
		this.handleToggleSideBar = this.handleToggleSideBar.bind(this);
		// this.handleSwitchToListView = this.handleSwitchToListView.bind(this);
		this.handleRefreshList = this.handleRefreshList.bind(this);
	}
	handleSelectAll(v) {
		this.props.onSelect(v);
	}
	handleShowSelectSort(e) {
		if(typeof e === 'boolean') {
			this.props.onToggleSort(e);
		} else {
			this.props.onToggleSort(!this.props.showSortSelection);
		}
	}
	handleSelectSort(index) {
		var sort = sortSelectionOptions.filter(function (el) {
			return el.id == index;
		});
		this.props.onSelectSort(sort[0].attr, 1, sort[0].dir);
	}
	handleToggleSideBar(v) {
		const p = this.props;
		p.onToggleSideBar(v);
	}
	// handleSwitchToListView() {
	// 	let show = false;
	// 	if(!this.props.showListView){
	// 		show = true;
	// 	}else{
	// 		show = false;
	// 	}
	// 	this.props.onSwitchToListView(show);
	// }
	handleRefreshList() {
		this.props.onRefreshList();
	}
	onDeleteMessages = () => {
		let ids = this.props.selectedMessages ? this.props.selectedMessages.join(",") : "";
		this.props.onHandleDeleteMessage(ids);
	}
	onRestoreMessages = () => {
		let ids = this.props.selectedMessages ? this.props.selectedMessages.join(",") : "";
		this.props.onHandleRestoreMessage(ids);
	}
	render() {
		const p = this.props;
		let customStyledAppHeader = {};
		if(!p.showSideBar) {
			customStyledAppHeader = { borderRadius: "initial"}
		}
		return <div className="app-header" style={customStyledAppHeader}>
				<ul className="conversations-nav" style={maxWidth100Percent}>
					<ToggleSideBar visible={!p.showSideBar || p.collapseSideBar} showSideBar={p.showSideBar} wrapped={true} collapsed={p.collapseSideBar}
						onClick={this.handleToggleSideBar} />
					<li style={{paddingBottom: "0px"}}>
						<Reload
							data-qa-id={p['data-qa-id']+"refresh-msgs"}
							loading={p.loading}
							onClick={this.handleRefreshList}
						/>
					</li>
					<li
						hidden={p.hideListOptions}
						style={{paddingBottom: "0px"}}
					>
						<SquareCheckbox
							className="big"
							data-qa-id={p['data-qa-id']+"select-all"}
							label={I('Select all')}
							checked={p.selectAll} // mak: TODO no props of selectAll
							onClick={this.handleSelectAll}
						/>
					</li>
					<li
						hidden={p.hideListOptions}
						>
						<ListSortByDate
							data-qa-id={p['data-qa-id']+"list_sort"}
							options={sortSelectionOptions}
							selectedSort={p.selectedSort}
							showSortSelection= {p.showSortSelection}
							onSelectSort={this.handleSelectSort}
							onToggleSort={this.handleShowSelectSort}
						/>
					</li>
					<div className="right-nav-actions">
						<div className="im-selected-count" hidden={!p.totalSelected || !p.mobileMode}>
							{p.totalSelected}
						</div>
						<ButtonIcon
							className={"btn im-action c3-delete-im"}
							hidden={!p.totalSelected || !p.mobileMode}
							icon={"fas fa-trash-alt"}
							onClick={this.onDeleteMessages}
							data-qa-id={"mobile-btn-delete-im"}
							noTooltip={true}
						/>
						{(p.selectedFolder === 3) &&
							<ButtonIcon
								className={"btn im-action c3-restore-im"}
								hidden={!p.totalSelected || !p.mobileMode}
								icon={"icon-return"}
								onClick={this.onRestoreMessages}
								data-qa-id={"mobile-btn-restore-im"}
								noTooltip={true}
						/>}
					</div>
				</ul>
			</div>;
	}
}

class MessageView extends React.Component {
	constructor(props) {
		super(props);
		this.handleLoadMore = this.handleLoadMore.bind(this);
	}
	componentDidMount(){
		document.addEventListener('scroll', this.handleScroll);
	}
	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleScroll);
	}
	handleScroll = (atBottom) => {
		// if(this.props.type !== "listView"){
			if(atBottom) {
				this.handleLoadMore();
			}
		// }
	}
	hasMore() {
		document.removeEventListener('scroll', this.handleScroll);
		return false;
	}
	handleLoadMore(){
		let hasMore = this.hasMore();
		if(hasMore) {
			this.props.onLoadMore();
		}
	}
	renderMoreLoading(more){
		if(more) {
			return (
				<div className="scroll-spinner">
					<i className="fa fa-spinner fa-spin"></i>
				</div>
			)
		}
	}
	render() {
		const p = this.props, d = p.data.norm, o = p.data.order, s = p.data.opr;
		let list = [], hideConversation = false, hideListOptions = false;
		let hasMore = this.hasMore();
		let msgCount = Object.keys(d).length;

		if( msgCount > 0) {
			for (var i = 0; i < msgCount; i++) {
				if(d[o[i]] && d[o[i]].mid) {
					const v = d[o[i]]
					const sSelected = s[v.mid]
					list.push(<MessageBrief errand={v} key={i} me={v.mid}
						onClick={p.onClick} onDoubleClick={p.onDoubleClick} tzOffset={p.tzOffset}
						onSelect={p.onSelectOne} select={sSelected.selected} avatar={v.avatar}/>);
				}
			}
		}
		if(!list.length) {
			list = <h3>{I('No messages')}</h3>;
		}
		if(p.mobileMode) {
			if(p.showErrandMobileView || p.showSideBar || (p.currentMessageID && p.totalSelected)) {
				hideConversation = true;
			}
		}
		if(p.data && $.isEmptyObject(p.data.norm)){
			hideListOptions = true;
		}
		return (
			<div className={classNames("app-conversations", {collapsed: p.collapseSideBar})} hidden={hideConversation}>
					<BoxListHeader
						data-qa-id={"im-box-header"}
						loading={p.loading}
						onSelect={p.onSelectAll}
						onSelectSort={p.onSelectSort}
						onToggleSort={p.onToggleShowSort}
						onToggleSideBar={p.onToggleSideBar}
						collapseSideBar={p.collapseSideBar}
						// onSwitchToListView={p.onSwitchToListView}
						onRefreshList={p.onSetLoadList}
						showSortSelection={p.showSort}
						showSideBar={p.showSideBar}
						// showListView={p.showListView}
						selectedSort={p.selectedSort}
						selectAll={p.data.allSelected}
						onHandleDeleteMessage={p.onHandleDeleteMessage}
						onHandleRestoreMessage={p.onHandleRestoreMessage}
						selectedMessages={p.selectedMessages}
						hideListOptions={hideListOptions}
						mobileMode={p.mobileMode}
						totalSelected={p.totalSelected}
						selectedFolder={p.selectedFolder}
						/>
					<Scrollable onScroll={this.handleScroll} disable={false}>
						<WorkflowInnerContainerCtnr id="IMList" hidden={false} className="app-inner-content" data-conversations data-custom-scrollbar>
							{list}
							{this.renderMoreLoading(hasMore)}
						</WorkflowInnerContainerCtnr>
					</Scrollable>
				</div>
			);
	}
}

export default MessageView;
