import React, { memo, useMemo } from 'react';
import { branch, renderComponent } from 'recompose';
import {
	CL_OVERVIEW,
	OC_LIMITS,
	OL_LEFT_PANE,
	OL_MID_PANE,
	OL_RIGHT_PANE
} from '../../common/v5/constants';
import { PC_NEW_CHART_LAYOUT } from '../../common/v5/statisticConstants';
import { getChevronIcon } from '../../common/v5/helpers';
import ChartGrid from './ChartGrid';
import { withChartRenderer } from './Report';
import { composeWithDisplayName } from '../../reactcomponents/hocs';
import { ActionDropdown } from '../../reactcomponents/Dropdown';
import ChartEditor from './ChartEditor';

const rangeItems = [
	{Id: "today", Name: I("Today")},
	{Id: "lastweek", Name: I("Last Week")},
	{Id: "lastmonth", Name: I("Last Month")},
];

const ManyWidgets = props => <div className="widgets" data-custom-scrollbar {...props} />;

const ChartRenderer = withChartRenderer(ManyWidgets);

const OverviewChartRenderer = props => (
	<ChartRenderer {...props} layout={CL_OVERVIEW} limits={OC_LIMITS} />
);

const Widgets = props => (
	<OverviewChartRenderer {...props} position={OL_LEFT_PANE} />
);

class WidgetReport extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleActionToggling = this.handleActionToggling.bind(this);
		this.state = {
			toggleRange: false
		};
	}
	handleActionToggling() {
		this.setState({toggleRange: !this.state.toggleRange});
	}
	onSelectRange(id) {
		console.log("dbg: select range", id);
	}
	render() {
		const { data, onLinkClick } = this.props;
		return (
			<div className="widgets-col">
				<div className="col-header">
					{
						/*<ActionDropdown
							title={<span className="range-select">{I("Today")}<i className={getChevronIcon(this.state.toggleRange)}></i></span>}
							id="selectRange"
							show={this.state.toggleRange}
							data={rangeItems}
							isHeader={false}
							idFields={{id: "Id", value: "Name"}}
							onToggle={this.handleActionToggling}
							onSelectItem={this.onSelectRange}
						/>*/
					}
				</div>
				<div className="slimScrollDiv">
					<Widgets data={data} onLinkClick={onLinkClick} />
				</div>
			</div>
		);
	}
};

const MiddlePanels = props => (
	<div className="leaderboard-col">
		<OverviewChartRenderer {...props} position={OL_MID_PANE} />
	</div>
);

const RightPanels = props => (
	<div className="candle-bars-col">
		<OverviewChartRenderer {...props} position={OL_RIGHT_PANE} />
	</div>
);

class OldExecutiveReport extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const { widgets, middlePanels, rightPanels, onLinkClick } = this.props;
		return (
			<div>
				<div className="app-inner-content">
					<WidgetReport data={widgets} onLinkClick={onLinkClick} />
					<MiddlePanels data={middlePanels} onLinkClick={onLinkClick} />
					<RightPanels data={rightPanels} onLinkClick={onLinkClick} />
				</div>
			</div>
		);
	}
};

const ExecutiveReportBase = ({
	agentTimezoneOffset
	, chartMap
	, grid
	, onChangeShowEditor
	, onGridChange
	, onGridSave
	, onLinkClick
	, pinReportsById
	, reportsById
	, saveDisabled
	, showEditor
	, systemReportsByName
}) => (
	<ChartGrid
		agentTimezoneOffset={agentTimezoneOffset}
		chartMap={chartMap}
		grid={grid}
		layout={CL_OVERVIEW}
		onChangeShowEditor={onChangeShowEditor}
		onGridChange={onGridChange}
		onGridSave={onGridSave}
		onLinkClick={onLinkClick}
		pinReportsById={pinReportsById}
		reportsById={reportsById}
		saveDisabled={saveDisabled}
		showEditor={showEditor}
		systemReportsByName={systemReportsByName}
	/>
);

const ExecutiveReport = composeWithDisplayName(
	"ExecutiveReport"
	, memo
	, branch(() => !PC_NEW_CHART_LAYOUT, renderComponent(OldExecutiveReport))
)(ExecutiveReportBase);

export default ExecutiveReport;
