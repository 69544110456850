import React, { useState, useEffect } from 'react';
import AdminAction from '../../flux/actions/adminActions';
import TableComponent from '../TableComponent';
import { I, webRoot } from '../../common/v5/config';
import { FooterButton } from '../../reactcomponents/Admin';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';


const OpenExternalV5 =(props)=>{
    const [keepOrgOpenExt, setKeepOrgOpenExt] = useState(true);
    const [inputUrl, setInputUrl] = useState("");
    const [externalSystemRules, setExternalSystemRules] = useState([]);
    const [openExternalSystemRulesId, setOpenExternalSystemRulesId] = useState("");
    
    useEffect(() => {
        if (props.externalSystemRules !== externalSystemRules) {
          let p = props;
          setKeepOrgOpenExt(p.keepOrgOpenExt);
          setExternalSystemRules(p.externalSystemRules);
          setOpenExternalSystemRulesId(p.openExternalSystemRulesId);
        }
        if (props.keepOrgOpenExt !== keepOrgOpenExt){
            setKeepOrgOpenExt(props.keepOrgOpenExt);
        }
      }, [props.externalSystemRules, externalSystemRules]);
      
    const handleInputChange = (event) => {
        setInputUrl(event.target.value);
    }

    const addLink = () => {
        if (inputUrl === "") {
            return;
        }

        if (!props.isArea) {
            props.saveOrgExtSysRules(props.id, {
                url: inputUrl
            });
        } else {
            AdminAction.saveAreaExtSysRules(props.id, {
                url: inputUrl
            }).then( (data) =>{
                var ops = {};
                ops.openExternalSystemRulesId = openExternalSystemRulesId;
                ops.openExternalSystemRulesPresent = true;
                ops.keepOrgOpenExt = keepOrgOpenExt;
                props.saveAreaExternal(OPENEXTERNAL, ops, props.id, props.orgId);
            });
        }

        setInputUrl("");
    }

    const saveOrg = () => {
        var ops = {};
        ops.openExternalSystemRulesId = openExternalSystemRulesId;
        ops.openExternalSystemRulesPresent = true;
        ops.keepOrgOpenExt = keepOrgOpenExt;
        props.saveOrganization(OPENEXTERNAL, ops);
    }

    const handleItemDelete = (action) => {
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            if (props.usedFor !== "area") {
                props.getOrg();
            } else {
                var ops = {};
                ops.openExternalSystemRulesId = openExternalSystemRulesId;
                ops.openExternalSystemRulesPresent = true;
                ops.keepOrgOpenExt = keepOrgOpenExt;
                props.saveAreaExternal(OPENEXTERNAL, ops, props.id, props.orgId);
            }
        });
    }

    const handleInputCheck = (checked) => {
        setKeepOrgOpenExt(!checked);
    }

    const defaultPaginationSize = 13;
    if (typeof externalSystemRules === 'undefined') {
        return null;
    }

    let RemoveButton = {};
    let addField = (
        <input
            name="contactByEmail"
            className="form-control input-sm"
            type="text"
            onChange={handleInputChange}
            value={inputUrl}
        />
    );

    let addButton = {
        type: "orgEdit"
    };

    if (props.isArea) {
        RemoveButton = {
            action: webRoot + "admin/area/" + props.id + "/exturl/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
    } else {
        let url = "admin/org/";
        if (props.usedFor === "area") {
            url = "admin/area/";
        }
        RemoveButton = {
            action: webRoot + url + props.id + "/exturl/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
    }

    let column = [
        { header: I("URL"), key: "url", subHeaderAction: addField },
        { header: I("Action"), orgType: 'edit', data: RemoveButton, type: 'actionCombineLink', subHeaderAction: addButton }
    ];

    let keepOrg = (
        <div className="form-group-org">
            <label className="col-lg-12">{I("Keep organization settings")} :</label>
            <div className="col-lg-12">
                <SwitchCheckbox
                    active={keepOrgOpenExt}
                    buttonClassName={""}
                    id={"orgActiveSwitch"}
                    name={"organisationSetting"}
                    type="checkbox"
                    onClick={handleInputCheck}
                />
            </div>
        </div>
    );

    if (props.usedFor === "org") {
        keepOrg = null;
    }

    if (props.usedFor === "area") {
        if (keepOrgOpenExt) {
            return (
                <div className="col-lg-12">
                    {keepOrg}
                    <FooterButton cancel={props.cancelToOrg} save={saveOrg} />
                </div>
            );
        }
    }

    return (
        <div className="col-lg-12 external-system-settings">
            {keepOrg}
            <div className="form-group-org">
                <div className="col-md-12">
                    <TableComponent
                        dataSource={externalSystemRules}
                        columns={column}
                        itemDeleteAction={handleItemDelete}
                        itemAddAction={addLink}
                        paginate={true}
                        pageSize={defaultPaginationSize}
                        enablePageSizeChange={false}
                        enableSubHeaders={true}
                        organisationV5={true}
                    />
                </div>
            </div>
            <FooterButton cancel={props.cancelToOrg} save={saveOrg} />
        </div>
    );
}
export default OpenExternalV5;