import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
	branch
	, compose
	, mapProps
	, renameProp
	, renderComponent
	, withHandlers
	, withProps
} from 'recompose';
import classNames from 'classnames';
import SplitPane from 'react-split-pane';
import { I } from '../../common/v5/config.js';
import {
	getEmailOutParentheses
	, getChevronIcon
	, isValidEmail
	, getHotkeyChar
} from '../../common/v5/helpers.js';
import {
	SIP_REGISTER
	, SIP_CONNECTED
	, SIP_DISCONNECTED
	, SIP_CALL_IDLE
	, SIP_CALL_CONNECTED
	, SIP_CALL_CONNECTING
	, SIP_CALL_ON_HOLD
	, CALL_STR_DURATION
	, WARM_TRANSFER
	, SIP_OUTGOING_CALL_CONNECTING
	, SIP_OUTGOING_CALL_CONNECTED
} from '../../common/v5/callConstants.js';
import { TextField } from '../../reactcomponents/Form';
import { StandardAction } from '../../reactcomponents/HeaderActions';
import {
	withIdentity
	, withRandomMultiProps
} from '../../reactcomponents/hocsForTests';
import OneReplyNav from '../../reactcomponents/OneReplyNav';
import {
	composeWithDisplayName
	, createWithSingleOptionHandler
	, withDisableableOnClick
	, withIdAttachedOnClick
	, withSingleAreaOptionDetect
	, withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import { BTTN_CLEAR } from '../../reactcomponents/DialPad';
import Ckeditor from '../v5/Ckeditor';
import {
	AreaDropdown
} from '../../reactcomponents/Dropdown';
import ErrandTags from './ErrandTags';
import {
	Buttons,
	EditorFootnotes,
	FooterCheckboxes,
} from './Footers';
import ReplyToolbar from './ReplyToolbar';
import {
	LabeledSelectBox
	, LabeledSelection
	, SelectBox
} from '../../reactcomponents/SelectBox';
import TagPicker, { ControlTagPicker } from './TagPicker';
import { CtrlKnowledgeBasePopup } from './KnowledgeBasePopup';
import { SimpleDialPadCtnr } from '../../containers/call';
import { CallTimer } from './SipNotification';
import {
	BTN_CLEAR,
	DEFAULT_ACCOUNT_TITLE,
	DEFAULT_SIMPLE_TOOLBAR,
	ME_CREATE,
	ME_START,
	ME_NOTE,
	ME_ST_BUSY,
	ME_ST_CREATED,
	ME_CREATE_AS_MY,
	MP_CALL,
	MP_BASIC_CALL,
	MP_ERRAND,
	MP_NONE,
	OBJ_ID_NAME,
	OPT_CHANNELS,
	OPT_FIELDS_ID_VALUE,
	OPT_ME_CALL_CREATE_AS,
	OPT_ME_CREATE_AS,
	RECIPIENT_TO,
	RPLY_MANUAL,
	RPLY_MANUAL_COMMENT,
	RPLY_ERRAND,
	RPLY_COMMENT,
	RPLY_QUESTION,
	ERRAND_HOTKEYS,
	TOGGLE_REPLY,
	TOGGLE_COMMENT,
	TOGGLE_QUESTION,
	RC_EMAIL,
	RC_TWITTER,
	RC_VOICE,
	TAG_ADD,
	TAG_DELETE,
	UNKNOWN_WORKFLOW_SERVICE_TYPE,
	UNSELECT,
	emptyArray
} from '../../common/v5/constants';
import { MANUAL_CALL_ERRAND_MAX_TO } from '../../redux/constants/constants';
import {
	buttonStateSelector,
	isPreviewDisabledSelector,
	showPreviewSelector
} from '../../redux/selectors/errand';
import CFlag, { NoCFlag } from '../../reactcomponents/CFlag';

const baseClassName = "manual errand popup"
	, mErdToolbarID = "ckev5mceTop"
	, allowEscToClear = [BTTN_CLEAR]
	, styleSplitPane = {position: "unset", overflow: "visible"}
	, stylePadding = {padding: "10px"}
	, styleDisplayNone = {display: "none"}
	, NEW_TITLE = I('New')
	;
const SplitOrNot = ({ isCall, ...props }) => {
	let maxSize
		, minSize
		, resizerStyle
		, size
		;
	if (!isCall) {
		maxSize = "100%";
		size = "100%";
		resizerStyle = styleDisplayNone;
	} else {
		maxSize = 500;
		minSize = 250;
	}
	return (
		<SplitPane
			allowResize={isCall}
			split={props.mobile ? "horizontal" : "vertical"}
			defaultSize="58%"
			maxSize={maxSize}
			minSize={minSize}
			size={size}
			style={styleSplitPane}
			resizerStyle={resizerStyle}
			{...props}
		/>
	);
};

class Bttns extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleButtonClick = this.handleButtonClick.bind(this);
	}
	handleButtonClick(type, e) {
		if(type === BTN_CLEAR) {
			return this.props.onClear(e);
		}
		if(this.props.disabled == true){
			return;
		}
		this.props.onSubmit(this.props.selectedTab, ME_CREATE_AS_MY, true,
		true);
	}
	render() {
		const {
			buttonsState
			, disabled
			, onSelectCreation
		} = this.props;
		return (
			<Buttons
				className="errand-buttons buttons-right"
				condition={buttonsState}
				onButtonClick={this.handleButtonClick}
				tag='button'
			>
			</Buttons>
		);
	}
}

const createSingleOptionHandler = fieldName => withHandlers({
	onSingleOption: ({ onSelect }) => item => onSelect(item[fieldName])
});

const keyAreas = {key: 'Areas'};

const AreaBase = props => (
	<AreaDropdown
		title={I('AREA:')}
		data-qa-id="manual-area-dd"
		nested={keyAreas}
		idFields={OBJ_ID_NAME}
		textNoItemSelected={I("select an area")}
		{...props}
	/>
);

const Area = composeWithDisplayName(
	"Area"
	, createSingleOptionHandler("Id")
	, withSingleAreaOptionDetect
)(AreaBase);

class Titles extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showTag: false,
			showArea: false
		};
		this.handleToggleKnowledgeBase = this.handleToggleKnowledgeBase.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.toggleTag = this.toggleTag.bind(this);
		this.toggleArea = this.toggleArea.bind(this);
		this.addTag = this.addTag.bind(this);
		this.delTag = this.delTag.bind(this);
	}
	handleSelectAll(v) {
	}
	toggleTag(v) {
		this.setState({showTag: v});
	}
	toggleArea(t, v) {
		this.setState({showArea: !v});
	}
	addTag(v) {
		this.props.onTagChange(TAG_ADD, v);
	}
	delTag(v) {
		this.props.onTagChange(TAG_DELETE, v);
	}
	handleToggleKnowledgeBase() {
		const p = this.props;
		if(p.selectedArea > 0){
			p.onToggleKnowledgeBase(!p.openKnowledgeBase);
		}
	}
	render() {
		const {
				isCall
				, onOpenInsertProtectedContentReply
				, selectedTab
				, disabled
				, sipMakeCallCurrentErrand
				, ...p
			} = this.props
			;
		return (
			<div className="errand-titles">
				<table style={{width: "100%"}}>
					<tbody>
						<tr>
							<td>
								<div>
									<span className="call-title-input">{I('ERRAND:')}</span>
								</div>
								<div>
									<span className="errand-number">{p.number}</span>
								</div>
							</td>
							<td>
								<Area
									className="call-title-input"
									show={this.state.showArea}
									data={p.areas}
									selected={p.selectedArea}
									notReady={!p.areas}
									onToggle={this.toggleArea}
									readOnly={p.disabled || sipMakeCallCurrentErrand}
									onSelect={p.onAreaChange}
									splitTitleAndLink={true}
								/>
							</td>
							{features["cention-library"] &&
							<td>
								<div className={classNames("reply-links-label", {disabled: sipMakeCallCurrentErrand})}>
									<div className="call-title-input">
									{I('KNOWLEDGE BASE:')}
									</div>
									<div style={{color: "#0C87F7"}}>
									<CtrlKnowledgeBasePopup
										id="errandKnowledgeBase"
										open={this.state.openKnowledgeBase}
										onToggle={this.handleToggleKnowledgeBase}
										title={I("Open Knowledge base")}
									/>
									</div>
								</div>
							</td>
							}
						</tr>
						<tr>
							<td colSpan="3" style={{padding: "10px 0px"}}>
								<ErrandTags
									me='meTags'
									data-qa-id={"manual-add-tag"}
									tags={p.tags}
									onAddTag={this.addTag}
									height='60vh'
									outerWidth='450'
									marginTop={'20px'}
									showMore={true}
									eid={0}
									readOnly={(isCall && !sipMakeCallCurrentErrand) ? false : (p.disabled || sipMakeCallCurrentErrand)}
									onDeleteTag={this.delTag}
									onToggleTagsSelection={this.toggleTag}
									showTagsSelection={this.state.showTag}
									selectedTags={p.selectedTags}
									addPosition={"left"}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

const withRecipient = Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleFailValidation = this.handleFailValidation.bind(this);
		this.handleOpenContactBook = this.handleOpenContactBook.bind(this);
	}
	handleFailValidation(type, inpt) {
		if (type === RC_EMAIL) {
			if (this.props.onFailValidation) {
				this.props.onFailValidation(type, inpt);
			}
		}
	}
	handleChange(inputs, name) {
		let filtereds = [];
		if(inputs && inputs.length > 0) {
			$.each(inputs, (i,v) => {
				let filtered;
				if (this.props.type === RC_EMAIL) {
					const email = getEmailOutParentheses(v);
					if (isValidEmail(email)) {
						filtered = email;
					} else {
						this.handleFailValidation(this.props.type, email);
						return;
					}
				} else {
					filtered = v;
				}
				filtereds.push({id: filtered, value: filtered});
			});
		}
		this.props.onChange(filtereds, name);
	}
	handleOpenContactBook() {
		this.props.onToggleContactBook(true, this.props.name)
	}
	render() {
		const { inputRef, inputValue, onChangeInput, ...p } = this.props;
		let inputText = inputValue;
		if(this.props.callType === MP_BASIC_CALL){
			inputText = "";
		}
		return (
			<div className={classNames("form-1-2", p.className)}>
				<div className="tag-picker-input-call">
					<Component
						id={p.id}
						inputValue={inputText}
						ref={inputRef}
						type={p.type}
						name={p.name}
						maxInput={p.maxInput}
						options={p.options}
						fields={OPT_FIELDS_ID_VALUE}
						disabled={p.disabled}
						selected={p.selected}
						onChange={this.handleChange}
						onChangeInput={onChangeInput}
						onFailValidation={this.handleFailValidation}
						onShowContactBook={this.handleOpenContactBook}
					/>
				</div>
			</div>
		);
	}
}

const ControlToRecipient = withRecipient(ControlTagPicker);

const withRemoveProps = propsToBeRemoved => Component => ({ ...props }) => {
	$.each(propsToBeRemoved, (i, v) => {
		delete props[v];
	});
	return <Component {...props} />;
};

const Recipient = compose(
	withRemoveProps(["inputRef", "inputValue", "onChangeInput"])
	, withRecipient
)(TagPicker);

const ToRecipient = ({ isCall, ...props }) => {
	if (isCall) {
		return <ControlToRecipient {...props} />;
	}
	return <Recipient {...props} />
};

class Toolbar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showSelectChannel: false
		};
		this.handleShowSelectChannel = this.handleShowSelectChannel.bind(this);
	}
	handleShowSelectChannel(v) {
		this.setState({showSelectChannel: v});
	}
	render() {
		const {
				channels
				, isCall
				, onSelectChannel
				, selectedChannel
			} = this.props
			;
		return (
			<div className="errand-edit">
				<div className="errand-edit-wrap">
					<ReplyToolbar
						show={true}
						showChannelOpt={!isCall}
						className="manual-errand-toolbar"
						enableAction={false}
						toolbarID={mErdToolbarID}
						options={channels}
						replyAs={RPLY_MANUAL}
						selectedChannel={selectedChannel}
						showChannelSelection={this.state.showSelectChannel}
						onSelectChannel={onSelectChannel}
						onToggleChannel={this.handleShowSelectChannel}
					/>
				</div>
			</div>
		);
	}
}

const SubjectInput = ({ id, value, ...props }) => (
	<div className="call-div">
		<label htmlFor={id}>{I('SUBJECT: ')}</label>
		<input className="input"
			id={id}
			data-qa-id="manual-call-subject"
			type="text"
			value={value}
			{...props}
		/>
	</div>
);

const withIdFieldSingleOptionHandler = createSingleOptionHandler("id");

const withConstantProps = textNoItemSelected => withProps({
	id: "account"
	, selectClassName: "errand-subject sidebar-nav-select"
	, wrapClassName: "label-block account"
	, textNoItemSelected
});

function createArrayDataWithUnselectDetector(optionsPropName) {
	return (props, prevProps) => {
		const data = props[optionsPropName];
		if (data.length
			&& data.length === 2
			&& data !== prevProps[optionsPropName]) {
			let item;
			$.each(data, (i, v) => {
				if (v.id !== UNSELECT) {
					item = v;
					return false;
				}
			});
			return item;
		}
	};
}

const AccountSelectBox = composeWithDisplayName(
	"AccountSelectBox"
	, withIdFieldSingleOptionHandler
	, createWithSingleOptionHandler(createArrayDataWithUnselectDetector("options"))
	, withConstantProps(I("Select an account"))
)(LabeledSelectBox);

class AccountSelectBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(v) {
		let data = {};
		for (let i = 0; i < this.props.options.length; i++) {
			if (this.props.options[i].id === v) {
				data = this.props.options[i];
			}
		}
		this.props.onSelect(
			this.props.type
			, data.id
			, data.accountId
			, data.value
		);
	}
	render() {
		const {
				isCall
				, onSelect
				, onSelectPhone
				, type
				, ...props
			} = this.props
			;
		return <AccountSelectBox onSelect={this.handleSelect} {...props} />;
	}
}

function createNormalizedDataDetector(normalizedPropsName, orderPropsName) {
	return (props, prevProps) => {
		const norm = props[normalizedPropsName]
			, order = props[orderPropsName]
			;
		if ((order.length && order.length === 1)
			&& (norm !== prevProps[normalizedPropsName]
				|| order !== prevProps[orderPropsName])) {
			return norm[order[0]];
		}
	};
}

const PhoneSelectBox = composeWithDisplayName(
	"withPhoneSelectBox"
	, renameProp("onSelectPhone", "onSelect")
	, mapProps(({ options, ...props }) => ({
		options: options.norm
		, order: options.order
		, tooltipProp: "value"
		, ...props
	}))
	, withIdFieldSingleOptionHandler
	, createWithSingleOptionHandler(createNormalizedDataDetector(
		"options"
		, "order"
	))
	, withConstantProps(I("Select a phone"))
)(LabeledSelection);

const AccountSelect = branch(
	({ isCall }) => isCall
	, renderComponent(PhoneSelectBox)
)(AccountSelectBase);

const TabWrapper = ({ children }) => (
	<div className="tab create active">
		<form action="#" className="form-create">
			{children}
		</form>
	</div>
);


class IdDropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}
	toggle() {
		this.setState({dropdownOpen: !this.state.dropdownOpen});
	}
	handleSelect(id){
		if(this.props.onSelect){
			this.props.onSelect(id);
		}
	}
	render() {
		const { options, selected } = this.props
			, show = this.state.dropdownOpen
			;
		let optItems = [];
		$.each(options, (i,v) => {
			optItems.push(
				<li key={i}>
					<a onClick={(e) => this.handleSelect(v.id)}>{v.label}</a>
				</li>
			);
		});
		let chevClass= getChevronIcon(show) + " right-floater";
		return (
			<div
				className="filter-select" style={{width: "100%", background: "white"}}
				data-dropdown-menu
				onClick={this.toggle}
			>
				<span>{selected}</span>
				<i className={chevClass} />
				<ul className="dropdown-menu" hidden={!show}>
					{optItems}
				</ul>
			</div>
		);
	}
}

class TabContent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
				// TODO: this seem useless as it seem not controllable by us but
				// browser.
				showBrowseAttachmentPopup: false,
				showArchiveAttachmentPopup: false,
				hideKeypad: false
		};
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.handleLibraryClick = this.handleLibraryClick.bind(this);
		this.toggleShowArchive = this.toggleShowArchive.bind(this);
		this.handleContentClear = this.handleContentClear.bind(this);
		this.handleContentChange = this.handleContentChange.bind(this);
		this.handleChangeToInput = this.handleChangeToInput.bind(this);
		this.handleSubjectChange = this.handleSubjectChange.bind(this);
		this.handleCallClick = this.handleCallClick.bind(this);
		this.handlePauseClick = this.handlePauseClick.bind(this);
		this.handleCallRecord = this.handleCallRecord.bind(this);
		this.onToggleContactBook = this.onToggleContactBook.bind(this);
		this.answerTransferCall = this.answerTransferCall.bind(this);
		this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
		this.handleDragnDropFile = this.handleDragnDropFile.bind(this);
		this.handleOnChangeToInput = this.handleOnChangeToInput.bind(this);
		this.handleDtmf = this.handleDtmf.bind(this);
		this.generateExtensionOptions = this.generateExtensionOptions.bind(this);
		this.handleToggleKeypad = this.handleToggleKeypad.bind(this);
		this.handleStopTimer = this.handleStopTimer.bind(this);
		this.showCallIcon = true;
	}
	componentDidMount() {
		this.showCallIcon = true;
	}
	handleSelectAll(v) {
	}
	handleKeyDown() {
	}
	handleCkeditorChange(e) {
		const p = this.props
			, cke = e.editor
			, ckText = cke.document.getBody().getText()
			;
		this.handleContentChange(cke.getData(),ckText);
	}
	getPreference(prefs, selectedPref) {
		let contents = "";
		prefs.forEach((s, i) => {
			if (s.id === selectedPref) {
				contents = s.value;
				return;
			}
		});
		return contents;
	}
	handleLibraryClick() {
	}
	toggleShowArchive(currentShow) {
		this.setState({showArchiveAttachmentPopup: !currentShow});
	}
	handleContentClear(e) {
		let which = this.props.currentReplyOption === ME_NOTE ? "internal_comment" : "update_answer";
		if(this.props.sipMakeCallCurrentErrand) {
			this.props.onClear(which, true, true);
		} else {
			this.props.onClear(which, true, false);
		}
	}
	handleContentChange(html, plain) {
		let which = this.props.currentReplyOption === ME_NOTE ? "internal_comment" : "update_answer";
		let currentErrand = false;
		if(this.props.sipMakeCallCurrentErrand) {
			currentErrand = true;
		}
		this.props.onContentChange(which, html, plain, currentErrand);
	}
	handleAttachmentDelete(type, attachmentID, index, e, attachmentFileName) {
		let reply = this.props.currentReplyOption === ME_NOTE ? RPLY_MANUAL_COMMENT : RPLY_MANUAL;
		this.props.onAttachmentDelete(reply, type, attachmentID, index, e, attachmentFileName)
	}
	handleDragnDropFile(data, info) {
		let reply = this.props.currentReplyOption === ME_NOTE ? RPLY_MANUAL_COMMENT : RPLY_MANUAL;
		this.props.onDragnDropFile(reply, data, info)
	}
	handleChangeToInput(e) {
		this.props.onChangeToInput(e.target.value);
	}
	onToggleContactBook(e) {
		this.props.onToggleContactBook(true, "to");
	}
	answerTransferCall(e) {
		if(this.props.sipCallTransferStatus == SIP_CALL_CONNECTED &&
			this.props.sipXferMode == WARM_TRANSFER){
			this.props.finalizeWarmTransferToAgent(this.props.newErrandId,
				this.props.createdId,this.props.sipPhone,
				this.props.mCipherKey, true,
				this.props.sipCallTransferIsExternal);
		} else if(this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED ||
			this.props.sipCallStatus == SIP_CALL_ON_HOLD){
				this.props.answerTransferCall(this.props.sipConn,
				this.props.createdId);
		}
	}
	handleCallClick(e) {
		if(this.props.createdId){
			if(this.props.sipCallStatus == SIP_DISCONNECTED) {
				return;
			}
			if(this.props.sipCallStatus == SIP_CONNECTED ||
				this.props.sipCallStatus == SIP_CALL_IDLE ||
				this.props.sipCallStatus == SIP_REGISTER){
				this.props.onCallClick();
			} else {
				if(this.props.sipCallIsRecording == true){
					this.handleCallRecord(e);
				}
				this.props.onHangupClick();
			}
			this.showCallIcon = false;
		}
	}
	handlePauseClick(e) {
		if(this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED){
			this.props.onCallHoldClick(true, true);
		} else if(this.props.sipCallStatus == SIP_CALL_ON_HOLD){
			this.props.onCallHoldClick(false, true);
		}
	}
	handleCallRecord(e) {
		if(this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED) {
			console.info("calling recordSipCall");
			this.props.recordSipCall(this.props.createdId, this.props.sipPhone,
				!this.props.sipCallIsRecording);
		}
	}
	generateExtensionOptions() {
		let extId = this.props.voiceExtensionId;
		let retVal = {}
		if(extId){
			retVal.id = extId;
			retVal.label = extId;
		}
		return retVal
	}
	handleDtmf(v) {
		if(this.props.sipCallStatus == SIP_CALL_CONNECTED ||
			this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED) {
			this.props.handleDtmf(this.props.sipPhone, v);
		}
	}
	handleOnChangeToInput(v) {
		this.props.onChangeToInput(v);
	}
	handleSubjectChange(e) {
		let currentErrand = false;
		if(this.props.sipMakeCallCurrentErrand) {
			currentErrand = true;
		}
		this.props.onSubjectChange(e, currentErrand);
	}
	handleToggleKeypad() {
		this.setState({hideKeypad: !this.state.hideKeypad});
	}
	handleStopTimer(callTime) {
		let toRecipients = "";
		if(typeof this.props.recipients != 'undefined' && this.props.recipients != null){
			toRecipients = this.props.recipients.to;
		}
		if(typeof this.props.commonApiCall !== 'undefined' &&
			this.props.commonApiCall != null){
			this.props.commonApiCall(CALL_STR_DURATION, toRecipients[0],
				false, Math.round(callTime/1000));
		}
	}
	render() {
		const {
			children
			, ckeditorSettings
			, createdId
			, disabled
			, insertionText
			, isCall
			, mobile
			, onChange
			, onChangeToInput
			, onOpenInsertProtectedContentReply
			, onResetInsertionText
			, replyOptions
			, selectedChannel
			, selectedTab
			, currentReplyOption
			, toInputValue
			, voiceExtensionId
			, toInputRef
			, recipients
			, onFailValidation
			, onToggleContactBook
			, onDeleteClick
			, recipientOptions
			, sipAllowRecord
			, innerTabs
			, sipMakeCallCurrentErrand
			, sipCallTransferStatus
			, ...p
		} = this.props
			, replyChData = replyOptions[selectedChannel]
			;
		let languageSrc
			, toolbar
			, title
			;
		let hideAttachment = false;
		let callCircle = "";
		let recordCircle = "";
		let forwardCircle = "";
		let recordTip = I("Record call");
		let callTip = I("Dial");
		let callIcon = "icon-make-call"
		let toRecipients = [];
		let disableButtons = true;
		let disableIcons = true;
		let callInProgress = false;
		if (replyChData) {
			toolbar = replyChData.toolbar;
		}
		if (!toolbar) {
			toolbar = DEFAULT_SIMPLE_TOOLBAR;
		}
		if (!createdId) {
			title = NEW_TITLE;
			disableButtons = false;
		} else {
			title = createdId;
			if(p.sipCallStatus == SIP_REGISTER || p.sipCallStatus ==
				SIP_CALL_IDLE || p.sipCallStatus == SIP_CONNECTED){
					callCircle = "green-circle";
					disableButtons = false;
					disableIcons = false;
			}
			if(p.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED) {
				callInProgress = true;
				callCircle = "red-circle";
				recordCircle = "grey-circle";
				disableIcons = false;
				if(this.props.sipHideColdTransfer === true &&
					this.props.sipHideWarmTransfer === true){
					forwardCircle = "grey-circle";
				} else {
					forwardCircle = "green-circle";
				}
			}
		}
		if(p.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED || p.sipCallStatus == SIP_OUTGOING_CALL_CONNECTING ||
			p.sipCallStatus === SIP_CALL_ON_HOLD) {
			callCircle = "red-circle";
			disableIcons = false;
		}
		if (ckeditorSettings.languageSrc
			&& ckeditorSettings.languageSrc.length) {
			languageSrc = ckeditorSettings.languageSrc;
		} else {
			languageSrc = emptyArray;
		}
		if(typeof recipients != 'undefined' && recipients != null){
			toRecipients = recipients.to;
		}
		let callElem = <SimpleDialPadCtnr
							allowedButtonsChangeValue={allowEscToClear}
							hidden={!isCall}
							value={toInputValue}
							onChange={this.handleOnChangeToInput}
							onChangeDtmf={this.handleDtmf}
							sipCallStatus={p.sipCallStatus}
							type={MP_BASIC_CALL}
							embedded={true}
							withClose={false}
						/>;
		let callIds = this.generateExtensionOptions();
		let pauseCircle = "";
		let pauseTip = I("Hold call");
		let stylePaddingPause = {padding: "10px"}
		if(p.sipCallStatus == SIP_CALL_ON_HOLD){
			pauseCircle = "grey-circle";
			pauseTip = I("Resume call");
			stylePaddingPause = {};
			callInProgress = true;
		}
		if(this.showCallIcon == false){
			callIcon = "icon-redial";
			if(p.sipCallStatus == SIP_CALL_CONNECTING ||
				p.sipCallStatus == SIP_CALL_CONNECTED ||
				p.sipCallStatus == SIP_OUTGOING_CALL_CONNECTED ||
				p.sipCallStatus == SIP_OUTGOING_CALL_CONNECTING ||
				p.sipCallStatus == SIP_CALL_ON_HOLD){
				callTip = I("Hang up");
				callInProgress = true;
				callIcon = "icon-call";
			} else {
				callTip = I("Redial");
				callInProgress = false;
				forwardCircle = "grey-circle";
			}
		}
		let disableForwardButton = false;
		if(!callInProgress){
			disableForwardButton = true;
		} else {
			if(this.props.sipHideWarmTransfer &&
			this.props.sipHideColdTransfer){
				disableForwardButton = true;
			} else {
				if(sipCallTransferStatus == SIP_CALL_IDLE ||
					sipCallTransferStatus == SIP_DISCONNECTED) {
					disableForwardButton = false;
				} else if(sipCallTransferStatus != SIP_CALL_CONNECTED &&
					sipCallTransferStatus != SIP_OUTGOING_CALL_CONNECTED){
					disableForwardButton = true;
				} else if(sipCallTransferStatus == SIP_CALL_CONNECTED){
					forwardCircle = "green-circle";
				}
			}
		}
		if(this.props.sipCallIsRecording == true){
			recordCircle = "red-circle-blink";
			recordTip = I("Stop recording");
		}
		let callStatus = "";
		switch (p.sipCallStatus) {
			case SIP_OUTGOING_CALL_CONNECTING:
				callStatus = I("Connecting");
				break;
			case SIP_OUTGOING_CALL_CONNECTED:
				callStatus = I("Connected");
				break;
			case SIP_CALL_ON_HOLD:
				callStatus = I("On hold");
				break;
			default:
				callStatus = "";
				break;
		}
		return (
			<TabWrapper>
				<SplitOrNot isCall={isCall} mobile={mobile}>
					<div className="form-wrapper" style={{marginLeft: "10px", marginRight: "10px"}}>
						{innerTabs}
						{currentReplyOption != ME_NOTE && <Titles
							answer={p.answer}
							plain={p.plain}
							appendTemplate={p.appendTemplate}
							areas={p.areas}
							centionLibrary={p.centionLibrary}
							disabled={disabled || sipMakeCallCurrentErrand}
							isCall={isCall}
							number={title}
							selectedArea={p.selectedArea}
							selectedChannel={selectedChannel}
							selectedTab={selectedTab}
							selectedTags={p.selectedTags}
							salutations={p.salutations}
							selectedSal={p.selectedSal}
							selectedSig={p.selectedSig}
							setSalutation={p.setSalutation}
							setSignature={p.setSignature}
							signatures={p.signatures}
							tags={p.tags}
							templates={p.templates}
							showPreview={p.showPreview}
							previewDisabled={p.previewDisabled}
							onAreaChange={p.onAreaChange}
							onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
							onTagChange={p.onTagChange}
							onToggleKnowledgeBase={p.onToggleKnowledgeBase}
							openKnowledgeBase={p.openKnowledgeBase}
							onClickPreview={p.onClickPreview}
							currentSelected={p.currentSelected}
							selectedCreation={p.selectedCreation}
							sipMakeCallCurrentErrand={sipMakeCallCurrentErrand}
						/>
						}
						{children}
						{currentReplyOption != ME_NOTE && <SubjectInput
							id="mceSubject"
							onChange={this.handleSubjectChange}
							value={p.subject}
							disabled={this.props.callType === MP_BASIC_CALL ? false : disabled}
						/>
						}
						<div className="call-div editor">
							{currentReplyOption != ME_NOTE &&
							<label htmlFor="mcedescription">
								{I('DESCRIPTION: ')}
							</label>
							}
							<div className="editor">
								<Ckeditor
									id="ckev5mce"
									insertionText={insertionText}
									isReadOnly={this.props.callType === MP_BASIC_CALL ? false : disabled}
									defaultFontFamily={ckeditorSettings.fontFamily}
									height={140}
									toolbarID={mErdToolbarID}
									defaultFontSize={ckeditorSettings.fontSize}
									defaultContent={p.content}
									spellLanguages={languageSrc}
									{...toolbar}
									fileArchiveImages={p.archives}
									onKeydown={this.handleKeyDown}
									onChange={this.handleCkeditorChange}
									onDragnDropFiles={this.handleDragnDropFile}
									onLibraryClick={this.handleLibraryClick}
									onResetInsertionText={onResetInsertionText}
								/>
								<Toolbar
									channels={p.channels}
									disabled={disabled}
									isCall={isCall}
									onSelectChannel={p.onSelectChannel}
									selectedChannel={selectedChannel}
								/>
								<FooterCheckboxes
									attachmentSize={p.attachmentSize}
									checkboxes={p.footerCheckboxes}
									disabled={disabled}
									checkedStates={p.footerCheckboxStates}
									currentRoot={selectedTab}
									onClick={p.onFooterCheckboxClick}
									hideAttachmentLink={hideAttachment}
									showUploadAttachmentBox={p.showUploadAttachmentBox}
									toggleUploadAttachment={p.toggleUploadAttachment}
									canTranslate={p.canTranslate}
									toLang={p.toLang}
									onSelectedToLang={p.onSelectedToLang}
									showAttachment={p.showManualPopup}
								/>
								<EditorFootnotes
									archiveImgs={p.archives}
									attachFile={p.attachFileOptions}
									attachments={p.attachments}
									currentReply={currentReplyOption === ME_NOTE ? RPLY_MANUAL_COMMENT : RPLY_MANUAL}
									isUploading={p.uploading}
									disabled={false}
									showAttachment={this.state.showBrowseAttachmentPopup}
									showArchiveDD={this.state.showArchiveAttachmentPopup}
									showUploadAttachmentBox={p.showUploadAttachmentBox}
									onAttachmentDelete={this.handleAttachmentDelete}
									onFileupload={p.onFileupload}
									onShowArchiveAttach={this.toggleShowArchive}
									onUploadArchive={p.onUploadArchive}
								/>
								<Bttns
									buttonsState={p.buttonsState}
									isCall={isCall}
									state={p.state}
									disabled={this.props.callType === MP_BASIC_CALL ? false : disableButtons}
									selectedTab={selectedTab}
									onClear={this.handleContentClear}
									onSubmit={p.onSubmit}
									hideNewErrandMenu={p.hideNewErrandMenu}
								/>
							</div>
						</div>
					</div>
					<div className="call-side-pad">
						<div className="side-pad-title">{I('CALLER ID:')}</div>
						{/*
						<IdDropdown
							options={callIds}
							selected={voiceExtensionId}
						/>
						*/}
						<div className="caller-id">
							<span>{voiceExtensionId}</span>
						</div>
						<div className="call-dialpad">
							<div className="call-input-icons">
								<input
									id="mceToRecipient"
									placeholder={I('Phone number')}
									value={toInputValue}
									onChange={this.handleChangeToInput}
								/>
								 <i className="icon-delete"
									onClick={onDeleteClick}
								/>
							</div>
							<div className="call-timer" style={{textAlign: "center"}}>
								<CallTimer key="sip-call-timer-1" id={"outbound-timer"} callInProgress={callInProgress}
									onStop={this.handleStopTimer}
									msgTxt={callStatus}
									sipCallTimer={this.props.sipCallTimer}
									resetSipTimer={this.props.resetSipTimer}
									outgoing={true}
								/>
							</div>
							<div className="dailpad-elements" hidden={this.state.hideKeypad}>
								{callElem}
							</div>
							<div className="call-footer-icon">
								<StandardAction
										data-qa-id="manual-call-addressbook"
										text={I("Address book")}
										iconWrapperClassName={classNames("icon-contact-book", {disabled: callInProgress})}
										onClick={this.onToggleContactBook}
										overflow={false}
										fontSize="10px"
										noTooltip={callInProgress}
								/>
								{sipAllowRecord &&
									<StandardAction
										data-qa-id="manual-call-record"
										iconWrapperClassName={classNames("icon-record", recordCircle, {disabled: !callInProgress })}
										text={recordTip}
										onClick={this.handleCallRecord}
										overflow={false}
										fontSize="10px"
										className="manual-call-record"
										noTooltip={!callInProgress}
									/>
								}
								<StandardAction
									data-qa-id="manual-call-dial"
									text={callTip}
									iconWrapperClassName={classNames(callIcon, callCircle, {disabled: disableIcons})}
									onClick={this.handleCallClick}
									overflow={false}
									fontSize="10px"
									className="manual-call-dial"
									noTooltip={disableIcons}
								/>
								<StandardAction
									data-qa-id="manual-call-forward"
									text={I("Transfer call")}
									iconWrapperClassName={classNames("icon-forward-call", forwardCircle, {disabled: disableForwardButton })}
									onClick={this.answerTransferCall}
									overflow={false}
									fontSize="10px"
									noTooltip={!callInProgress}
								/>
								<StandardAction
									data-qa-id="manual-call-pause"
									text={pauseTip}
									iconWrapperClassName={classNames("icon-pause", pauseCircle, {disabled: !callInProgress})}
									onClick={this.handlePauseClick}
									overflow={true}
									fontSize="10px"
									noTooltip={!callInProgress}
								/>
								<StandardAction
									data-qa-id="manual-call-toggle-keypad"
									text={I("Hide/show keypad")}
									iconWrapperClassName={classNames("icon-keypad")}
									onClick={this.handleToggleKeypad}
									overflow={true}
									fontSize="10px"
								/>
							</div>
						</div>
					</div>
				</SplitOrNot>
			</TabWrapper>
		);
	}
}

export const ManualCallCreate = ({
	fromOptions
	, onFailValidation
	, onSelectAccount
	, onSelectPhone
	, onRecipientsChange
	, selectedAccount
	, toInputRef
	, ...props
}) => {
	const {
			disabled
			, isCall
			, onChangeToInput
			, handleDtmf
			, replyOptions
			, selectedArea
			, selectedChannel
			, selectedTab
			, currentReplyOption
			, toInputValue
		} = props
		, replyChData = replyOptions[selectedChannel]
		;
	return (
		<TabContent {...props}>
		</TabContent>
	);
};
