import React, { useState } from 'react';
import classNames from 'classnames';

export default class FileUploader extends React.Component {
	constructor(props) {
		super(props);
		this.maxFileAllowed = 0;
		this.state = {
			isUploading: false,
		};
	}

	isWithinSizeLimit = (estimatedSize) => {
		if (typeof this.props.maxFileAllowed === 'undefined' || this.props.maxFileAllowed <= 0) {
			return true; // don't care about the file limits
		}
		if (estimatedSize > this.props.maxFileAllowed) {
			return false;
		}
		return true;
	}

	uploadFiles = (event) => {
		event.preventDefault();
		let dataFiles = [];
		Object.keys(event.target.files).forEach(i => {
			if (!this.isWithinSizeLimit(event.target.files[i].size)) {
				alert(event.target.files[i].name + "\n" + I("The uploaded file has exceeded the max allowed size."));
				return;
			}
			const formData = new FormData();
			let boundary = Math.floor(Math.random() * 6) + '' + i + '' + Math.floor('' + new Date() / 1000);
			formData.append('uploadfile', event.target.files[i]);
			formData.append('fileNameOnly', event.target.files[i].name);
			formData.append('eventType', 'upload');
			formData.append('random', parseFloat(boundary));
			dataFiles.push(formData);
		});
		this.setState({ isUploading: true });
		this.props.uploadAttachment(dataFiles, this.props.uploadTo);
		// to allow same file to be able be chosen immediately next to this.
		event.target.value = '';

		setTimeout(() => {
			this.setState({ isUploading: false });
		}, 5000);
	}

	render = () => {
		const {
			attachSingle,
			multipleFile,
			cssClass,
			title,
			iconClass,
			hide,
			disabled
		} = this.props;
		const { isUploading } = this.state;

		let formFileField = "";
		if (attachSingle) {
			formFileField = (
				<span className={cssClass}>
					{title}
					<i className={iconClass} hidden={!iconClass} />
					<input
						ref="fileUpload"
						type="file"
						name="FileUpload"
						data-qa-id="file-upload"
						className="upload-file"
						disabled={disabled}
						onChange={this.uploadFiles}
					/>
				</span>
			);
		}
		if (multipleFile) {
			formFileField = (
				<span className={cssClass}>
					{title}
					<i className={iconClass} hidden={!iconClass} />
					<input
						ref="fileUpload"
						type="file"
						name="FileUpload"
						data-qa-id="file-upload-multiple"
						className="upload-file"
						disabled={disabled}
						onChange={this.uploadFiles}
						multiple="multiple"
					/>
				</span>
			);
		}
		return (
			<span hidden={hide} className="attachFileBrowse">
				{formFileField}
				{isUploading && (
					<div className="loading-bar-container">
						<div className="loading-bar-filler"></div>
					</div>
				)}
				<style jsx="true">{`
					.loading-bar-container {
						width: 100%;
						height: 4px;
						background-color: #e0e0e0;
						position: relative;
						overflow: hidden;
						margin-top: 10px;
					}
					.loading-bar-filler {
						width: 100%;
						height: 100%;
						background-color: #0c87f7;
						position: absolute;
						left: -100%;
						animation: loadingAnimation 2s infinite linear;
					}
					@keyframes loadingAnimation {
						0% {
							left: -100%;
						}
						50% {
							left: 100%;
						}
						100% {
							left: -100%;
						}
					}
				`}</style>
			</span>
		);
	}
}

//simple file input to return single uploaded file
export const FileUploaderInput = ({ hide, className, iconClass, onUploadFile, text, ...props }) => {
	return (
		<div hidden={hide} className="attachFileBrowse">
			<span className={"btn btn-file " + className}>
				{text ? text : (I("Upload file"))}
				<i className={iconClass} hidden={!iconClass} />
				<input
					type="file"
					name="FileUpload"
					data-qa-id="file-upload"
					className="upload-file"
					onChange={e => onUploadFile(e.target.files[0])}
				/>
			</span>
		</div>
	);
}

export const FileUploadDropArea = ({
	onUploadAttachment,
	uploadTo,
	children,
	maxFileAllowed
}) => {
	const [isDragOver, setIsDragOver] = useState(false);

	const isWithinSizeLimit = (estimatedSize) => {
		if (typeof maxFileAllowed === 'undefined' || maxFileAllowed <= 0) {
			return true; // don't care about the file limits
		}
		if (estimatedSize > maxFileAllowed) {
			return false;
		}
		return true;
	}

	const handleDrop = (e) => {
		e.preventDefault();
		setIsDragOver(false);
		const droppedFiles = e.dataTransfer.files;
		let dataFiles = [];
		Object.keys(droppedFiles).forEach(i => {
			if (!isWithinSizeLimit(droppedFiles[i].size)) {
				alert(droppedFiles[i].name + "\n" + I("The uploaded file has exceeded the max allowed size."));
				return;
			}

			const formData = new FormData();
			let boundary = Math.floor(Math.random() * 6) + '' + i + '' + Math.floor('' + new Date() / 1000);

			formData.append('uploadfile', droppedFiles[i]);
			formData.append('fileNameOnly', droppedFiles[i].name);
			formData.append('eventType', 'upload');
			formData.append('random', parseFloat(boundary));
			dataFiles.push(formData);
		});
		onUploadAttachment(dataFiles, uploadTo);
		// to allow same file to be able be chosen immediately next to this.
		e.target.value = '';
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragOver(true);
	};

	const handleDragLeave = () => {
		setIsDragOver(false);
	};

	return (
		<div
			className={classNames("file__upload--dropzone", { "dragged": isDragOver })}
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
		>
			{children}
		</div>
	);
};
