import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import HeaderFirstLink from './HeaderFirstLink'
import CustomLink from '../../containers/link'
import {
  HEADER_USER_STATUS,
  HEADER_USER_PROFILE,
  M_PREFERENCES,
  MM_MESSAGE
} from '../../common/v5/constants'
import { getNotificationIcon } from '../../common/v5/helpers'
import { useCallbackWithValue } from '../../hooks/callback'
import {
  composeWithDisplayName,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import Anchor from '../../reactcomponents/Anchor'
import { HotkeyLabel } from '../../reactcomponents/Hotkeys'

const ProfileInternalMessageBase = ({ count }) => (
  <div className='notices-list-item-icon'>
    <span data-notices-count={count} className='with-notices' />
    {getNotificationIcon('MsgInternalMessage')}
  </div>
)

const ProfileInternalMessage = composeWithDisplayName(
  'ProfileInternalMessage',
  withUnmountWhenHidden,
  memo
)(ProfileInternalMessageBase)

const Link = ({ onLinkChange, id, ...props }) => (
  <CustomLink
    onLinkChange={useCallbackWithValue(id, onLinkChange)}
    {...props}
  />
)

const AnchorLinkBase = ({ id, onClick, onLinkChange, to, ...props }) => {
  const noLink = id === M_PREFERENCES
  const LinkComponent = noLink ? Anchor : Link
  const otherProps = useMemo(() => {
    if (noLink) {
      return { onClick }
    }
    return { id, to, onLinkChange }
  }, [id, noLink, onClick, onLinkChange, to])
  return (
    <LinkComponent
      className=''
      data-qa-id={'agent_menu_' + id}
      {...otherProps}
      {...props}
    />
  )
}

const AnchorLink = memo(AnchorLinkBase)

class OneHeaderDropdownItem extends React.PureComponent {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    const { menuItem } = this.props
    const { label, id } = menuItem
    console.info(menuItem)
    if (id === "acw") { // not allowed to manually changed to ACW status by agent
      return
    }
    this.props.onClick(this.props.agentStatusById[id].label, label)
  }

  render () {
    const { menuItem } = this.props
    if (!menuItem) {
      return null
    }
    const { label, link, id } = menuItem
    if (this.props.id === HEADER_USER_STATUS) {
      const className = this.props.agentStatusById[id].className;
      return (
        <li
          className={className}
          data-qa-id={'agent_status_' + label}
          onClick={this.handleClick}
        >
          {label}
        </li>
      )
    } else if (this.props.id === HEADER_USER_PROFILE) {
      const {
        listIconClass,
        newIMCount,
        onClickPreferences,
        onLinkChange
      } = this.props;
      return (
        <li className='notices-list-item'>
          <AnchorLink
            id={id}
            onClick={onClickPreferences}
            onLinkChange={onLinkChange}
            to={link}
          >
            <div className='notices-list-item-icon'>
              <i className={listIconClass} />
            </div>
            <div className='notices-list-item-text'>
              <span className='user-link'>
                <HotkeyLabel
                  hotkey={menuItem.hotkey}
                  id={'menuItem-' + label}
                  text={label}
                />
              </span>
            </div>
            <ProfileInternalMessage
              count={newIMCount}
              hidden={id !== MM_MESSAGE || newIMCount <= 0}
            />
          </AnchorLink>
        </li>
      )
    } else {
      return null
    }
  }
}

const DivId = ({ id, onClick, opened, ...props }) => (
  <div
    data-qa-id={'QA_' + id}
    onClick={useCallbackWithValue(opened ? null : id, onClick)}
    {...props}
  />
)

class HeaderDropdown extends React.Component {
  componentDidMount () {
    if (this.props.id === HEADER_USER_STATUS) {
      this.props.onLoad()
    }
  }

  handleUserStatusClick (status) {
    this.props.onUpdateStatus(status)
  }

  renderDataSelector () {
    return (this.props.id === HEADER_USER_STATUS ? 'status' : 'myprofile')
  }

  renderHeaderDropdown () {
    const isUserStatus = this.props.id === HEADER_USER_STATUS
    let userDatas;
    if (isUserStatus) {
      userDatas = this.props.agentStatusList;
      if (!userDatas) {
        return (
          <div className={classNames({ 'jq-selectbox__dropdown': isUserStatus, 'header-dropdown': !isUserStatus })}>
            <ul></ul>
          </div>
        );
      }
    } else {
      const userData = this.props.data.filter(v => v.id === this.renderDataSelector()).map(({ sub }) => sub)
      userData.map(function (data, i) {
        userDatas = data
      })
    }
    return (
      <div className={classNames({ 'jq-selectbox__dropdown': isUserStatus, 'header-dropdown': !isUserStatus })}>
        <ul>
          { userDatas.map((menuItem, i) => (
              <OneHeaderDropdownItem
                key={i}
                id={this.props.id}
                listIconClass={this.props.listIconClass}
                menuItem={menuItem}
                newIMCount={this.props.newIMCount}
                onClickPreferences={this.props.onClickPreferences}
                onClick={this.props.onUpdateStatus}
                onLinkChange={this.props.onLinkChange}
                agentStatusById={this.props.agentStatusById}
              />
            ))
          }
        </ul>
      </div>
    )
  }

  render () {
    const {
      agentStatus,
      agentStatusByName,
      currentActiveAgent,
      id,
      onToggleArrow,
      uiData,
      withStatusChanger,
      embeddedWithMenu,
      menuOnly
    } = this.props
    const validAgentStatus = id === HEADER_USER_STATUS && agentStatus
    const opened = uiData.openHeaderArrow === id
    const statusClassName = validAgentStatus && agentStatusByName && agentStatusByName[agentStatus.status] ?
        agentStatusByName[agentStatus.status].className : "";
    return (
      <DivId
        id={id}
        className={classNames(
          { 'jq-selectbox dropdown': validAgentStatus },
          validAgentStatus ? statusClassName : false,
          { dropped: opened || menuOnly }
        )}
        onClick={onToggleArrow}
        opened={opened || menuOnly}
      >
        {
          !menuOnly &&
            <HeaderFirstLink
            id={id}
            agentStatusLabel={validAgentStatus ? agentStatus.label : null}
            currentActiveAgent={currentActiveAgent}
            uiData={uiData}
            withStatusChanger={withStatusChanger}
            embeddedWithMenu={embeddedWithMenu}
          />
        }
        {this.renderHeaderDropdown()}
      </DivId>
    )
  }
}

export default HeaderDropdown
