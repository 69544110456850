import React, { PureComponent } from 'react';
import { SelectBox } from '../../reactcomponents/SelectBox';
import {I} from '../../common/v5/config.js';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import { CollapsableMenu } from '../../reactcomponents/Form';
import { CustomInputRow, TableIconicButton as Button } from '../../reactcomponents/Form';
import { ActiveBorderHighlightArraySingleSelect as SingleSelect } from '../../reactcomponents/Dropdown';
import { TXT_NO_SELECTION } from '../../common/v5/receiptGreetingConstants';
class ExpiryText extends PureComponent {
	constructor(props) {
		super(props);
		this.expiryText = this.expiryText.bind(this);
	}
	expiryText(duration, timestamp){
		// TODO use a library to do this.
		if (timestamp == 0) {
			return I("Expired");
		}
		var secs = duration - ((new Date()).getTime()/1000 - timestamp);
		if (secs <= 0) {
			return I("Expired");
		}
		if (secs <= 60) {
			return I("Expiring soon");
		}
		if (secs <= 3600) {
			return I("Expiring in {N} minutes").replace('{N}', Math.round(secs/60));
		}
		var hours = secs / 3600;
		if (hours < 24) {
			return I("Expires in {N} hours").replace('{N}', Math.round(hours));
		}
		if (hours < 48) {
			return I("Expires tomorrow");
		}
		var days = hours / 24;
		return I("Expires in {N} days").replace('{N}', Math.round(days));
	}
	render(){
		return <span>{this.expiryText(this.props.duration, this.props.timestamp)}</span>
	}
}


export default class DataProtection extends React.Component {
	constructor(props){
		super(props);
		this.langs = [
			{ value: "en", label: I("English") },
			{ value: "fi", label: I("Finnish") },
			{ value: "nb", label: I("Norwegian") },
			{ value: "sv", label: I("Swedish") },
		];
		this.state = {
			expanded: false,
			checkedToForget: false,
			showTZOptions: false,
			showLangOptions: false
		}
		this.exportContacts = this.exportContacts.bind(this);
		this.revokeAccess = this.revokeAccess.bind(this);
		this.getExportHistory = this.getExportHistory.bind(this);
		this.isAnonymized = this.isAnonymized.bind(this);
		this.AnonymizeContacts = this.AnonymizeContacts.bind(this);
		this.getCurrentAnonymizeTask = this.getCurrentAnonymizeTask.bind(this);
		this.showPanel = this.showPanel.bind(this);
		this.handleCheckToForget = this.handleCheckToForget.bind(this);
		this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
		this.handleChangeTZ = this.handleChangeTZ.bind(this);
		this.handleToggleTZ = this.handleToggleTZ.bind(this);
		this.handleToggleLang = this.handleToggleLang.bind(this);
		this.handleGrantAccess = this.handleGrantAccess.bind(this);
	}
	componentDidMount(){
		this.getCurrentAnonymizeTask();
		if(cflag.IsActive("2023-06-01.CCC-5436.new.errand.contact.cards.gui")) {
			this.setState({expanded: true});
			this.getExportHistory(this.props.errandId, true);
		}
	}
	exportContacts(errandId){
		this.props.onExportContact(errandId, this.props.selectedLang, this.props.selectedTZ);
	}
	revokeAccess(errandId, secret){
		this.props.onRevokeAccess(errandId, secret);
	}
	getExportHistory(errandId, tz){
		this.props.onGetExportLog(errandId, tz);
	}
	isAnonymized(){
		return (this.props.anonymizeTaskId != 0 && this.props.anonymizeTaskStatus != 3);
	}
	AnonymizeContacts(){
		var ok = window.confirm(I("You are deleting all data and conversations with this person. If you proceed there is no way of reversing the action"));
		if(ok) {
			this.props.onDoAnonymize(this.props.errandId, this.props.timezone);
		}
	}
	getCurrentAnonymizeTask(){
		this.props.onGetAnonymize(this.props.errandId, true);
	}
	handleCheckToForget(){
		this.setState({checkedToForget: !this.state.checkedToForget});
	}
	handleChangeLanguage(lang){
		this.props.onSelectedLang(lang);
		this.setState({
			showLangOptions: false
		});
	}
	handleChangeTZ(tz){
		this.props.onSelectedTZ(tz);
		this.setState({
			showTZOptions: false
		});
	}
	handleToggleTZ(){
		this.setState({showTZOptions: !this.state.showTZOptions},
			() => {
				if(this.state.showTZOptions){
					this.setState({
						showLangOptions: false
					})
				}
			});
	}
	handleToggleLang(){
		this.setState({showLangOptions: !this.state.showLangOptions},
			() => {
				if(this.state.showLangOptions){
					this.setState({
						showTZOptions: false
					});
				}
			});
	}
	showPanel(e){
		if (!this.state.expanded) {
			this.getExportHistory(this.props.errandId, true);
		}
		this.setState({
			expanded:!this.state.expanded,
			checkedToForget: false
		});
		this.props.expand();
		e.preventDefault();
	}
	handleGrantAccess(e){
		if (this.props.exportPath=="") {
			this.exportContacts(this.props.errandId);
		} else {
			this.revokeAccess(this.props.errandId, this.props.secret);
		}
		e.preventDefault();
	}
	render(){
		const p = this.props;

		var that = this
		, panelBodyStyle = {
			display: 'none',
		}
		, history = []
		, i
		, log
		, text
		, logDiv
		;
		let classExpand = "";
		const newErrandCC = cflag.IsActive("2023-06-01.CCC-5436.new.errand.contact.cards.gui");
		if(this.state.expanded){
			classExpand = "expanded";
		}

		if (this.state.expanded) {
			panelBodyStyle.display = 'block';
			if(this.props.exportLogs){
				for(var i=0; i<this.props.exportLogs.length; i++) {
					log = this.props.exportLogs[i];
					switch (log.Action) {
					case "GRANT":
						text = I("Access granted by {AGENTNAME} at {DATE}");
						break;
					case "REVOKE":
						text = I("Access revoked by {AGENTNAME} at {DATE}");
						break;
					case "ANONYMIZE":
						text = I("Anonymized by {AGENTNAME} at {DATE}");
						break;
					}
					if (text) {
						text = text
							.replace('{AGENTNAME}', log.AgentName)
							.replace('{DATE}', log.TimestampStr)
							;

						history.push(<div key={i}>{text}</div>);
					}
				}
			}
			if (this.props.ready && history.length > 0) {
				logDiv = <div className="export-log">
					{this.props.exportPath==""
					? (this.props.working
						? <div>{I("Please wait...")}</div>
						: null)
					: <div className='current'>
						<a href={this.props.exportPath} target="_blank">
							{I("Customer data is available here.")}
						</a> <ExpiryText timestamp={this.props.timestampEnabled} duration={this.props.expirySecs}/>
					</div>
					}
					{history}
				</div>;
			}
		}
		//Language options
		var langOptions = [];
		langOptions.push({id: -1, name: I("Language")});
		for (var i=0;i<this.langs.length;i++) {
			langOptions.push({id: this.langs[i].value, name: this.langs[i].label});
		}
		var langSelect = <SelectBox
				id="dp_language"
				className="sidebar-nav-select"
				data-qa-id={"cc_dp_selectLang"}
				textNoItemSelected={I("Language")}
				visible={true}
				selected={this.props.selectedLang}
				onSelect={this.handleChangeLanguage}
				onToggleShow={this.handleToggleLang}
				show={this.state.showLangOptions}
				options={langOptions}
				disableOnClickOutside={true}
			/>;
		if(newErrandCC) {
			langSelect = <CustomInputRow
							id={"dp_language"}
							name={"dp_language"}
							className={"admin-select-option"}
						>
							<SingleSelect
								data={langOptions}
								invalidSelectedText={TXT_NO_SELECTION}
								textNoItemSelected={I("Select Language")}
								onSelect={this.handleChangeLanguage}
								selected={this.props.selectedLang}
								idFields={{ id: 'id', name: 'name' }}
								flip={false}
							/>
						</CustomInputRow>
		}

		//Timezone options
		var tzoptions = [];
		var tzs = this.props.timezone;
		if (tzs) {
			tzoptions.push({id: -1, name: I("Time Zone")});
			for (var i=0;i<tzs.length;i++) {
				tzoptions.push({id: tzs[i].Id, name: tzs[i].Name});

			}
			var timeZonesSelect = <SelectBox
				id="dp_timezones"
				className="sidebar-nav-select"
				data-qa-id={"cc_dp_selectTimezone"}
				textNoItemSelected={I("Time Zone")}
				visible={true}
				selected={this.props.selectedTZ}
				onSelect={this.handleChangeTZ}
				onToggleShow={this.handleToggleTZ}
				show={this.state.showTZOptions}
				options={tzoptions}
				disableOnClickOutside={true}
			/>;
			if(newErrandCC) {
				timeZonesSelect = <CustomInputRow
								id={"dp_timezones"}
								name={"dp_timezones"}
								className={"admin-select-option"}
							>
								<SingleSelect
									data={tzoptions}
									invalidSelectedText={TXT_NO_SELECTION}
									textNoItemSelected={I("Select Time Zone")}
									onSelect={this.handleChangeTZ}
									selected={this.props.selectedTZ}
									idFields={{ id: 'id', name: 'name' }}
									flip={false}
								/>
							</CustomInputRow>
			}
		}
		// Once anonymized and no exported link then disable Grant Export Data
		// regardless agent has the rights or not to anonymize data
		let enableGrantExportData = true;
		if (this.isAnonymized() && this.props.exportPath=="") {
			enableGrantExportData = false;
		}
		var exportContactHistoryAndLink;
		if (this.state.expanded) {
			if (this.props.ready) {
				if(this.props.dataPortabilityExport){
					if(newErrandCC){
						exportContactHistoryAndLink =
						<section className="data-portability__section">
							<div className='form-controls'>
								<h5 className="label-title">{I("Data Portability")}</h5>
								<div className='inputs-container'>
									{enableGrantExportData && this.props.exportPath == "" &&
										<div className='input-row'>
											<div className="label-block lang">{langSelect}</div>
											<div className="label-block time-zone">{timeZonesSelect}</div>
										</div>
									}
									<div className='action-row'>
										{enableGrantExportData &&
											<button
												className="btn-blue"
												onClick={this.handleGrantAccess}>
												{this.props.exportPath==""
												? I("Grant access")
												: I("Revoke access")
												}
											</button>
										}
									</div>
									{this.props.error && <div className="error">{this.props.error}</div>}
								</div>
							</div>
							{logDiv}
						</section>
					} else {
					exportContactHistoryAndLink = <div className="label-type">
					<form>
						<div className="label-row">
							{logDiv}
							{logDiv?<hr/>:null}
							{enableGrantExportData &&
							<div className="label-title">{I("Data Portability")}</div>
							}
							{enableGrantExportData && this.props.exportPath == "" &&
								<div>
									<div className="label-block lang">{langSelect}</div>
									<div className="label-block time-zone">{timeZonesSelect}</div>
								</div>
							}
						</div>
						<div className="label-row">
							{enableGrantExportData &&
							<button
								className="btn-blue"
								onClick={this.handleGrantAccess}>
								{this.props.exportPath==""
								? I("Grant access")
								: I("Revoke access")
								}
							</button>
							}
							{!this.props.error?null:
								<div className="error">
									{this.props.error}
								</div>
							}
						</div>
					</form>
				</div>
				}
				}
			} else {
				exportContactHistoryAndLink = I("Please wait...");
			}
		}

		return (newErrandCC ?
			<DataProtectionGUI
				agentAnonymize={p.agentAnonymize}
				anonymizeError={p.anonymizeError}
				checkedToForget={this.state.checkedToForget}
				dataPortabilityExport={p.dataPortabilityExport}
				dataPortabilityJSX={exportContactHistoryAndLink}
				isAnonymized={this.isAnonymized()}
				onCheckToForget={this.handleCheckToForget}
				onAnonymizeContacts={this.AnonymizeContacts}
			/>
			:
			<div className={"card-data-protection card-info " + classExpand} ref={"dataProtectionElem"}>
				<div className="add-information-title" onClick={this.showPanel}>
					{this.state.expanded?I("Data Privacy"):I("Data Privacy ...")}
				</div>
				{p.dataPortabilityExport &&
					<div className="data-portability" hidden={!this.state.expanded}>
						{exportContactHistoryAndLink}
					</div>
				}
				{(p.agentAnonymize && !this.isAnonymized()) &&
					<div className="data-anonymize" hidden={!this.state.expanded}>
						<div className="data-anonymize-checker">
							<label>
								{/* <input type="checkbox" checked={this.state.checkedToForget} onChange={this.handleCheckToForget}/> */}
								<SquareCheckbox onClick={this.handleCheckToForget}
									id="handle-forget"
									checked={this.state.checkedToForget}/>
								{I("Rights to be Forgotten")}
							</label>
							<div className="data-anonymize-btn">
							<button className="btn-red" data-qa-id={"gdpr_anonymize_btn"} onClick={this.AnonymizeContacts} disabled={(this.state.checkedToForget ? false : true)}>
								{I('Anonymize Contact')}
							</button>
						</div>
						</div>
						<div className="clear"></div>
						{!this.props.anonymizeError?null:
						<div className="error">
							{this.props.anonymizeError}
						</div>
						}
					</div>
				}
			</div>
			)
	}
}

const DataProtectionGUI = ({agentAnonymize,
	anonymizeError,
	checkedToForget,
	dataPortabilityExport,
	dataPortabilityJSX,
	isAnonymized,
	onCheckToForget,
	onAnonymizeContacts,
}) => {
	return (
		<div id="contactCardDataPrivacy" className={"card-data-protection card-info"}>
			{dataPortabilityExport &&
				<div className="data-portability" hidden={false}>{dataPortabilityJSX}</div>
			}
			{(agentAnonymize && !isAnonymized) &&
				<div className="data-anonymize" hidden={false}>
					{/*tk test */}
					<CollapsableMenu
						label={<h5 className='label-title'>{I("Data Anonymization")}</h5>}
						data-qa-id={"menu_gdpr_anonymize"}
						id={"contactCardAnonymizeMenu"}
						show={false}
					>
						<div className="data-anonymize__content">
							<div className='data-anonymize__checker'>
								<SquareCheckbox
									id={"handle-forget-1"}
									data-qa-id={"qa-rights-to-be-forgotten"}
									checked={checkedToForget}
									onClick={onCheckToForget}
									label={I("Rights to be Forgotten")}
									className={"v5-checkbox big"}
								/>
								<p>{I("All information and errands from this customer will be anonymized.")}</p>
							</div>
							<div className="data-anonymize__btn">
								<Button
									type="button"
									data-qa-id={"gdpr_anonymize_btn"}
									// className="btn-red"
									className={"btn " + (checkedToForget ? "btn-red" : "btn-outline") }
									onClick={onAnonymizeContacts}
									title={I('Anonymize Contact')}
									label={I('Anonymize Contact')}
									disabled={(checkedToForget ? false : true)}
								/>
							</div>
						</div>
					</CollapsableMenu>
					{anonymizeError && <div className="error">{anonymizeError}</div>}
				</div>
			}
		</div>
	)
}