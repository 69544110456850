import React, { memo, useCallback, useRef, useState } from 'react';
import { mapProps } from 'recompose';
import each from 'lodash/each';
import { useToggle } from '../../hooks/toggle';
import { composeWithDisplayName } from '../../reactcomponents/hocs';
import Dropdown, { newDropdownUI } from '../../reactcomponents/Dropdown';
import { truncateHtml } from '../../common/v5/helpers';

const englishLabel = 'en'

const languageOption = [
	{ id: 1, name: 'Arabic', label: 'ar' },
	{ id: 2, name: 'Bulgarian', label: 'bg' },
	{ id: 3, name: 'Catalan', label: 'ca' },
	{ id: 4, name: 'Chinese Simplified', label: 'zh-CHS' },
	{ id: 5, name: 'Chinese Traditional', label: 'zh-CHT' },
	{ id: 6, name: 'Czech', label: 'cs' },
	{ id: 7, name: 'Danish', label: 'da' },
	{ id: 8, name: 'Dutch', label: 'nl' },
	{ id: 9, name: 'English', label: englishLabel },
	{ id: 10, name: 'Estonian', label: 'et' },
	{ id: 11, name: 'Finnish', label: 'fi' },
	{ id: 12, name: 'French', label: 'fr' },
	{ id: 13, name: 'German', label: 'de' },
	{ id: 14, name: 'Greek', label: 'el' },
	{ id: 15, name: 'Haitian Creole', label: 'ht' },
	{ id: 16, name: 'Hebrew', label: 'he' },
	{ id: 17, name: 'Hindi', label: 'hi' },
	{ id: 18, name: 'Hmong Daw', label: 'mww' },
	{ id: 19, name: 'Hungarian', label: 'hu' },
	{ id: 20, name: 'Indonesian', label: 'id' },
	{ id: 21, name: 'Italian', label: 'it' },
	{ id: 22, name: 'Japanese', label: 'ja' },
	{ id: 23, name: 'Klingon', label: 'tlh' },
	{ id: 24, name: 'Klingon (pIqaD)', label: 'tlh-Qaak' },
	{ id: 25, name: 'Korean', label: 'ko' },
	{ id: 26, name: 'Latvian', label: 'lv' },
	{ id: 27, name: 'Lithuanian', label: 'lt' },
	{ id: 28, name: 'Malay', label: 'ms' },
	{ id: 29, name: 'Maltese', label: 'mt' },
	{ id: 30, name: 'Norwegian', label: 'no' },
	{ id: 31, name: 'Persian', label: 'fa' },
	{ id: 32, name: 'Polish', label: 'pl' },
	{ id: 33, name: 'Portuguese', label: 'pt' },
	{ id: 34, name: 'Romanian', label: 'ro' },
	{ id: 35, name: 'Russian', label: 'ru' },
	{ id: 36, name: 'Slovak', label: 'sk' },
	{ id: 37, name: 'Slovenian', label: 'sl' },
	{ id: 38, name: 'Spanish', label: 'es' },
	{ id: 39, name: 'Swedish', label: 'sv' },
	{ id: 40, name: 'Thai', label: 'th' },
	{ id: 41, name: 'Turkish', label: 'tr' },
	{ id: 42, name: 'Ukrainian', label: 'uk' },
	{ id: 43, name: 'Urdu', label: 'ur' },
	{ id: 44, name: 'Vietlabelse', label: 'vi' },
	{ id: 45, name: 'Welsh', label: 'cy' }
]

const languages = [
	{ id: 0, name: 'No Translation', label: '' },
	...languageOption
]

export function getLanguageName(l) {
	var name = "";
	languages.forEach(function(v, i){
		if(v['label'] && v["label"] == l){
			name = v['name']
		}
	});
	return name
}

export class TranslateNotes extends React.PureComponent {
	constructor(props) {
		super(props);
		this.showTranslation = this.showTranslation.bind(this);
		this.hideTranslation = this.hideTranslation.bind(this);
		this.state = {
			note: false,
			from: "en",
			translate: false,
			originalText: ""
		}
	}
	componentDidMount() {
		const p = this.props;
		if(p.type !== "CHAT"){
			p.onTranslation("detect", p.html, this.state.from)
			.then(from => {
				if(from) {
					if(p.interfaceLang === from) {
						if(this.refs.translationRef){
							this.setState({translate: false});
						}
					} else {
						if(this.refs.translationRef){
							this.setState({translate: true, from: from});
						}
					}
				}
			});
		}else{
			if(p.isAgent){
				if (p.toLang) {
					this.setState({originalText: p.html});
					return;
				}
				p.onTranslation("detect", p.html)
				.then(from => {
					if(from) {
						if (p.interfaceLang !== from) {
							p.onTranslation("translate", p.html, from)
							.then(html => {
								if(html) {
									this.setState({originalText: html[0], note: true});
								}
							});
						} else {
							this.setState({originalText: p.html});
						}
					}
				});
			}
		}
	}
	showTranslation() {
		const p = this.props;
		if(p.type !== "CHAT"){
			p.onTranslation("translate", truncateHtml(p.html, 1000), this.state.from)
			.then(html => {
				if(html) {
					p.translateData(html[0]);
					this.setState({note: true});
				}
			});
		}else{
			if(p.isAgent && p.fromLang){
				p.onTranslation("reverseTranslation", this.state.originalText, p.fromLang, p.toLang)
				.then(html => {
					if(html) {
						p.translateData(html[0]);
						this.setState({note: true});
					}
				});
			}else{
				let custLang = p.toLang;
				if(custLang){
					p.onTranslation("translate", p.html, custLang, p.fromLang)
					.then(html => {
						if(html) {
							p.translateData(html[0]);
							this.setState({note: true});
						}
					});
				}
			}
		}
	}
	hideTranslation() {
		if(this.props.type === "CHAT"){
			if(this.props.isAgent){
				this.props.translateData(this.state.originalText);
			}else{
				this.props.translateData("");
			}
		}else{
			this.props.translateData("");
		}
		this.setState({note: false});
	}
	render() {
		const p = this.props;
		const s = this.state;
		let enableTranslation = false;
		let option = "";
		let lang = "";

		if(p.type === "CHAT"){
			enableTranslation = (p.toLang || p.fromLang) ? true : false;
			if(p.isAgent){
				if(p.fromLang){
					lang = getLanguageName(p.fromLang);
				}
			}else{
				lang = getLanguageName(p.toLang);
			}
		}else{
			lang = getLanguageName(s.from);
			enableTranslation = s.translate;
		}

		if(p.type === "CHAT") {
			if(p.toLang !== "" && p.fromLang !== "") {
				if(p.toLang !== p.fromLang) {
					if(s.note){
						option = <span><a onClick={this.hideTranslation}>
							<span>{I("Translated from")} {lang}, </span>{I("see original")}</a></span>
					} else {
						option = <span><a onClick={this.showTranslation}>{I("See translation")}</a></span>
					}
				}
			}
		} else {
			if(s.note){
				option = <span><a onClick={this.hideTranslation}>
					<span>{I("Translated from")} {lang}, </span>{I("see original")}</a></span>
			} else {
				option = <span><a onClick={this.showTranslation}>{I("See translation")}</a></span>
			}
		}
		return (
			<div className="errand-message-sign" ref={"translationRef"} hidden={!enableTranslation}>
				{option}
			</div>
		);
	}
}

const getLanguageId = l => {
	if (!l) {
		return ''
	}
	let index = ''
	each(languageOption, ({ label }, i) => {
		if (label == l) {
			index = i
			return false
		}
	})
	return index
}

const isInitializedSelection = selected => !selected && selected !== 0

const Translation = ({ className, disabled, id, language, onSelect, text }) => {
	const [show, onToggle] = useToggle()
	return (
		<Dropdown
			className={className}
			data={languageOption}
			headerText={text}
			id={id}
			isInitializedSelection={isInitializedSelection}
			onReset={useCallback(() => onSelect(''), [onSelect])}
			onSelect={useCallback(
				index => onSelect(languageOption[index].label),
				[onSelect]
			)}
			onToggle={onToggle}
			readOnly={disabled}
			selected={getLanguageId(language)}
			show={show}
			textNoItemSelected={text}
		/>
	)
}

const NewErrandTranslation = composeWithDisplayName(
	'NewErrandTranslation',
	memo,
	mapProps(({
		onSelectedToLang,
		toLang,
		...props
	}) => ({
		id: 'QA_errandTranslate',
		language: toLang,
		onSelect: onSelectedToLang,
		...props
	}))
)(Translation)

const NewErrandTranslationFrom = composeWithDisplayName(
	'NewErrandTranslationFrom',
	memo,
	mapProps(({
		fromLang,
		onSelectedFromLang,
		...props
	}) => ({
		id: 'QA_errandTranslateFrom',
		language: fromLang,
		onSelect: onSelectedFromLang,
		...props
	}))
)(Translation)

// OBSOLETE: do not update this
class OldErrandTranslation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showTo: false,
		};
		this.handleToggleTo = this.handleToggleTo.bind(this);
		this.handleSelectTo = this.handleSelectTo.bind(this);
	}
	handleToggleTo(e) {
		this.setState({showTo: !this.state.showTo})
	}
	handleSelectTo(id) {
		this.props.onSelectedToLang(this.getLanguageLabel(id));
	}
	getLanguageLabel(id) {
		var text = "en";
		languages.forEach(function(v, i){
			if(v["id"] == id){
				text = v['label'];
			}
		});
		return text;
	}
	getLanguageId(l) {
		var id = "";
		languages.forEach(function(v, i){
			if(v["label"] && v["label"] == l){
				id = v['id'];
			}
		});
		return id;
	}
	render() {
		return (
			<Dropdown id={"QA_errandTranslate"}
				className={this.props.className}
				show={this.state.showTo}
				onToggle={this.handleToggleTo}
				selected={this.getLanguageId(this.props.toLang)}
				data={languages}
				onSelect={this.handleSelectTo}
				textNoItemSelected={this.props.text}
				readOnly={this.props.disabled} />
		);
	}
}

// OBSOLETE: do not update this
class OldErrandTranslationFrom extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showFrom: false,
		};
		this.handleToggleFrom = this.handleToggleFrom.bind(this);
		this.handleSelectFrom = this.handleSelectFrom.bind(this);
	}
	handleToggleFrom(e) {
		this.setState({showFrom: !this.state.showFrom})
	}
	handleSelectFrom(id) {
		this.props.onSelectedFromLang(this.getLanguageLabel(id));
	}
	getLanguageLabel(id) {
		var text = "en";
		languages.forEach(function(v, i){
			if(v["id"] == id){
				text = v['label'];
			}
		});
		return text;
	}
	getLanguageId(l) {
		var id = "";
		languages.forEach(function(v, i){
			if(v["label"] && v["label"] == l){
				id = v['id'];
			}
		});
		return id;
	}
	render() {
		return (
			<Dropdown id={"QA_errandTranslateFrom"}
				className={this.props.className}
				show={this.state.showFrom}
				onToggle={this.handleToggleFrom}
				selected={this.getLanguageId(this.props.fromLang)}
				data={languages}
				onSelect={this.handleSelectFrom}
				textNoItemSelected={this.props.text}
				readOnly={this.props.disabled} />
		);
	}
}

const ErrandTranslation = newDropdownUI ? NewErrandTranslation : OldErrandTranslation

export default ErrandTranslation

export const ErrandTranslationFrom = newDropdownUI ? NewErrandTranslationFrom : OldErrandTranslationFrom
