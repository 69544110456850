import React, { useState, useEffect } from 'react';
import { ModalPopup } from '../../reactcomponents/Modal';
import FileViewer from 'react-file-viewer';
import Markdown from 'react-markdown';
import { I } from '../../common/v5/config';

export default class FilePreview extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "FilePreview";
		this.state = {
			textContent: ""
		}
		this.closePreview = this.closePreview.bind(this);
	}
	closePreview(){
		if(this.props.onClose){
			this.props.onClose();
		}
	}
	render(){
		let previewId, previewSrc;
		if(this.props.type === "img" && this.props.src){
			previewId = "imagePreview";
			previewSrc = <div className="file-preview" key="imagePreview"><img src={this.props.src} /></div>
		}else if(this.props.type === "txt" && this.props.src){
			previewId = "textPreview";
			previewSrc = this.props.textFilePreview;
		}else if(this.props.type === "pdf" && this.props.src){
			previewId = "pdfPreview";
			previewSrc = I("Preview not available.");
		}else if(this.props.type === "csv" && this.props.src){
			previewId = "csvPreview";
			previewSrc = <div dangerouslySetInnerHTML={{__html: this.props.csvFilePreview}}></div>;
		}else {
			previewId = "otherPreview";
			previewSrc = I("Preview not available.");
		}
		return(
				<ModalPopup
					viewAs="file-preview"
					id={previewId}
					show={this.props.open}
					dataSrc={previewSrc}
					isHeader={true}
					headerText= {
						<div>
							<span>{I("Preview")+" - "+this.props.fileName}</span>
							<span className="modal-header-link file-download">
								<a href={this.props.downloadSrc} target="_blank" className="file-download">
									<i className="fa fa-download"></i> {I("Download")}
								</a>
							</span>
						</div>
					}
					isFooter={false}
					onToggle={this.closePreview}
				/>
			)
	}
}

const DownloadButton = ({ downloadSrc, centered}) => (
	<div className={"file-actions " + (centered ? "centered" : "top")}>
		<a className="btn btn-text blue-hover" href={downloadSrc} target="_blank" rel="noopener noreferrer">
			<i className="icon-download"></i>
			<span className='text'>{I("Download file")}</span>
		</a>
	</div>
);

export const FilePreviewV5 = ({
	src
	, type
	, textFilePreview
	, csvFilePreview
	, fileName
	, downloadSrc
	, downloadPosition = "bottom"
}) => {
	let previewId, previewSrc;

	switch (type) {
		case "img":
			if (src) {
				previewId = "imagePreview";
				previewSrc = <div className="file-preview" key="imagePreview"><img src={src} /></div>;
				// optional way: can use lazy load for smother image rendering, BUT it'll be slower
				// previewSrc = <LazyLoadImage title={fileName} src={src} />
			}
			break;
		case "txt":
			if (src) {
				previewId = "textPreview";
				previewSrc = <TextFilePreview file={downloadSrc} />;
			}
			break;
		case "pdf":
			if (src) {
				previewId = "pdfPreview";
				previewSrc = <DocPreview file={downloadSrc} type="pdf" />;
			}
			break;
		case "csv":
			if (src) {
				previewId = "csvPreview";
				previewSrc = <DocPreview file={downloadSrc} type="csv" />;
			}
			break;
		case "docx":
			if (src) {
				previewId = "wordPreview";
				previewSrc = <DocPreview file={downloadSrc} type="docx" />;
			}
			break;
		case "xlsx":
			if (src) {
				previewId = "excelPreview";
				previewSrc = <DocPreview file={downloadSrc} type="xlsx" />;
			}
			break;
		case "md":
			if (src) {
				previewId = "mdPreview";
				previewSrc = <MDPreview file={downloadSrc} />;
			}
			break;
		case "json":
			if (src) {
				previewId = "jsonPreview";
				previewSrc = <JSONPreview file={downloadSrc} />;
			}
			break;
		default:
			previewId = "otherPreview";
			previewSrc = <div className='file-preview'>{I("Preview not available.")}</div>;
	}
	return (
		<div className='preview-container file-preview-v5'>
			{downloadPosition === "top" && <DownloadButton downloadSrc={downloadSrc} centered={false} /> }
			{previewSrc}
			{downloadPosition === "bottom" && <DownloadButton downloadSrc={downloadSrc} />}
		</div>
	)
}

const JSONPreview = (props) => {
	const [json, setJson] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const jsonUrl = props.file;

		fetch(jsonUrl)
			.then((response) => response.json())
			.then((data) => {
				setJson(JSON.stringify(data, null, 2));
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching JSON:', error);
				setJson(I("Error fetching JSON"));
				setIsLoading(false);
			});
	}, []);

	return (
		<div>
			{isLoading ? <div>{I("Loading...")}</div> : <pre>{json}</pre>}
		</div>
	);
};

const DocPreview = (props) => {
	return (
		<FileViewer
			fileType={props.type}
			filePath={props.file}
			errorComponent={<div>{I("Error loading document")}</div>}
			onError={(e) => console.log(e)}
		/>
	)
}

const MDPreview = (props) => {
	const [markdown, setMarkdown] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const markdownUrl = props.file;

		fetch(markdownUrl)
			.then((response) => response.text())
			.then((text) => {
				setMarkdown(text);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching text:', error);
				setMarkdown(I("Error loading text"));
				setIsLoading(false);
			});
	}, []);

	return (
		<div>
			{isLoading ? <div>{I("Loading...")}</div> : <Markdown>{markdown}</Markdown>}
		</div>
	);
};

const TextFilePreview = (props) => {
	const [text, setText] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const textUrl = props.file;

		fetch(textUrl)
			.then((response) => response.text())
			.then((text) => {
				setText(text);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching text:', error);
				setText(I("Error loading text"));
				setIsLoading(false);
			});
	}
	, []);

	return (
		<div>
			{isLoading ? <div>{I("Loading...")}</div> : <pre>{text}</pre>}
		</div>
	);
}
