import React, { useState, useEffect, useMemo } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import styled from '@emotion/styled'
import { ExpandableBox, NumberSpan } from "./LiveReport";
import Button from '../../reactcomponents/Button';
import { centionBlue, centionLighterGrey } from '../../styles/_variables';
import _ from "lodash";
import PopupPage from '../../reactcomponents/PopupPage';
import { DeletePopup } from '../../reactcomponents/Dialog';

const StyledLayoutSelection = styled.div`
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	padding: 12px 16px;
	z-index: 1;
	right: 80px;
`

const greyBtnStyle = {
	borderColor: centionBlue,
	marginRight: '10px',
	color: centionBlue
}
const blueBtnStyle = {
	borderColor: centionBlue,
	marginRight: '10px',
	color: '#FFF',
	fontSize: '12px'
}

function LayoutOpt({id, icon, onClick}) {
	return (
		<li style={{cursor: "pointer"}} onClick={onClick}> <i className={icon} />{I("Layout")} {id}</li>
	)
}

const Launchpad = ({
	className = "layout",
	defaultLaunchpadWidgets,
	launchpadLayout,
	launchpadGridLayout,
	launchpadWidgets,
	items = 4,
	isResizable = false,
	breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
	onSetCurrentErrand,
	onSaveWidgetPreference,
	dueErrands,
	forwardedErrands,
	incomingCollabs,
	expiringErrands,
	onChangeLayout,
	onSaveChangeLayout,
	onAddWidgetGUI,
	onDeleteWidgetGUI,
	collapseSideBar,
	...props
}) => {
	const LAYOUT_ROW_HEIGHT = 150;
	const LAYOUT_ROW_HEIGHT_EDIT = 200;
	const LAYOUT_ROW_HEIGHT_WIDGET_EDIT = 250;

	const [showEdits, setShowEdits] = useState(false);
	const [boxWidth, setBoxWidth] = useState('80vw');
	const [rowHeight, setRowHeight] = useState(LAYOUT_ROW_HEIGHT);
	const [showLayoutOption , setShowLayoutOption ] = useState(false);
	const [expandAll, setExpandAll] = useState(false);
	const [layoutChanged, setLayoutChanged] = useState(false);
	const [showAddWidget, setShowAddWidget] = useState(false);
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [toDeleteId , setToDeleteId] = useState("");
	const [toDeleteWidgetList, setToDeleteWidgetList] = useState([]);
	const [toBeAddedWidget, setToBeAddedWidget] = useState([]);

	const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

	const handleEditWidget = () => {
		setShowEdits(true);
		setExpandAll(true);
	};

	const handleAddWidget = () => {
		setShowAddWidget(true);
	};

	const handleAdd = (widget) => {
		onAddWidgetGUI(widget);
		setToBeAddedWidget([...toBeAddedWidget, widget]);
		setShowAddWidget(false);
	}

	const handleCloseAddWidget = () => {
		setShowAddWidget(false);
	}

	const handleDeleteWidget = (id) => {
		setShowDeleteAlert(true);
		setToDeleteId(id);
		setToDeleteWidgetList([...toDeleteWidgetList, id]);
	}

	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
		const newToDeleteWidgetList = [...toDeleteWidgetList];
		newToDeleteWidgetList.pop();
		setToDeleteWidgetList(newToDeleteWidgetList);
		setToDeleteId("");
	}

	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		if(typeof onDeleteWidgetGUI === 'function') {
			onDeleteWidgetGUI((toDeleteId));
		}
		setToDeleteId("");
	}

	const handleChangeLayout = () => {
		setShowLayoutOption(true);
	};

	const handleSaveLayout = () => {
		setShowEdits(false);
		setExpandAll(false);

		const newWidgets = [];
		for(let j = 0; j < launchpadWidgets.length; j++) {
			const launchpadWidget = launchpadWidgets[j];
			if(launchpadWidget.new) {
				launchpadWidget.id = 0;
				launchpadWidget.new = false;
				newWidgets.push(launchpadWidget);
			}
		}
		onSaveChangeLayout(launchpadLayout, launchpadGridLayout, true, toDeleteWidgetList, newWidgets);
		setToDeleteWidgetList([]);
		setToBeAddedWidget([]);
	};

	const handleLayoutChange = (grid) => {
		if(layoutChanged) {
			const newLayout = {};
			newLayout.lg = grid;
			newLayout.md = grid;

			onSaveChangeLayout(launchpadLayout, newLayout, false)
		}
	};

	//TODO @sue, this state will be use to determine height of specific widget on edit mode
	const [showWidgetEdit, setShowWidgetEdit] = useState([]); //store id of widgets that on edit mode
	const handleActivateEdit = (activated, id) => {
		if(activated) {
			setShowWidgetEdit([...showWidgetEdit, id]);
		} else {
			const index = showWidgetEdit.indexOf(id);
			if (index > -1) {
				showWidgetEdit.splice(index, 1);
			}
			setShowWidgetEdit([...showWidgetEdit]);
		}
	}

	const generateLayout = (widgets, layoutType) => {
		const newLayout = [];
		for (let i = 0; i < widgets.length; i++) {
			const widget = widgets[i];
			const newWidget = {
				i: widget.id.toString(),
				y: Math.floor(i / layoutType),
				h: 2,
			};

			if (layoutType === 1) {
				newWidget.x = 0;
				newWidget.w = 12;
			} else if (layoutType === 2) {
				newWidget.x = i % 2 === 0 ? 0 : 6;
				newWidget.w = 5;
			} else if (layoutType === 3) {
				newWidget.x = i % 3 === 0 ? 0 : i % 3 === 1 ? 4 : 8;
				newWidget.w = 4;
			}

			newLayout.push(newWidget);
		}
		return newLayout;
	}

	const setLayoutsFunc = (layoutType) => {
		const newLayout = generateLayout(launchpadWidgets, layoutType);
		const grid = {};
		grid.md = newLayout;
		grid.lg = newLayout;
		return grid;
	};

	const setWidth = () => {
		let boxW = boxWidth;
		if(launchpadLayout === 1) {
			boxW = collapseSideBar ? '80vw' : '72vw';
		} else if (launchpadLayout === 2) {
			boxW = collapseSideBar ? '35vw' : '30vw';
		} else if (launchpadLayout === 3) {
			boxW = collapseSideBar ? '24vw' : '22vw';
		}
		setBoxWidth(boxW);
	}

	const setHeight = () => {
		let rowH = rowHeight;
		rowH = LAYOUT_ROW_HEIGHT;
		if(showEdits) {
			rowH = LAYOUT_ROW_HEIGHT_EDIT;
			if(showWidgetEdit.length > 0) {
				rowH = LAYOUT_ROW_HEIGHT_WIDGET_EDIT;
			}
		}
		setRowHeight(rowH);
	}

	useEffect(() => {
		setWidth();
	}, [collapseSideBar]);

	useEffect(() => {
		if(showEdits) {
			setRowHeight(LAYOUT_ROW_HEIGHT_EDIT);
		}
	}, [showEdits]);

	useEffect(() => {
		if(showWidgetEdit.length > 0) {
			setRowHeight(LAYOUT_ROW_HEIGHT_WIDGET_EDIT);
		} else {
			if(showEdits) {
				setRowHeight(LAYOUT_ROW_HEIGHT_EDIT);
			} else {
				setRowHeight(LAYOUT_ROW_HEIGHT);
			}
		}
	},[showWidgetEdit]);

	const selectLayout = (layout) => {
		const grid = setLayoutsFunc(layout);
		onSaveChangeLayout(layout, grid, false);
		setShowLayoutOption(false);
	};

	const handleHeightResize = (height) => {
		//console.log("Dbg sue: height: changed ", height);
	};

	useEffect(() => {
		if (props.onLoad) {
			props.onLoad();
			setWidth();
		}
		return () => {
			if (props.onUnload)	{
				props.onUnload();
			}
		};
	}, []);

	const { name, chooseString } = props;
	const layoutList = [
		{ id: 1, icon: "icon-layout-single" },
		{ id: 2, icon: "icon-new-layout-vertical" },
		{ id: 3, icon: "icon-layout-triple" }
	]
	let layoutOpt = [];
	for (let i = 0; i < layoutList.length; i++) {
		const layout = layoutList[i];
		layoutOpt.push(<LayoutOpt id={layout.id} icon={layout.icon} key={"layoutOpt" + i} onClick={() => selectLayout(layout.id)} />);
	}
	const dueLength = dueErrands.data.length, forwardedLength = forwardedErrands.data.length, collabsLength = incomingCollabs.data.length, expiredLength = expiringErrands.data.length;
	const isDraggable = showEdits ? true : false;

	const handleCollapse = (id, toggle) => {
		//console.log("Dbg sue: handleCollapse " , id, toggle);
	};

	const handleRowClick = (id, threadId, queryId) => {
		if( typeof onSetCurrentErrand === 'function' ){
			onSetCurrentErrand(id, threadId, queryId);
		}
	};

	//Processing the widgets based on preferences
	const getDataSet = (id) => {
		if(id === 1){
			return dueErrands;
		}else if(id === 2){
			return incomingCollabs;
		}else if(id === 3){
			return forwardedErrands;
		}else if(id === 4){
			return expiringErrands;
		}
	}

	const getWidgetTotal = (id) => {
		let total = 0;
		if(id === 1){
			total = dueLength;
		}else if(id === 2){
			total = collabsLength;
		}else if(id === 3){
			total = forwardedLength;
		}else if(id === 4){
			total = expiredLength;
		}
		return <NumberSpan>{total}</NumberSpan>;
	}

	const handleSave = (data) => {
		onSaveWidgetPreference(data);
	}

	const handleUpdateGrid = (grid) => {
		const newGrid = {};
		newGrid.md = grid;
		newGrid.lg = grid;
		onSaveChangeLayout(launchpadLayout, newGrid, false);
	}

	const handleDrag = (layout, oldItem, newItem, placeholder, e, element) => {
		if(launchpadLayout === 1) {
			placeholder.w = 12;
		} else if (launchpadLayout === 2) {
			placeholder.w = 5;
		} else if (launchpadLayout === 3) {
			placeholder.w = 4;
		}
	}

	const handleDragStop = (grid) => {
		handleUpdateGrid(grid);
	}

	useEffect(() => {
		setWidth();
		setHeight();
	}, [launchpadLayout, launchpadGridLayout]);

	const launchpadWidgetTypes = [];
	for(let i = 0; i < defaultLaunchpadWidgets.length; i++) {
		const widget = defaultLaunchpadWidgets[i];
		launchpadWidgetTypes.push({id: widget.id, name: widget.label});
	}

	const formWidgets = () => {
		const renderWidgets = launchpadWidgets.map((widget, i) => (
			<div key={widget.id != 0 ? widget.id : widget.id+'-'+i} style={{ width: boxWidth, background: 'transparent' }}>
				<ExpandableBox
					id={widget.id.toString()}
					total={getWidgetTotal(widget.dataType)}
					title={widget.title}
					dataType={widget.dataType}
					maxResults={widget.maxResults}
					showCols={widget.showColumns}
					showTotal={widget.showTotal}
					refreshInterval={widget.refreshInterval}
					position={widget.position}
					data={getDataSet(widget.dataType)}
					type="LIST"
					width={boxWidth}
					isDraggable={isDraggable}
					editMode={showEdits}
					onRowClick={handleRowClick}
					onHeightChange={handleHeightResize}
					onCollapse={handleCollapse}
					onActivateEdit={handleActivateEdit}
					onSave={handleSave}
					widgetTypes={launchpadWidgetTypes}
					autoExpand={expandAll}
					onDelete={handleDeleteWidget}
				/>
			</div>
		));
		return renderWidgets;
	}

	return (
		<div style={{ background: centionLighterGrey, overflow: "auto" }}>
			<div className="errand-ready-text">
				{name}
				<h2 className="book">{chooseString}</h2>
			</div>
			<div className="launchpad launchpad-wrapper">
				<div style={{ float: "right", marginRight: '20px' }}>
					{!showEdits &&
						<Button key="btn-edit-widget" color="white" style={greyBtnStyle} text={I("Edit")} className="add-button" onClick={handleEditWidget} />
					}
					<div hidden={!showEdits}>
						<Button key="btn-add-widget" color="white-transparent" style={greyBtnStyle} text={I("Add widget")} className="add-button" onClick={handleAddWidget} />
						<Button key="btn-change-layout" color="white-transparent" style={greyBtnStyle} text={I("Change layout")} className="change-button" onClick={handleChangeLayout} />
						<StyledLayoutSelection hidden={!showLayoutOption}>
							<ul style={{ listStyle: "none" }}>
								{layoutOpt}
							</ul>
						</StyledLayoutSelection>
						<Button key="btn-save-layout" color="blue" style={blueBtnStyle} text={I("Save")} className="save-button" onClick={handleSaveLayout} />
					</div>

				</div>
				<div style={{ overflow: 'auto', width: '100%' }}>
					<ResponsiveGridLayout className="layout" isDraggable={isDraggable}
						onLayoutChange={handleLayoutChange}
						layouts={launchpadGridLayout}
						isResizable={false}
						breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
						style={{ height: '100vh' }}
						rowHeight={rowHeight}
						margin={[10, 10]}
						containerPadding={[10, 10]}
						preventCollision={true}
						draggableHandle=".launchpad-drag-handle"
						onDrag={handleDrag}
						onDragStop={handleDragStop}
					>
						{formWidgets()}
					</ResponsiveGridLayout>
				</div>
			</div>
			<AddWidgetPopup msg={I("Add widget")} show={showAddWidget} onClose={handleCloseAddWidget}>
				<WidgetOptions options={defaultLaunchpadWidgets} onAdd={handleAdd}/>
			</AddWidgetPopup>
			<DeletePopup
				title={I("Are you sure you want to delete this widget?")}
				msg={I("This process cannot be undone.")}
				icon={'icon-caution'}
				show={showDeleteAlert}
				onDelete={handleConfirmDelete}
				onClose={handleCancelDelete}
			/>
		</div>
	)
};

export const AddWidgetPopup = ({ msg, show, onClose, children }) => {
	return <PopupPage
		data-qa-id="launchpad-option-backdrop-popup"
		extraClass="launchpad-option-backdrop"
		innerClass="launchpad-option"
		show={show}
		onClose={onClose}
	>	<div className="popup-title">
			<h2><i className="icon-add"></i>{msg}</h2>
		</div>
		<div>
			{children}
		</div>
	</PopupPage>
}

const WidgetOptions = ({ options, onAdd }) => {
	return (
		<div className="widget-options" style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
			{options.map((item, index) => {
				return (
					<div key={index} className="widget-option"  style={{display: 'flex', alignItems: 'flex-start', padding: '5px 10px'}}>
						<div className="widget-options-content" style={{display: 'flex', flex: '1', flexDirection: 'column', margin: '0px 10px 0px 10px'}}>
							<div className="widget-option-title" style={{marginBottom: '5px'}}>
								<h3>{item.label}</h3>
							</div>
							<div className="widget-option-description" style={{marginBottom: '10px'}}>
								<p>{item.description}</p>
							</div>
						</div>
						<div className="widget-option-button" style={{padding: '5px 10px'}}>
							<Button key="btn-add-widget" color="blue" style={blueBtnStyle} text={I("Add")} className="add-button" onClick={() => onAdd(item.id)} />
						</div>
					</div>
				)
			})}
		</div>
	)
}


export default Launchpad;
