import React from 'react';

class ErrandsReport extends React.Component {
	constructor(props){
		super(props);
	}
	render(){
		const p = this.props;
		return(
			<div className="app-inner-content">
				{"TODO: Errands Report"}
			</div>
		);
	}
};

export default ErrandsReport;