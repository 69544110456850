import React from 'react';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import { timeSpanFormatListSelector } from '../../redux/selectors/statistics';

class HeaderFirstLink extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		e.preventDefault();
	}
	render() {
		let className;
		if (this.props.noticeCount) {
			className = 'with-notices ';
		}
		if (this.props.id == 'headerNotification') {
			if(!this.props.forSidebar) {
				return (
					<div>
						<a
							href="#"
							onClick={this.handleClick}
							className={className}
							data-notices-count={this.props.noticeCount}
							data-qa-id="header-notif"
							data-dropdown-target="notices-dropdown">
							<i className="icon-bell"></i>
						</a>
						{this.props.title}
					</div>
				)
			} else {
				return null;
			}
		} else if (this.props.id == 'headerUserStatus') {
			let isTelavox = typeof externalqueue !== "undefined" &&
				externalqueue.telavox == true;
			if(isTelavox !== true) {
				if(this.props.embeddedWithMenu) {
					return (
						<div className="userStatusLink">
							<span style={{marginRight: "10px"}}>{I("Status:")}</span>
							<div className="jq-selectbox__select-text embedded">
								{this.props.agentStatusLabel}
							</div>
							{!this.props.uiData.wsConnected
							? <span style={{color: 'red'}} title={I('Connecting ...')}>
								<i className="fa fa-refresh fa-spin"/>
							</span>
							: null
							}
							<div className="jq-selectbox__trigger">
								<div className="jq-selectbox__trigger-arrow"></div>
							</div>
						</div>
					)
				} else {
					return (
						<div className="userStatusLink">
							{!this.props.uiData.wsConnected
							? <span style={{color: 'red'}} title={I('Connecting ...')}>
								<i className="fa fa-refresh fa-spin"/>
							</span>
							: null
							}
							<div className="jq-selectbox__select-text">
								{this.props.agentStatusLabel}
							</div>
							<div className="jq-selectbox__trigger">
								<div className="jq-selectbox__trigger-arrow"></div>
							</div>
						</div>
					)
				}
			} else {
				return null;
			}
		} else {
			return (
				<a
					href="#"
					onClick={this.handleClick}
					title={this.props.currentActiveAgent.name}
					data-dropdown-target="userprofile-dropdown">
					<span className="user-name">{this.props.currentActiveAgent.name}</span>
					<span className="user-photo">
						<ProfilePhoto photo={this.props.currentActiveAgent.photo}
							width={"30"} isAgent={true}
							className={"user-photo"} />
					</span>
				</a>
			)
		}
	}
}

export default HeaderFirstLink
