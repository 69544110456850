import React, { useEffect } from 'react';
import classNames from 'classnames';
import MenuCtnr from '../../common/v5/menuCtnr';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import { SidebarSettingsCtnr } from '../../views/v5/workflowCtnr';
import { isValidTelavoxUrl } from '../../common/v5/helpers';
import {
	MM_EXPORT_CALL,
	CENTION_LOGO_MINI,
	CENTION_LOGO
} from '../../common/v5/constants';
import { WorkflowBreadcrumbsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr } from '../../views/v5/workflowCtnr';
import { CancellableSimpleLink } from '../../containers/link';
import { EXPORTS_CALL as EXPORTS_CALL_LINK, EXPORTS as EXPORT_LINK } from '../../common/path';
import ExportCallList from './ExportCallContent';

const ExportCalls = ({
	activeId
	, view
	, input
	, baseUi
	, ui
	, activateChat
	, onCollapseSideBar
	, onToggleSideBar
	, onMainView
	, onChangeView
	, onChangePage
	, list
	, areas
	, allAreas
	, timezone
	, channel
	, fileType
	, runInterval
	, searchInterval
	, columnsToDisplay
	, advanceCallFilters
	, onLoad
	, onLoadToEdit
	, onDeleteFromList
	, onCancel
	, onRunCallExport
	, onPreviewCallExport
	, onCloseExportPreview
	, onClosePreviewCallExport
	, onChangeInput
	, onSave
	, onCloseForm
	, onTextInputChange
	, onClickToggleEnable
	, onSetValue
	, onCdrExportDownload
	, simpleLoadAndOpen
	, saveStatus
	, ...props
}) => {
	useEffect(() => {
		window.addEventListener('message', handleMessageEvent);
		return () => {
			window.removeEventListener('message', handleMessageEvent);
		};
	}, [props]);

	function handleMessageEvent(e) {
		if(typeof e.data === "object"){
			if(typeof e.data.action !== 'undefined' &&
				e.data.action !== null){
				if(e.data.action == "open-errand"){
					let urlValid = isValidTelavoxUrl(e.origin);
					if(typeof e.data.params !== 'undefined' &&
						e.data.params !== null && urlValid == true){
						externalqueue.isPostMessage = true;
						if(typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== ""){
								let encoded = e.data.params.deepLink;
								simpleLoadAndOpen(encoded, 0);
						}
					}
				} else {
					console.info("unhandled messsage action:", e.data.action);
				}
			}
		}
	}

	function handleSideBarToggle() {
		onToggleSideBar(!baseUi.showSideBar);
	}

	function handleSideBarCollapse() {
		onCollapseSideBar(!baseUi.collapseSideBar);
	}

	function handleHomePage(e) {
		e.preventDefault();
		onMainView();
	}

	let layoutClass = "layout slim";
	let hide = false;
	let backNavHide = true;
	if (!baseUi.showSideBar) {
		layoutClass = "layout slim closed-sidebar";
	}

	if (baseUi.showMobileView) {
		hide = true;
		if (baseUi.showSideBar) {
			hide = false;
		}
		if (baseUi.showErrandMobileView) {
			backNavHide = false;
			if (baseUi.showSideBar) {
				backNavHide = true;
			}
		}
	} else {
		hide = false;
		if (!baseUi.showSideBar) {
			layoutClass = "layout slim closed-sidebar";
		}
	}
	if (activateChat) {
		layoutClass += " opened-chat";
	}
	let sidebarClass = "", logoWrapperStyle = {}, flexMenusStyle = {};
	if (baseUi.collapseSideBar) {
		sidebarClass = "collapsed";
		logoWrapperStyle = { margin: "auto", textAlign: "center" };
		flexMenusStyle = { padding: "0px" };
	}
	const hideLogo = features['hide-cention-logo'];
	let customStyledAppHeader = { borderRadius: "initial" }
	if (hideLogo && baseUi.collapseSideBar) {
		customStyledAppHeader = { borderRadius: "initial", padding: "0px 15px" }
	}

	//fetch areas and export list once
	useEffect(() => {
		if(onLoad) {
			onLoad();
		}
	}, []);

	return (
		<div className="admin-main-wrapper export-wrapper">
			<div id="page-wrapper">
				<section className={"export " + layoutClass} data-app-layout>
					<div className={classNames("app-sidebar", sidebarClass)}>
						<div className="app-header" style={customStyledAppHeader}>
							{
								(hideLogo) &&
								<MenuCtnr visible={!baseUi.showErrandMobileView && !baseUi.collapseSideBar} customCurrentLabel={I("Export")}  />
							}
							{
								(hideLogo) &&
								<ToggleSideBar visible={baseUi.showSideBar || baseUi.showMobileView}
									collapsed={baseUi.collapseSideBar}
									mobileMode={baseUi.showMobileView}
									onCollapse={handleSideBarCollapse}
									onClick={handleSideBarToggle} />
							}
							<div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
								<CancellableSimpleLink onClick={handleHomePage} title={I("Back to Main")}>
									<img src={baseUi.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
								</CancellableSimpleLink>
							</div>
						</div>
						<WorkflowInnerContainerCtnr className="app-inner-content" data-custom-scrollbar hidden={hide}>
							<div className="flex-menus-wrap" style={flexMenusStyle}>
								<div className="side-bar-main-menu" hidden={hideLogo}>
									{
										!baseUi.collapseSideBar &&
										<div style={{ marginRight: "70px" }}><MenuCtnr visible={!baseUi.showErrandMobileView} customCurrentLabel={I("Export")} /></div>
									}
									<ToggleSideBar visible={baseUi.showSideBar || baseUi.showMobileView}
										showSideBar={baseUi.showSideBar}
										collapsed={baseUi.collapseSideBar}
										mobileMode={baseUi.showMobileView}
										id={"QA_toggle_sidebar_top"}
										onCollapse={handleSideBarCollapse}
										onClick={handleSideBarToggle} />
								</div>
								{!baseUi.collapseSideBar &&
									<ul className="sidebar-menu search">
										<li><a data-qa-id="export_menu_errand" data-value="Errands" onClick={() => onChangePage(EXPORT_LINK)}>{I('Errands')}</a></li>
										<li className={"current"}><a data-qa-id="export_menu_calls" onClick={() => onChangePage(EXPORTS_CALL_LINK)} data-value="Calls detail record">{I('Calls detail record')}</a></li>
									</ul>
								}
								{
									baseUi.collapseSideBar &&
									<IconizeSideBarMenuCtnr onChangePage={onChangeView} currentPage={MM_EXPORT_CALL} showWorkflowMenu={true} />
								}
							</div>
							<SidebarSettingsCtnr />
						</WorkflowInnerContainerCtnr>
					</div>
					<div className="app-content width-100 export">
						<div className="app-header" style={customStyledAppHeader}>
							<ul className="conversations-nav">
								<ToggleSideBar
									data-qa-id={"toggle-sidebar-search"}
									visible={!baseUi.showSideBar || baseUi.collapseSideBar}
									wrapped={true}
									collapsed={baseUi.collapseSideBar}
									onCollapse={handleSideBarCollapse}
									onClick={handleSideBarToggle} />
							</ul>
							{(baseUi.collapseSideBar || !baseUi.showSideBar) && <WorkflowBreadcrumbsCtnr />}
						</div>
						<WorkflowInnerContainerCtnr className="app-inner-content">
							<ExportCallList
								view={view}
								input={input}
								list={list}
								activeId={activeId}
								showExportPreview={ui.showExportPreview}
								showGeneratedCdrExport={ui.showGeneratedCdrExport}
								timezone={timezone}
								areas={areas}
								allAreas={allAreas}
								channel={channel}
								fileType={fileType}
								runInterval={runInterval}
								searchInterval={searchInterval}
								columnsToDisplay={columnsToDisplay}
								advanceCallFilters={advanceCallFilters}
								mobileMode={baseUi.showMobileView}
								onLoad={onLoad}
								onLoadToEdit={onLoadToEdit}
								onDeleteFromList={onDeleteFromList}
								onCancel={onCancel}
								onRunCallExport={onRunCallExport}
								onPreviewCallExport={onPreviewCallExport}
								onCloseExportPreview={onCloseExportPreview}
								onClosePreviewCallExport={onClosePreviewCallExport}
								onSave={onSave}
								onCloseForm={onCloseForm}
								onTextInputChange={onTextInputChange}
								onClickToggleEnable={onClickToggleEnable}
								onSetValue={onSetValue}
								onCdrExportDownload={onCdrExportDownload}
								saveStatus={saveStatus}
							/>
						</WorkflowInnerContainerCtnr>
					</div>
				</section>
			</div>
		</div>
	)
}

export default ExportCalls;
