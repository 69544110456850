import React from 'react';
import Ckeditor from '../Ckeditor';
import {
	Buttons,
} from './Footers';
import TagPicker from './TagPicker';
import { I } from '../../common/v5/config.js';
import {
	getEmailOutParentheses
} from '../../common/v5/helpers.js';
import {
	BTN_CLEAR,
	ME_ST_BUSY,
	ME_ST_CREATED,
	OPT_FIELDS_ID_VALUE,
	RECIPIENT_TO,
	RECIPIENT_GROUP,
	BTN_SEND,
	BTN_SAVE,
	RC_IM,
	RC_GROUP
} from '../../common/v5/constants';

class Bttns extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleButtonClick = this.handleButtonClick.bind(this);
	}
	handleButtonClick(type, e) {
		if(type === BTN_CLEAR) {
			return this.props.onClear(e);
		} else if (type === BTN_SEND) {
			this.props.onSubmit(type);
		} else if (type === BTN_SAVE) {
			this.props.onSave(type);
		}
	}
	render() {
		const {
			buttonsState,
			disabled,
		} = this.props;
		let createdAs;
		createdAs = null;
		return <Buttons className="errand-buttons" condition={buttonsState}
				onButtonClick={this.handleButtonClick} tag='button'>
				{createdAs}
			</Buttons>;
	}
}

class Recipient extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleAgentChange = this.handleAgentChange.bind(this);
		this.handleFailValidation = this.handleFailValidation.bind(this);
		this.handleOpenAgentBook = this.handleOpenAgentBook.bind(this);
	}
	handleFailValidation(type, inpt) {
		if (this.props.onFailValidation) {
			this.props.onFailValidation(RC_IM, inpt);
		}
	}
	handleAgentChange(agents, name) {
		let agentList = [];
		if(agents && agents.length > 0) {
			$.each(agents, function(k,v) {
				const agent = getEmailOutParentheses(v);
				var aRet = this.isValidAgentName(agent);
				if(aRet.isValid){
						agentList.push({id: aRet.agentID, value: agent});
				}else{
					this.handleFailValidation(this.props.type, agent);
				}
			}.bind(this));
		}
		this.props.onChange(agentList, name, this.props.agentList.agents);
	}
	handleOpenAgentBook() {
		this.props.onToggleAgentBook(true, this.props.name, this.props.selected, this.props.agentList.agents)
	}
	isValidAgentName (agentName) {
		let isValid = false;
		let agentID = 0;
		const p = this.props;
		$.each(p.agentList.agents, (i,v) => {
			if(v.Name === agentName) {
				isValid = true;
				agentID = v.Id;
				return false;
			}
		});
		return {isValid: isValid, agentID: agentID};
	}

	render() {
		const p = this.props;
		return <div className={p.className}>
				<label htmlFor={p.id}>{p.fieldName}</label>
				<div className="tag-picker-input">
					<TagPicker id={p.id}
						type={"internal message"}
						required={true}
						name={p.name}
						maxInput={p.maxInput}
						options={p.options}
						fields={OPT_FIELDS_ID_VALUE}
						disabled={p.disabled}
						selected={p.selected}
						onChange={this.handleAgentChange}
						onFailValidation={this.handleFailValidation}
						//need to pass onShowContactBook to TagPicker
						onShowContactBook={this.handleOpenAgentBook}/>
				</div>
			</div>;
	}
}

class GroupRecipient extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleFailValidation = this.handleFailValidation.bind(this);
		this.handleOpenGroupBook = this.handleOpenGroupBook.bind(this);
	}
	handleFailValidation(type, inpt) {
		if (this.props.onFailValidation) {
			this.props.onFailValidation(RC_GROUP, inpt);
		}
	}
	handleChange(agentGroups, name) {
		let agentGroupList = [];
		if(agentGroups && agentGroups.length > 0) {
			$.each(agentGroups, function(k,v) {
				const agentGroup = getEmailOutParentheses(v);
				var aRet = this.isValidAgentGroupName(agentGroup);
				if(aRet.isValid){
					agentGroupList.push({id: aRet.groupID, value: agentGroup});
				}else{
					this.handleFailValidation(this.props.type, agentGroup);
				}
			}.bind(this));
		}
		this.props.onChange(agentGroupList, name, this.props.agentGroupList.groups);
	}
	handleOpenGroupBook() {
		this.props.onToggleGroupBook(true, this.props.name, this.props.selected, this.props.agentGroupList.groups)
	}
	isValidAgentGroupName (agentGroupName) {
		let isValid = false;
		let groupID = 0;
		const p = this.props;
		$.each(p.agentGroupList.groups, (i,v) => {
			if(v.Name === agentGroupName) {
				isValid = true;
				groupID = v.Id;
				return false;
			}
		});
		return {isValid: isValid, groupID: groupID};
	}

	render() {
		const p = this.props;
		return <div className={p.className}>
				<label htmlFor={p.id}>{p.fieldName}</label>
				<div className="tag-picker-input">
					<TagPicker id={p.id}
						type={"group"}
						required={true}
						name={p.name}
						maxInput={p.maxInput}
						options={p.options}
						fields={OPT_FIELDS_ID_VALUE}
						disabled={p.disabled}
						selected={p.selected}
						onChange={this.handleChange}
						onFailValidation={this.handleFailValidation}
						onShowContactBook={this.handleOpenGroupBook}/>
				</div>
			</div>;
	}
}

const SubjectInput = ({id, wrapClassName, value,'data-qa-id': dataQAId, ...props}) => {
	return <div className={wrapClassName}>
			<label htmlFor={id}>{I('SUBJECT: ')}</label>
			<input data-qa-id={dataQAId} id={id} type="text" value={value} {...props} />
		</div>;
};

const mIMToolbarID = "ckeditorimrTop";

class StartIM extends React.PureComponent {
	constructor(props) {
		super(props);
		// this.state = {
		// 	// TODO: this seem useless as it seem not controllable by us but
		// 	// browser.
		// 	showBrowseAttachmentPopup: false,
		// 	showArchiveAttachmentPopup: false
		// };
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
	}
	handleSelectAll(v) {
	}
	handleKeyDown() {
	}
	handleCkeditorChange(e) {
		const cke = e.editor;
		this.props.onContentChange(cke.getData(),
			cke.document.getBody().getText());
	}
	render() {
		const p = this.props, {to, group, from} = p.recipients;//, //lwb
		if (!p.wfSettings) {
			return null;
		}
		let langs = p.wfSettings.answerWysiwyg.languageSrc;

		// fromText = I('FROM: '), lwb
		let toFieldName = I('TO: ');
		let selectedAgentTo = []; // this.props.selectedAgent;
		let selectedAgentGroup = []; // this.props.selectedAgent;
		let areaArchive = [], ckeClass, bgColor;
		if(this.props.replyTypeAgent==="to") {
			if(!(this.props.selectedAgent === undefined)) {
				selectedAgentTo.push(p.selectedAgent);
			}
		} else if (this.props.replyTypeGroup==="group") {
			if(!(this.props.selectedGroup === undefined)) {
				selectedAgentGroup.push(p.selectedGroup);  //?????? need change???
			}
		}
		return <div className="tab create active">
				<form action="#" className="form-create">
					<Recipient className="form-1-2"
						fieldName={toFieldName}	id="im-email-to"
						selected={to}
						name={RECIPIENT_TO}
						onToggleAgentBook={p.onToggleAgentBook}
						disabled={p.disabled} options={p.recipientOptions}
						onChange={p.onRecipientsChange}
						onFailValidation={p.onFailValidation}
						agentList={p.agentList}
					/>
					<GroupRecipient className="form-1-2"
						fieldName={I('Group: ')} id="im-agentgroup"
						selected={group}
						name={RECIPIENT_GROUP}
						onToggleGroupBook={p.onToggleGroupBook}
						disabled={p.disabled} options={p.recipientOptions}
						onChange={p.onGroupsChange}
						onFailValidation={p.onFailValidation}
						agentGroupList={p.agentGroupList}
					/>
					<SubjectInput id="meSubject" wrapClassName="im-subject" className="label-block subj"
						data-qa-id={p["data-qa-id"]+"subject-input"}
						onChange={p.onSubjectChange} value={p.subject}
						disabled={p.disabled} />
					{/* <div id={mIMToolbarID} hidden={false }></div> */}
					<Ckeditor id={'ckemeim'}
						bgColor={bgColor}
						myClass={ckeClass}
					// isReadOnly={p.disabled}
						defaultFontFamily=""
						height={250}
						// toolbarID={mIMToolbarID}
						defaultFontSize=""
						defaultContent={p.content}
						spellLanguages={langs}
						simpleToolbar={false}
						simpleEditor={true}
						// {...toolbar}
						// fileArchiveImages={p.archives}
						onKeydown={this.handleKeyDown}
						onChange={this.handleCkeditorChange}
						fileArchiveImages={areaArchive}
						// onDragnDropFiles={this.handleDragnDropFiles}
						// onLibraryClick={this.handleLibraryClick}
					/>
					<Bttns buttonsState={p.buttonsState}
						state={p.state} disabled={p.disabled}
						onClear={p.onClear} onSubmit={p.onSubmit}
						onSave={p.onSave}
						/>
				</form>
			</div>;
	}
}

export class NewIM extends React.PureComponent {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
	}
	componentDidMount() {
		this.props.onLoad();
	}
	toggle(e) {
		this.props.onClose(!this.props.show);
	}
	render() {
		const p = this.props;

		if (!p.isShowIM) {
			return null;
		}

		// console.log("selectedAgent="+ p.selectedAgent);
		let mainClass = 'new im popup', closeDisable, uiDisable, imTitle;
		if(p.show) {
			mainClass += ' open';
		}
		if(p.state === ME_ST_BUSY) {
			closeDisable = true;
			uiDisable = true;
		} else if(p.state === ME_ST_CREATED) {
			uiDisable = true;
		}
		if (p.newIM)  {
			imTitle = I('New Internal Message');
		} else {
			imTitle = I('Edit Internal Message');
		}

		return <div className={mainClass}>
				<div className="errand-inner popup-inner">
					<div className="wrap-errand">
							<div className="errand-titles">
								{imTitle}
								</div>
						<StartIM
							data-qa-id={"im-start"}
							archives={p.archives}
							buttonsState={p.buttonsState}
							footerCheckboxStates={p.footerCheckboxStates}
							answer={p.answer}
							plain={p.plain}
							createdId={p.createdId} disabled={uiDisable}
							content={p.content}
							subject={p.subject}
							fromOptions={p.fromOptions}
							recipientOptions={p.recipientOptions}
							from={p.from} recipients={p.recipients}
							disableCreate={p.disableCreate}
							state={p.state}
							lastSavedTimestamp={p.lastSavedTimestamp}
							onContentChange={p.onContentChange}
							onFooterCheckboxClick={p.toggleFooterCheckboxClick}
							onRecipientsChange={p.onRecipientsChange}
							onGroupsChange={p.onGroupsChange}
							onFailValidation={p.onFailValidation}
							onSubjectChange={p.onSubjectChange}
							onToggleAgentBook={p.onToggleAgentBook}
							onToggleGroupBook={p.onToggleGroupBook}
							onClear={p.onClear} onSubmit={p.onSubmit}
							onSave={p.onSave}
							selectedAgent={p.selectedAgent}
							replyTypeAgent={p.replyTypeAgent}
							replyTypeGroup={p.replyTypeGroup}
							agentList={p.agentList}
							agentGroupList={p.agentGroupList}
							wfSettings={p.wfSettings}
							onLoad={p.onLoad}
							/>
					</div>
					<div data-qa-id={"im-close-new-msg"} className="popup-close" onClick={this.toggle}
						disabled={closeDisable}>
						<i className="icon-times"></i>
					</div>
				</div>
			</div>;
	}
}
