import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import omit from 'lodash/omit';
import update from 'immutability-helper';
import onClickOutside from 'react-onclickoutside';
import { I, L } from '../../common/v5/config';
import {
	AOT_DATE_INTERVAL,
	AOT_ERRAND_TYPE,
	AOT_SHOW_INACTIVE,
	DDG_ADVANCE,
	DDG_AGENT_TYPES,
	DDG_DATE_TYPE,
	DDG_DATE_COMPARE,
	DDG_DATE_FROM,
	DDG_DATE_TO,
	DDG_ERRAND_TYPE,
	DDG_GROUPS,
	DDG_INTERVAL_DATE,
	DDG_MULTI_AREAS,
	DDG_MULTI_AGENTS,
	DDG_MULTI_CHANNELS,
	DDG_MULTI_SERVICE_IDS,
	DDG_MULTI_SIMPLE_TAGS,
	DDG_MULTI_TAGS,
	DDG_REL_DATETIME,
	DDG_SORT_ATTR,
	DDG_SORT_DIRECTION,
	DDG_TIME_FORMAT,
	DDG_TIME_SPAN_FORMAT,
	DDG_TIME_ZONE,
	DDG_WIDGET_REPORT_TYPE,
	DDG_WIDGET_REPORT_LIST,
	DDG_WIDGET_CHART_TYPE,
	DDG_EXISTING_OV_CHARTS,
	DDG_AGGREGATE_TYPE,
	DDG_MULTI_CONNECTEDAREAS,
	DDG_COLLABORATORS,
	CL_OVERVIEW,
	OC_LIMITS,
	RDT_CUSTOM,
	DT_ABSOLUTE,
	DT_RELATIVE,
	RELATIVE_DATE_TYPES
} from '../../common/v5/constants';
import { DummyFunction } from '../../common/v5/helpers';
import { VerticalCalenderPair, SingleCalenderDD, Calender } from '../../reactcomponents/DatePicker';
import {
	OrderSingleSelect,
	SingleSelectDD,
	SerialMultiSelect
} from '../../reactcomponents/Dropdown';
import { ReactSingleSelect } from '../../reactcomponents/Select';
import { connectData } from '../../containers/select';

const emptyObject = {};

/*const SingleChart = props => <div className="single-chart" data-custom-scrollbar {...props} />;

const ChartRenderer = withChartRenderer(SingleChart);

const CompareChartRenderer = props => (
	<ChartRenderer {...props} layout={CL_OVERVIEW} limits={OC_LIMITS} />
);*/

const defNestedAreas = {key: 'Areas'}
	, defNestedTags = {key: 'Tags'}
	, defIdFields = {id: "Id", name: "Name"}
	, defIdSmall = {id: "id", name: "name"}
	, defIdValue = {id: "id", name: "value"}
	, defIdDisplayName = {id: "tagId", name: "displayName", keyId: "id"}
	;
// Renderer type - every one must map with a react component as renderer for
// this dropdown group, EXCEPT RT_CUSTOM which is meant for dropdown that don't
// carry data or the data props shouldn't be altered as it is very specific
// dropdown. See MAP_RENDERER_TYPE for more information.
const RT_SINGLE = 1
	, RT_MULTI = 2
	, RT_CUSTOM = 3
	, RT_COMPLETE_MULTI = 4
	, RT_COMPLETE_MULTI_WITH_INACTIVE_AND_NO_OWNER = 5
	, RT_GROUPED_MULTI_WITH_INACTIVE = 6
	, RT_SINGLE_SELECT = 7
	;
const DDGroup = ({children, ...props}) => <ul {...props}>{children}</ul>;

const ListItem = ({className, children, ...props}) => (
	<li {...props} className={className}>{children}</li>
);

const DDItem = props => <ListItem {...props} className='pull-left' />;

function withShowChevron(WrappedComponent) {
	return props => <WrappedComponent {...props} showChevron={true} />;
}

function withList(WrappedComponent) {
	return ({doNotRender, ...props}) => {
		if (doNotRender) {
			return null;
		}
		return <DDItem><WrappedComponent {...props} /></DDItem>;
	};
}

const chevronList = compose(withList, withShowChevron);

function checkInactive(WrappedComponent) {
	return class extends PureComponent {
		constructor(props) {
			super(props);
		}
		render() {
			let { includeInactive, data, ...props } = this.props;
			if (includeInactive) {
				data = data.all;
			} else {
				data = data.active;
			}
			return <WrappedComponent {...props} data={data} />;
		}
	}
}

function checkIfAddNoOwner(WrappedComponent) {
	return class extends PureComponent {
		constructor(props) {
			super(props);
		}
		render() {
			let { includeNoOwner, data, ...props } = this.props;
			if (includeNoOwner) {
				data = data.concat([{
					Id: 0
					, Name: I("Errands Without Owner")
					, GroupBy: ""
					, Active: true
				}]);
			}
			return <WrappedComponent {...props} data={data} />;
		}
	}
}

function withSelectAllNone(MultiSelect) {
	return class extends PureComponent {
		constructor(props) {
			super(props);
		}
		render() {
			let { ...props } = this.props;
			return <MultiSelect selectAll={true} selectNone={true} {...props} />;
		}
	}
}

function withGroupSelect(MultiSelect) {
	return class extends PureComponent {
		constructor(props) {
			super(props);
		}
		render() {
			let { ...props } = this.props;
			return <MultiSelect groupSelect={true} selectAll={true} selectNone={true} {...props} />
		}
	}
}

const calenderTitleStyle = {cursor: 'pointer'}; //{cursor: 'pointer', color: '#0c87f7'}; //TODO: Remove this

export class RawPairCalendar extends React.Component {
	constructor(props){
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleLoadCalendar = this.handleLoadCalendar.bind(this);
		this.handleToggleDateType = this.handleToggleDateType.bind(this);
		this.handleSelectDateType = this.handleSelectDateType.bind(this);
		this.handleChangeDefaultDateFrom = this.handleChangeDefaultDateFrom.bind(this);
		this.handleChangeDefaultDateTo = this.handleChangeDefaultDateTo.bind(this);
		this.handleShowCompare = this.handleShowCompare.bind(this);

		this.state = {
			popCalendar: false,
			toggleDateType: false,
			disableCalendar: true
		}
	}
	handleClickOutside(){
		if (this.props.show && typeof this.props.onToggle === 'function') {
			this.props.onToggle();
		}
	}
	handleLoadCalendar() {
		this.setState({ popCalendar: !this.state.popCalendar });
	}
	handleToggleDateType() {
		this.setState({ toggleDateType: !this.state.toggleDateType })
	}
	handleSelectDateType(v) {
		if(v == RDT_CUSTOM) {
			if(this.props.onChangeDateType){
				this.props.onChangeDateType(DT_ABSOLUTE);
			}
			this.setState({disableCalendar: false});
		}else {
			if(this.props.onChangeDateType){
				this.props.onChangeDateType(DT_RELATIVE);
			}
			this.setState({disableCalendar: true});
		}
		this.props.onChangeRelDate(v);
	}
	handleChangeDefaultDateFrom(d) {
		if(this.props.onChangeFromDate) {
			this.props.onChangeFromDate(d);
		}
	}
	handleChangeDefaultDateTo(d) {
		if(this.props.onChangeToDate) {
			this.props.onChangeToDate(d);
		}
	}
	handleShowCompare() {
		if(this.props.onShowCompare) {
			this.props.onShowCompare(true);
		}
	}
	render() {
		const {
			id
			, data
			, selected
			, dateTypeSelected
			, onSelect
			, fromDateSelected
			, toDateSelected
			, title
			, onToggle
			, mobile
		} = this.props;
		let calenderType;
		return (
			<div className="pair-calendar">
				<div className="content-wrapper">
					<span>{title}</span>
					<div className="selected-date" onClick={this.handleLoadCalendar}>
						{ dateTypeSelected === DT_ABSOLUTE ?
							<span>{fromDateSelected} - {toDateSelected}</span>
							: <span>{RELATIVE_DATE_TYPES[selected]}</span>
						}
					</div>
					{/**Todo: add chevron btn for loading calendar (if follow design)*/}
				</div>
				<div className="date-select-dropdown" hidden={!this.state.popCalendar}>
					<div className="pair-calendar-inner">
						<div>
							<OrderSingleSelect data={data["Relative"]} show={this.state.toggleDateType} title={title} selected={selected} onToggle={this.handleToggleDateType} onSelect={this.handleSelectDateType} />
						</div>
						<div className="date-set-default" hidden={this.state.disableCalendar}>
							<div className="date-set-default-from">
								<span style={calenderTitleStyle} onClick={onToggle}>
									{fromDateSelected}
								</span>
								<Calender
									selected={fromDateSelected}
									id={calenderType}
									onChange={this.handleChangeDefaultDateFrom}
									mobile={mobile}
									disabled={this.state.disableCalendar}
								/>
							</div>
							<span className="separator">-</span>
							<div className="date-set-default-to">
								<span style={calenderTitleStyle} onClick={onToggle}>
									{toDateSelected}
								</span>
								<Calender
									selected={toDateSelected}
									id={calenderType}
									onChange={this.handleChangeDefaultDateTo}
									mobile={mobile}
									disabled={this.state.disableCalendar}
								/>
							</div>
						</div>
						{!this.props.hideCompare &&
							<div className="date-compare" onClick={this.handleShowCompare}>{I("Compare data")}</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export const DoubleCalenderDD = onClickOutside(RawPairCalendar);

// different dropdowns
const VerticalCalender = withList(VerticalCalenderPair)
	, SingleCalender = withList(SingleCalenderDD)
	, DualCalendar = withList(DoubleCalenderDD)
	, OneSelect = chevronList(OrderSingleSelect)
	, SingleSelect = chevronList(SingleSelectDD)
	, SingleSelectReact = chevronList(ReactSingleSelect)
	, MultiSelect = chevronList(SerialMultiSelect)
	, tagStr = I('Tag')
	, CompleteMultiSelect = withSelectAllNone(MultiSelect)
	, CompleteWithInactiveAndNoOwner = compose(checkInactive, checkIfAddNoOwner)(CompleteMultiSelect)
	, GroupedMultiSelect =  withGroupSelect(MultiSelect)
	, GroupedWithInactive = checkInactive(GroupedMultiSelect)
	//, ReactSelect = chevronList(SingleSelectReact)
	;
// Map renderer type to its react component. The react component will be
// directly pass in as argument into HOC which return from result of
// connectData.
const MAP_RENDERER_TYPE = {
	[RT_MULTI]: MultiSelect
	, [RT_COMPLETE_MULTI]: CompleteMultiSelect
	, [RT_COMPLETE_MULTI_WITH_INACTIVE_AND_NO_OWNER]: CompleteWithInactiveAndNoOwner
	, [RT_GROUPED_MULTI_WITH_INACTIVE]: GroupedWithInactive
	, [RT_SINGLE]: SingleSelect
	, [RT_SINGLE_SELECT]: SingleSelectReact
};

const renderer = type => MAP_RENDERER_TYPE[type];

const defaults = {
		[DDG_MULTI_AREAS]: {
			renderType: RT_GROUPED_MULTI_WITH_INACTIVE
			, renderer: connectData(DDG_MULTI_AREAS)(renderer(RT_GROUPED_MULTI_WITH_INACTIVE))
			, title: I('Areas')
			, nested: defNestedAreas
			, idFields: defIdFields
		}
		, [DDG_MULTI_AGENTS]: {
			renderType: RT_COMPLETE_MULTI_WITH_INACTIVE_AND_NO_OWNER
			, renderer: connectData(DDG_MULTI_AGENTS)(renderer(RT_COMPLETE_MULTI_WITH_INACTIVE_AND_NO_OWNER))
			, title: I('Agents')
			, idFields: defIdFields
		}
		, [DDG_MULTI_CHANNELS]: {
			renderType: RT_COMPLETE_MULTI
			, renderer: connectData(DDG_MULTI_CHANNELS)(renderer(RT_COMPLETE_MULTI))
			, title: I('Channels')
			, idFields: defIdValue
		}
		, [DDG_MULTI_SERVICE_IDS]: {
			renderType: RT_COMPLETE_MULTI
			, renderer: connectData(DDG_MULTI_SERVICE_IDS)(renderer(RT_COMPLETE_MULTI))
			, title: I('Channels')
			, idFields: defIdValue
		}
		, [DDG_MULTI_SIMPLE_TAGS]: {
			renderType: RT_COMPLETE_MULTI
			, renderer: connectData(DDG_MULTI_SIMPLE_TAGS)(renderer(RT_COMPLETE_MULTI))
			, title: tagStr
			, nested: defNestedTags
			, idFields: defIdSmall
		}
		, [DDG_MULTI_TAGS]: {
			renderType: RT_COMPLETE_MULTI
			, renderer: connectData(DDG_MULTI_TAGS)(renderer(RT_COMPLETE_MULTI))
			, title: tagStr
			, idFields: defIdDisplayName
		}
		, [DDG_ADVANCE]: {
			renderType: RT_MULTI
			, renderer: connectData(DDG_ADVANCE)(renderer(RT_MULTI))
			, title: I('Advanced option')
			, idFields: defIdValue
		}
		, [DDG_INTERVAL_DATE]: {
			renderType: RT_CUSTOM
			, title: I('Date interval')
			, renderer: VerticalCalender
		}
		, [DDG_ERRAND_TYPE]: {
			renderType: RT_SINGLE
			, renderer: connectData(DDG_ERRAND_TYPE)(renderer(RT_SINGLE))
			, title: I('Errand types')
			, idFields: defIdValue
		}
		, [DDG_DATE_COMPARE]: {
			renderType: RT_CUSTOM
			, renderer: connectData(DDG_DATE_COMPARE)(DualCalendar)
			, title: I("Date range:")
		}
		, [DDG_DATE_TYPE]: {
			renderType: (RT_CUSTOM)
			, renderer: connectData(DDG_DATE_TYPE)(OneSelect)
			, title: I('Date types')
		}
		, [DDG_DATE_FROM]: {
			renderType: RT_CUSTOM
			, renderer: SingleCalender
			, title: I('Date From')
		}
		, [DDG_DATE_TO]: {
			renderType: RT_CUSTOM
			, renderer: SingleCalender
			, title : I('Date To')
		}
		, [DDG_REL_DATETIME]: {
			renderType: RT_CUSTOM
			, renderer: connectData(DDG_REL_DATETIME)(OneSelect)
			, title: I('Relative date types')
		}
		, [DDG_AGENT_TYPES]: {
			renderType: RT_CUSTOM
			, renderer: connectData(DDG_AGENT_TYPES)(OneSelect)
			, title: I('Agent types')
		}
		, [DDG_GROUPS]: {
			renderType: RT_COMPLETE_MULTI
			, renderer: connectData(DDG_GROUPS)(renderer(RT_COMPLETE_MULTI))
			, title: I('Groups')
			, idFields: defIdFields
		}
		, [DDG_TIME_FORMAT]: {
			renderType: RT_SINGLE
			, renderer: connectData(DDG_TIME_FORMAT)(renderer(RT_SINGLE))
			, title: I('Time')
			, idFields: defIdFields
		}
		, [DDG_TIME_SPAN_FORMAT]: {
			renderType: RT_SINGLE
			, renderer: connectData(DDG_TIME_SPAN_FORMAT)(renderer(RT_SINGLE))
			, title: I('Time Span')
			, idFields: defIdFields
		}
		, [DDG_TIME_ZONE]: {
			renderType: RT_SINGLE
			, renderer: connectData(DDG_TIME_ZONE)(renderer(RT_SINGLE))
			, title: I('Time Zone')
			, idFields: defIdFields
		}
		, [DDG_SORT_DIRECTION]: {
			renderType: RT_SINGLE
			, renderer: connectData(DDG_SORT_DIRECTION)(renderer(RT_SINGLE))
			, title: I('Sort direction')
			, idFields: defIdValue
		}
		, [DDG_SORT_ATTR]: {
			renderType: RT_SINGLE
			, renderer: connectData(DDG_SORT_ATTR)(renderer(RT_SINGLE))
			, title: I('Sort by')
			, idFields: defIdValue
		}
		, [DDG_WIDGET_REPORT_TYPE]: {
			renderType: RT_SINGLE_SELECT
			, renderer: connectData(DDG_WIDGET_REPORT_TYPE)(renderer(RT_SINGLE_SELECT))
			, title: I('Report type')
		}
		, [DDG_WIDGET_REPORT_LIST]: {
			renderType: RT_SINGLE_SELECT
			, renderer: connectData(DDG_WIDGET_REPORT_LIST)(renderer(RT_SINGLE_SELECT))
			, title: I('Report list')
			, getOptionLabel: ({ i18nName }) => i18nName
			, getOptionValue: ({ Id }) => Id
		}
		, [DDG_WIDGET_CHART_TYPE]: {
			renderType: RT_SINGLE_SELECT
			, renderer: connectData(DDG_WIDGET_CHART_TYPE)(renderer(RT_SINGLE_SELECT))
			, title: I('Chart type')
		}
		, [DDG_EXISTING_OV_CHARTS]: {
			renderType: RT_SINGLE_SELECT
			, renderer: connectData(DDG_EXISTING_OV_CHARTS)(renderer(RT_SINGLE_SELECT))
			, title: I('Existing Executive Report charts')
		}
		, [DDG_AGGREGATE_TYPE]: {
			renderType: RT_CUSTOM
			, renderer: connectData(DDG_AGGREGATE_TYPE)(OneSelect)
			, title: I('Aggregate By')
		}
		, [DDG_MULTI_CONNECTEDAREAS]: {
			renderType: RT_GROUPED_MULTI_WITH_INACTIVE
			, renderer: connectData(DDG_MULTI_CONNECTEDAREAS)(renderer(RT_GROUPED_MULTI_WITH_INACTIVE))
			, title: I('Areas')
			, nested: defNestedAreas
			, idFields: defIdFields
		}
		, [DDG_COLLABORATORS]: {
			renderType: RT_COMPLETE_MULTI
			, renderer: connectData(DDG_COLLABORATORS)(renderer(RT_COMPLETE_MULTI))
			, title: I('Collaborators')
			, idFields: defIdSmall
		}
	}
	;
class RawRenderer extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const { type, ...more } = this.props
			, d = defaults[type]
			;
		if (!d) {
			return null;
		}
		let { title, nested, idFields, uppercase, ...props } = more
			, Renderer
			;
		if (d.renderType !== RT_CUSTOM) {
			if (!props.data && d.renderer) {
				Renderer = d.renderer;
			} else {
				Renderer = renderer(d.renderType);
			}
		} else if (d.renderer) {
			Renderer = d.renderer;
		} else {
			Renderer = DummyFunction;
		}
		if (!title) {
			title = L(d.title);
		}
		if (uppercase && title) {
			title = L(title);
			title = title.toUpperCase();
		}
		if (!nested) {
			nested = d.nested;
		}
		if (!idFields) {
			idFields = d.idFields;
		}
		
		return (
			<Renderer {...props}
				title={title}
				nested={nested}
				idFields={idFields}
				{...omit(d, ["idFields", "nested", "renderType", "renderer", "title"])}
			/>
		);
	}
}

RawRenderer.propTypes = {
	title: PropTypes.string
	, nested: PropTypes.objectOf(PropTypes.string)
	, idFields: PropTypes.objectOf(PropTypes.string)
};

function autoToggle(Component) {
	return class extends PureComponent {
		constructor(props) {
			super(props);
			this.toggle = this.toggle.bind(this);
			this.state = {
				show: false
			};
		}
		toggle() {
			this.setState({show: !this.state.show});
		}
		render() {
			const { onToggle, show, ...props } = this.props;
			return (
				<Component {...props}
					show={this.state.show}
					onToggle={this.toggle}
				/>
			);
		}
	}
}

function withOnSelect(Component) {
	return class extends PureComponent {
		constructor(props) {
			super(props);
			this.handleSelect = this.handleSelect.bind(this);
		}
		handleSelect(...args) {
			if (typeof this.props.onSelect === 'function') {
				this.props.onSelect(this.props.index, this.props.type, ...args);
			}
		}
		render() {
			const { onSelect, ...props } = this.props;
			return (
				<Component {...props} onSelect={this.handleSelect} />
			);
		}
	}
}

const DropdownWithSelect = withOnSelect(RawRenderer);

const AutoToggleDropdown = autoToggle(RawRenderer);

const AutoToggleWithSelectDD = withOnSelect(AutoToggleDropdown);

const UNDEFINED = 'undefined';

// Provides dropdowns base on array. Caller no need specify how to render the
// dropdown but only specify what type of dropdown wanted. Dropdowns trying to
// make thing simpler at the same time provide all the flexibility. Properties
// dataX, dataSize, selectedX, showX, onToggleX and onSelectX are required as
// this component still maintain as controlled component. The X value is the
// index value of the grouped dropdown where rendering will start from small X
// to bigger X. Property 'othersX' can be used to pass properties that are
// unique to the X dropdown. If property sourceX is not provided then the
// component will automatically conenct to redux store to get the appropriate
// data source that is predefined on 'defaults'. Any new dropdown that want to
// be added as one of the option need to update the defaults list. Caller can
// make use property mixedX to pass more properties via showX, onToggleX, etc.
// When mixedX true, its respective indexed-properties (showX, onToggleX, etc)
// will be assumed having object type with its key represent the name of the
// property and its value represent that property value. Object spread operation
// will be executed on mixedX respective properties.
export const Dropdowns = props => {
	let dds = [], className = 'c3-dropdown-group';
	for (let i=0; i<props.dataSize; i++) {
		const v = props['data'+i];
		if (typeof v === UNDEFINED) {
			continue;
		}
		const isMixed = props['mixed'+i]
			, oneOnSelect = !!props.onSelect
			;
		let src = props['source'+i]
			, show = props['show'+i]
			, selected = props['selected'+i]
			, onSelect = props['onSelect'+i]
			, onToggle = props['onToggle'+i]
			, doNotRender = props['doNotRender'+i]
			, spreadSrc = emptyObject
			, spreadShow = emptyObject
			, spreadSelected = emptyObject
			, spreadNotRender = emptyObject
			, spreadToggle = emptyObject
			, spreadSelect = emptyObject
			, noAutoToggle
			, fromDateSelected = props['fromDateSelected'+i]
			, toDateSelected = props['toDateSelected'+i]
			, dateTypeSelected = props['dateTypeSelected'+i]
			, onChangeFromDate = props['onChangeFromDate'+i]
			, onChangeToDate = props['onChangeToDate'+i]
			, onChangeRelDate = props['onChangeRelDate'+i]
			, onChangeDateType = props['onChangeDateType'+i]
			, onShowCompare = props['onShowCompare'+i]
			, Dd
			;
		if (isMixed) {
			if (typeof src !== UNDEFINED) {
				spreadSrc = src;
				src = undefined;
			}
			if (typeof show !== UNDEFINED) {
				noAutoToggle = true;
				spreadShow = show;
				show = undefined;
			}
			if (typeof selected !== UNDEFINED) {
				spreadSelected = selected;
				selected = undefined;
			}
			if (typeof onToggle !== UNDEFINED) {
				if (!noAutoToggle) {
					noAutoToggle = true;
				}
				spreadToggle = onToggle;
				onToggle = undefined;
			}
			if (typeof onSelect !== UNDEFINED) {
				spreadSelect = onSelect;
				onSelect = undefined;
			} else if (oneOnSelect) {
				onSelect = props.onSelect;
			}
			if (typeof doNotRender !== UNDEFINED) {
				spreadNotRender = doNotRender;
				doNotRender = undefined;
			}
		} else {
			if (!onToggle) {
				onToggle = DummyFunction;
			}
			if (oneOnSelect) {
				onSelect = props.onSelect;
			} else if (!onSelect) {
				onSelect = DummyFunction;
			}
		}
		if (noAutoToggle && !oneOnSelect) {
			Dd = RawRenderer;
		} else if (!noAutoToggle && oneOnSelect) {
			Dd = AutoToggleWithSelectDD;
		} else if (!noAutoToggle) {
			Dd = AutoToggleDropdown;
		} else {
			Dd = DropdownWithSelect;
		}
		dds.push(
			<Dd key={''+v+i}
				type={v}
				index={i}
				data={src}
				show={show}
				selected={selected}
				onToggle={onToggle}
				onSelect={onSelect}
				doNotRender={doNotRender}
				uppercase={!!props.uppercase}
				mobile={props.mobile}
				fromDateSelected={fromDateSelected}
				toDateSelected={toDateSelected}
				dateTypeSelected={dateTypeSelected}
				onChangeFromDate={onChangeFromDate}
				onChangeToDate={onChangeToDate}
				onChangeRelDate={onChangeRelDate}
				onChangeDateType={onChangeDateType}
				onShowCompare={onShowCompare}
				{...spreadSrc}
				{...spreadShow}
				{...spreadSelected}
				{...spreadNotRender}
				{...spreadToggle}
				{...spreadSelect}
				{...props['others'+i]}
			/>
		);
	}
	if (props.className) {
		className += ' ' + props.className;
	}
	return <DDGroup className={className}>{dds}</DDGroup>;
};

Dropdowns.propTypes = {
	className: PropTypes.string
	, dataSize: PropTypes.number.isRequired
	, onSelect: PropTypes.func
	, uppercase: PropTypes.bool
	, data0: PropTypes.number
	, mixed0: PropTypes.bool
	, source0: PropTypes.any
	, show0: PropTypes.bool
	, selected0: PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.number
	]).isRequired
	, onToggle0: PropTypes.func
	, onSelect0: PropTypes.func
	, doNotRender0: PropTypes.bool
	, others0: PropTypes.object
	, fromDateSelected: PropTypes.string
	, toDateSelected: PropTypes.string
	, dateTypeSelected: PropTypes.number
	, onChangeFromDate: PropTypes.func
	, onChangeToDate: PropTypes.func
	, onChangeRelDate: PropTypes.func
	, onChangeDateType: PropTypes.func
	, onShowCompare: PropTypes.func
};

export class ArrayDropdowns extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		if (!this.props.data || !this.props.data.length) {
			return null;
		}
		let data = {}
			, mixed = {}
			, source = {}
			, show = {}
			, selected = {}
			, onSelect = {}
			, onToggle = {}
			, doNotRender = {}
			, others = {}
			, fromDateSelected = {}
			, toDateSelected = {}
			, dateTypeSelected = {}
			, onChangeFromDate = {}
			, onChangeToDate = {}
			, onChangeRelDate = {}
			, onChangeDateType = {}
			, onShowCompare = {}
			;
		$.each(this.props.data, (i,v) => {
			if (typeof v.data === UNDEFINED) {
				return;
			}
			data['data'+i] = v.data;
			if (typeof v.mixed !== UNDEFINED) {
				mixed['mixed'+i] = v.mixed;
			}
			if (typeof v.source !== UNDEFINED) {
				source['source'+i] = v.source;
			}
			if (typeof v.show !== UNDEFINED) {
				show['show'+i] = v.show;
			}
			if (typeof v.selected !== UNDEFINED) {
				selected['selected'+i] = v.selected;
			}
			if (typeof v.onSelect !== UNDEFINED) {
				onSelect['onSelect'+i] = v.onSelect;
			}
			if (typeof v.onToggle !== UNDEFINED) {
				onToggle['onToggle'+i] = v.onToggle;
			}
			if (typeof v.doNotRender !== UNDEFINED) {
				doNotRender['doNotRender'+i] = v.doNotRender;
			}
			if (typeof v.others !== UNDEFINED) {
				others['others'+i] = v.others;
			}
			if (typeof v.fromDateSelected !== UNDEFINED) {
				selected['fromDateSelected'+i] = v.fromDateSelected;
			}
			if (typeof v.toDateSelected !== UNDEFINED) {
				selected['toDateSelected'+i] = v.toDateSelected;
			}
			if (typeof v.dateTypeSelected !== UNDEFINED) {
				selected['dateTypeSelected'+i] = v.dateTypeSelected;
			}
			if (typeof v.onChangeFromDate !== UNDEFINED) {
				onChangeFromDate['onChangeFromDate'+i] = v.onChangeFromDate;
			}
			if (typeof v.onChangeToDate !== UNDEFINED) {
				onChangeToDate['onChangeToDate'+i] = v.onChangeToDate;
			}
			if (typeof v.onChangeRelDate !== UNDEFINED) {
				onChangeRelDate['onChangeRelDate'+i] = v.onChangeRelDate;
			}
			if (typeof v.onChangeDateType !== UNDEFINED) {
				onChangeDateType['onChangeDateType'+i] = v.onChangeDateType;
			}
			if (typeof v.onShowCompare !== UNDEFINED) {
				onShowCompare['onShowCompare'+i] = v.onShowCompare;
			}
		});
		return (
			<Dropdowns
				className={this.props.className}
				dataSize={this.props.data.length}
				onSelect={this.props.onSelect}
				uppercase={this.props.uppercase}
				{...data}
				{...mixed}
				{...source}
				{...show}
				{...selected}
				{...onSelect}
				{...onToggle}
				{...doNotRender}
				{...others}
				{...fromDateSelected}
				{...toDateSelected}
				{...dateTypeSelected}
				{...onChangeFromDate}
				{...onChangeToDate}
				{...onChangeRelDate}
				{...onChangeDateType}
				{...onShowCompare}
			/>
		);
	}
}

ArrayDropdowns.propTypes = {
	className: PropTypes.string
	, data: PropTypes.arrayOf(
		PropTypes.shape({
			data: PropTypes.number
			, mixed: PropTypes.bool
			, source: PropTypes.any
			, show: PropTypes.oneOfType([
				PropTypes.bool
				, PropTypes.objectOf(PropTypes.bool)
			])
			, selected: PropTypes.oneOfType([
				PropTypes.string
				, PropTypes.number
				, PropTypes.objectOf(
					PropTypes.oneOfType([
						PropTypes.string
						, PropTypes.number
					])
				)
			]).isRequired
			, onSelect: PropTypes.oneOfType([
				PropTypes.func
				, PropTypes.objectOf(PropTypes.func)
			])
			, onToggle: PropTypes.oneOfType([
				PropTypes.func
				, PropTypes.objectOf(PropTypes.func)
			])
			, doNotRender: PropTypes.oneOfType([
				PropTypes.bool
				, PropTypes.objectOf(PropTypes.bool)
			])
			, additionalData: PropTypes.object
			, others: PropTypes.object
			, fromDateSelected: PropTypes.string
			, toDateSelected: PropTypes.string
			, dateTypeSelected: PropTypes.number
			, onChangeFromDate: PropTypes.func
			, onChangeToDate: PropTypes.func
			, onChangeRelDate: PropTypes.func
			, onChangeDateType: PropTypes.func
			, onShowCompare: PropTypes.func
		})
	)
	, onSelect: PropTypes.func
	, uppercase: PropTypes.bool
};
